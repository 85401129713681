import { Box, Button, TextField } from "@mui/material";
import Header from "../../components/Header"
import useMediaQuery from "@mui/material/useMediaQuery";
import React from 'react'
import {useState} from 'react'
import useAuth from "../../auth/useAuth";
import { loginRoute } from "../../config";
import swal from 'sweetalert';
import Loading from "../global/Loading";
import Logo from "../../assets/serrano.png"

const LogIn = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [body, setBody] = useState({email:'',password:''})
  const auth = useAuth();
  const [loading,setLoading] = useState(false)

  
  const handleChange = e => {
    //console.log(e.target.value)
    setBody({
        ...body,
        [e.target.name]: e.target.value
    })
  }
  const mostrarAlerta=(error)=>{
    if(error!=null){
      try{
        swal({
          title:"Error de Logueo",
          text: error,
          icon: "error",
          button:"Aceptar"
        })
      }catch(e){
        swal({
          title:"Error de Logueo",
          text: "Error inesperado ",
          icon: "error",
          button:"Aceptar"
        })
      }
    }
  }


  async function asyncsend(body){
    setLoading(true)
    try{    
      const response = await fetch(loginRoute(), {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
        },
        body: JSON.stringify(body),
      });
      const elementos = await response.json();
      //console.log('Response: ',response)
      console.log('Elementos: ', elementos);
      if (!response.ok) {
        setLoading(false);
        mostrarAlerta(elementos.detail);
        return;
      }else{
        auth.login(elementos.token,elementos.user,elementos.email,elementos.id,elementos.name_role,[]);
      }
      setLoading(false);      
    }catch (e){
      setLoading(false);
      console.log(e);
      mostrarAlerta('Error inesperado: ' + e.message);
    }
  }

  if(loading)
  return (<Loading/>)

  return (
    <main className="content">
    <Box m="10px" display="flex" justifyContent="space-around">
      <Box width="400px">
        <Box display="flex" justifyContent="center" alignItems="center" mb="50px" mt="50px">
          <img 
            alt = "profile-user"
            width = "90%"
            height = "90%"
            src = {Logo}
            style = {{ cursor: "pointer"}}
          />
        </Box>
        <Header title="Sign in" subtitle="Ingrese usuario y contraseña"/>
          <form className="LogIn">
            <Box
              display="grid"
              gap="10px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Email"
                onChange={handleChange}
                value={body.email}
                name="email"
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="password"
                label="Password"
                onChange={handleChange}
                value={body.password}
                name="password"
                sx={{ gridColumn: "span 4" }}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="15px">
              <Button color="secondary" variant="contained" onClick={()=>asyncsend(body)}>
                Sign In
              </Button>
            </Box>
          </form>
      </Box>
    </Box>
    </main>
  );
}

export default LogIn;