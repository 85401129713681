import { Box,/*useTheme,*/TextField,Button, Grid,Paper, Typography, Dialog, DialogContent, DialogActions, InputAdornment} from "@mui/material";
//import { useNavigate } from "react-router-dom";
import useAuth from "../../../../auth/useAuth";
import Header from "../../../../components/Header";
import { useRef, useState } from "react";
import { useFormik } from "formik";
//import { tokens } from "../../../../theme";
import { getBoxPaymentsSummaryRoute, newPaymentRoute } from "../../../../config";
import FatalError from "../../../global/FatalError";
import Loading from "../../../global/Loading";
import swal from "sweetalert";
import * as yup from "yup";
import ReactSignatureCanvas from "react-signature-canvas";
//import Loading from "../global/Loading";
//import FatalError from "../global/FatalError";

const IngresoEfectivo = () => {
  //const theme = useTheme();
  //const colors = tokens(theme.palette.mode)
  const auth = useAuth();
  const token = auth.globals.token
  //const {data,loading,error} = useFetch2(`${url}/api/clients`,token)

  //const navigate = useNavigate();

  const [ loadingRequest, setLoadingRequest ] = useState(false)
  const [ errorRequest, setErrorRequest ] = useState(null)


  const [signatureOpen, setSignatureOpen] = useState(false);
  const [signatureData, setSignatureData] = useState('');
  const sigCanvasRef = useRef({});


  const handleOpenSignature = () => {
    setSignatureOpen(true);
  };

  const handleSaveSignature = () => {
    if (sigCanvasRef.current.isEmpty()) {
      setSignatureData('');
      setSignatureOpen(false);
    }else{
      setSignatureData(sigCanvasRef.current.getTrimmedCanvas().toDataURL('image/png'));
      setSignatureOpen(false);
    }
    // Aquí puedes actualizar el estado o el formulario con la firma en base64
  };


  const handleClearSignature = () => {
    sigCanvasRef.current.clear();
  };


  const statusOptions = [
    { id: 1, name: 'Delivery' },
    { id: 2, name: 'Pickup' },
    { id: 3, name: 'Tránsito a bodega USA' },
    { id: 5, name: 'Tránsito a Aduana' },
    { id: 6, name: 'Trámite en Aduana' },
    { id: 7, name: 'Bodega país destino' },
    { id: 8, name: 'Trancito para entrega' },
    { id: 9, name: 'Entregada' },
  ];

  const getStatusName = (statusId) => {
    const status = statusOptions.find(option => option.id === statusId);
    return status ? status.name : 'Estado desconocido';
  };

  const getBoxSummary = async(id_box) =>{
    if(id_box && id_box !== "" ){
      try{
        setLoadingRequest(true)
        //console.log('id_box',id_box)
        let res = await fetch(getBoxPaymentsSummaryRoute(id_box), {
          method: "GET",
          headers: {
            //"Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Accept":"application/json",
            "Authorization": `Bearer ${token}`
            //"x-access-token": token,
          },
          //cors: 'no-cors'
        })
        let data = await res.json()
        //console.log('sumary',data)
        if(res.ok){
          setRecord(data[0])
        }else{
          //console.log('res',res)
          if(res.status === 404){
            mostrarErrorRequest("Registro no encontrado")
            setRecord(null)
          }
          if(res.status === 401||res.status === 403){
            mostrarErrorRequest("No tiene autorización o su sesión ha expirado")          
          }  
        }
        setLoadingRequest(false)
        return data;
      }catch(errorRequest){
        setLoadingRequest(false)
        setErrorRequest(errorRequest)
      }
    }
  }

  const newPayment = async(body) =>{
    //console.log(body)
    
    try{      
      setLoadingRequest(true)
      console.log('json pago',{
        "id_box":record.id.toString(),
        "box_status" : record.status.toString(),
        "payment_signature" : signatureData,
        "comment" : body.comment,
        "destination_user" : null,
        "detail" : [
          {
            "payment_method": "Efectivo",
            "payment": body.cash
          },
          {
            "payment_method": "Tarjeta",
            "payment": body.card
          },
          {
            "payment_method": "Cheque",
            "payment": body.check
          },     
        ]
      })
      let res = await fetch(newPaymentRoute(), {
        method: "POST",
        headers: {
          //"Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Accept":"application/json",
          "Authorization": `Bearer ${token}`
          //"x-access-token": token,
        },
        body: JSON.stringify({
          "id_box":record.id.toString(),
          "box_status" : record.status.toString(),
          "payment_signature" : signatureData,
          "comment" : body.comment,
          "destination_user" : null,
          "detail" : [
            {
              "payment_method": "Efectivo",
              "payment": body.cash
            },
            {
              "payment_method": "Tarjeta",
              "payment": body.card
            },
            {
              "payment_method": "Cheque",
              "payment": body.check
            },     
          ]
        }),
        //cors: 'no-cors'
      })
      let data = await res.json()
      console.log('resultados nuevo delivery: ',data)
      if(res.ok){
        alertSucess("Pago registrado con éxito",data.message)
        //navigate("/IngresoEfectivo")
        getBoxSummary(record.id)          
      }else{
        if(res.status === 401||res.status === 403){
          mostrarErrorRequest("No tiene autorización o su sesión ha expirado")          
        }
        mostrarErrorRequest(data.detail)
        setErrorRequest(data.detail)
        //console.log('busqueda error')     
      }
      setLoadingRequest(false)
      return data;
    }catch(errorRequest){
      //console.log('errorRequest',errorRequest)
      setLoadingRequest(false)
      setErrorRequest(errorRequest)
      //console.log('busqueda catch') 
    }
  }

  const [record, setRecord] = useState(null);

  const searchFormik = useFormik({
      initialValues: {
          recordId: '',
      },
      onSubmit: (values) => {
        //console.log(values)
        getBoxSummary(values.recordId)
      },
  });

  const paymentSchema = yup.object().shape({
    cash: yup.number().required('* Valor Requerido').min(0.00, 'El valor debe ser mayor o igual a 0').typeError('Debe ser un número válido'),
    card: yup.number().required('* Valor Requerido').min(0.00, 'El valor debe ser mayor o igual a 0').typeError('Debe ser un número válido'),
    check: yup.number().required('* Valor Requerido').min(0.00, 'El valor debe ser mayor o igual a 0').typeError('Debe ser un número válido')
  })

  const paymentFormik = useFormik({
      initialValues: {
          cash: '0',
          card: '0',
          check: '0',
          comment: '',
      },
      validationSchema: paymentSchema, 
      onSubmit: (values, { resetForm }) => {

        if (record.status === 2 && (!signatureData || signatureData === '')) {
          mostrarAlertRequest('Por favor, agregue una firma válida.');
          return;
        }
        if (((record.status === 1 && amountToCharge>0)||(record.status === 3)) && (parseInt(values.cash)===0) && (parseInt(values.card)===0) && (parseInt(values.check)===0)) {
          mostrarAlertRequest('Por favor ingrese algún tipo de pago.');
          return;
        }
          //console.log('Pago realizado:', values);
          newPayment(values)
          resetForm();
          setSignatureData('');
      },
  });


  const mostrarErrorRequest=(error)=>{
    if(error!=null){
      try{
        swal({
          title:"Error",
          text: "Resultado: "+error,
          icon: "error",
          button:"Aceptar"
        })
        if(error==="No tiene autorización o su sesión ha expirado"){
          auth.logout();
        }
      }catch(e){
        swal({
          title:"Error",
          text: "Error inesperado ",
          icon: "error",
          button:"Aceptar"
        })
      }
    }
    setErrorRequest(null)
  }

  const mostrarAlertRequest=(info)=>{
    if(info!=null){
      try{
        swal({
          title:"Alerta",
          text: info,
          icon: "warning",
          button:"Aceptar"
        })
        
      }catch(e){
        swal({
          title:"Error",
          text: "Error inesperado ",
          icon: "error",
          button:"Aceptar"
        })
      }
    }
  }

  const alertSucess=(text_title,text_content='')=>{    
    try{
      swal({
        title:text_title,
        text: text_content,
        icon: "success",
        button:"Aceptar"
      })
    }catch(e){
      swal({
        title:"Error inesperado",
        text: "Error inesperado ",
        icon: "error",
        button:"Aceptar"
      })
    }
    //navigate("/Dashboard")
  }

  const calculateAmountToCharge = () => {
    if (!record) return 0;
    //console.log(record)
    if (record.status === 1) {
      return record.delivery_cost - record.payed;
    } else if (record.status > 1) {
      return record.approximate_cost + record.insurance_cost + record.extra_payment - record.discount - record.payed;
    }
    return 0;
  };

  const amountToCharge = calculateAmountToCharge();
  const paperStyle = {
    backgroundColor: amountToCharge === 0 ? 'lightgreen' : 'lightcoral',
    padding: '15px',
    margin: '15px 0',
    textAlign: 'center'
  };

  if(loadingRequest)
  return(<Loading/>)

  if(errorRequest)
  return(<FatalError/>)


  return (
    <Box m="20px" marginBottom="64px">
      {/*<Box display="flex" justifyContent="space-between" alignItems="center">*/}
        <Header title="Ingreso Efectivo" subtitle="Ingreso Efectivo"/>
        <form onSubmit={searchFormik.handleSubmit}>
          <Box 
            sx={{ 
              display: 'flex', 
              justifyContent: 'left', 
              alignItems: 'center', 
              gap: 2,  // Espacio entre los elementos
              padding: 1 // Espacio alrededor del contenido
            }}
          >
            <TextField
              variant="filled"
              label="Código de caja"
              name="recordId"
              value={searchFormik.values.recordId}
              onChange={searchFormik.handleChange}
              size="small"
            />
            <Button type="submit" variant="contained" color="secondary">
              Buscar
            </Button>
          </Box>
        </form>

        {record && (
          <Paper style={paperStyle}>
            <Typography variant="h3">
              Cobrar: ${amountToCharge.toFixed(2)}
            </Typography>
          </Paper>
        )}

        {record && (
          <Paper style={{ margin: '15px 0', padding: '15px' }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Typography variant="subtitle1">Empresa: 
                  <Box display="inline">
                    <Typography variant="subtitle1" display="inline" color="secondary">
                      {' ' + record.company}
                    </Typography>
                  </Box>
                </Typography>
                <Typography variant="subtitle1">Cliente: 
                  <Box display="inline">
                    <Typography variant="subtitle1" display="inline" color="secondary">
                      {' ' + record.senders_name}
                    </Typography>
                  </Box>
                </Typography>
                <Typography variant="subtitle1">Teléfono: 
                  <Box display="inline">
                    <Typography variant="subtitle1" display="inline" color="secondary">
                      {' ' + record.senders_phone}
                    </Typography>
                  </Box>
                </Typography>
                <Typography variant="subtitle1">Dirección: 
                  <Box display="inline">
                    <Typography variant="subtitle1" display="inline" color="secondary">
                      {' ' + record.address}
                    </Typography>
                  </Box>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="subtitle1">Estado Caja: 
                  <Box display="inline">
                    <Typography variant="subtitle1" display="inline" color="secondary">
                      {' ' + getStatusName(record.status)}
                    </Typography>
                  </Box>
                </Typography>
                <Typography variant="subtitle1">Depósito: 
                  <Box display="inline">
                    <Typography variant="subtitle1" display="inline" color="secondary">
                      {' $' + record.delivery_cost}
                    </Typography>
                  </Box>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="subtitle1">Precio de envío: 
                  <Box display="inline">
                    <Typography variant="subtitle1" display="inline" color="secondary">
                      {' $' + record.approximate_cost}
                    </Typography>
                  </Box>
                </Typography>
                <Typography variant="subtitle1">Precio del Seguro: 
                  <Box display="inline">
                    <Typography variant="subtitle1" display="inline" color="secondary">
                      {' $' + record.insurance_cost}
                    </Typography>
                  </Box>
                </Typography>
                <Typography variant="subtitle1">Costo Extra: 
                  <Box display="inline">
                    <Typography variant="subtitle1" display="inline" color="secondary">
                      {' $' + record.extra_payment}
                    </Typography>
                  </Box>
                </Typography>
                <Typography variant="subtitle1">Descuentos aplicados: 
                  <Box display="inline">
                    <Typography variant="subtitle1" display="inline" color="secondary">
                      {' $' + record.discount}
                    </Typography>
                  </Box>
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        )}
        {record && (
          <Box sx={{ width: '100%', marginRight: 'auto', paddingBottom:'15px'}}>
            <form>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={4}>
                    <Typography variant="subtitle1">Pagado (USD)</Typography>
                    <TextField
                        label="Valor pagado"
                        name="payed"
                        value={record.payed}
                        disabled={true}
                        //onChange={paymentFormik.handleChange}
                        fullWidth
                        variant="filled"
                        size="small"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              $
                            </InputAdornment>
                          ),
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography variant="subtitle2">Total (USD)</Typography>
                    <TextField
                        label="Valor total"
                        name="cost"
                        value={record.approximate_cost + record.insurance_cost + record.extra_payment - record.discount}
                        disabled={true}
                        //onChange={paymentFormik.handleChange}
                        fullWidth
                        variant="filled"
                        size="small"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              $
                            </InputAdornment>
                          ),
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography variant="subtitle3">Pendiente (USD)</Typography>
                    <TextField
                        label="Valor Pendiente"
                        name="debt"
                        value={record.approximate_cost + record.insurance_cost + record.extra_payment - record.discount - record.payed}
                        disabled={true}
                        //onChange={paymentFormik.handleChange}
                        fullWidth
                        variant="filled"
                        size="small"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              $
                            </InputAdornment>
                          ),
                        }}
                    />
                </Grid>
              </Grid>
            </form>
          </Box>          
        )}
        {record && record.status < 5 &&(
          <form onSubmit={paymentFormik.handleSubmit}>
          <Box sx={{ width: { xs: '100%', sm: '50%', md: '35%' }, marginRight: 'auto'}}>
            <Grid container direction="column" spacing={2}>
              <Grid item container alignItems="center" spacing={2}>
                <Grid item style={{ width: '100px' }}>
                  <Typography variant="subtitle1">Efectivo</Typography>
                </Grid>
                <Grid item xs>
                  <TextField
                    label="Ingrese el valor"
                    name="cash"
                    value={paymentFormik.values.cash}
                    onChange={paymentFormik.handleChange}
                    fullWidth
                    variant="filled"
                    size="small"
                    onBlur={paymentFormik.handleBlur}
                    error={paymentFormik.touched.cash && Boolean(paymentFormik.errors.cash)} 
                    helperText={paymentFormik.touched.cash && paymentFormik.errors.cash}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          $
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item container alignItems="center" spacing={2}>
                <Grid item style={{ width: '100px' }}>
                  <Typography variant="subtitle2">Zelle</Typography>
                </Grid>
                <Grid item xs>
                  <TextField
                    label="Ingrese el valor"
                    name="card"
                    value={paymentFormik.values.card}
                    onChange={paymentFormik.handleChange}
                    fullWidth
                    variant="filled"
                    size="small"
                    onBlur={paymentFormik.handleBlur}
                    error={paymentFormik.touched.card && Boolean(paymentFormik.errors.card)} 
                    helperText={paymentFormik.touched.card && paymentFormik.errors.card}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          $
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item container alignItems="center" spacing={2}>
                <Grid item style={{ width: '100px' }}>
                  <Typography variant="subtitle3">Cheque</Typography>
                </Grid>
                <Grid item xs>
                  <TextField
                    label="Ingrese el valor"
                    name="check"
                    value={paymentFormik.values.check}
                    onChange={paymentFormik.handleChange}
                    fullWidth
                    variant="filled"
                    size="small"
                    onBlur={paymentFormik.handleBlur}
                    error={paymentFormik.touched.check && Boolean(paymentFormik.errors.check)} 
                    helperText={paymentFormik.touched.check && paymentFormik.errors.check}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          $
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            </Box>
            <Grid item style={{ paddingTop:'10px' }}>
                <Typography variant="subtitle1">Comentario</Typography>
                <TextField
                  label="Ingrese su comentario"
                  name="comment"
                  value={paymentFormik.values.comment}
                  onChange={paymentFormik.handleChange}
                  fullWidth
                  variant="filled"
                  size="small"
                />
            </Grid>
            {(amountToCharge >0||(amountToCharge === 0 && record.status === 2)||(record.status === 1 && amountToCharge === 0 && record.delivery_cost === 0)) && <Button type="submit" variant="contained" color="secondary" style={{ marginTop: '20px' }}>
              Agregar Pago
            </Button>}

            {record.status === 2 && <Button variant="contained" color="success" style={{ marginTop: '20px', marginLeft:"10px"}} onClick={handleOpenSignature}>
              Agregar Firma
            </Button>}

            <Dialog open={signatureOpen} onClose={() => setSignatureOpen(false)} fullWidth maxWidth="sm">
              <DialogContent >
                <Box 
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: '2px solid black',
                    borderRadius: '10px',
                    overflow: 'hidden',
                    margin: 'auto',
                    padding: '0px',
                    width: 'fit-content'
                  }}
                >
                  <ReactSignatureCanvas
                    penColor="black"
                    backgroundColor="#FFFFFF"
                    canvasProps={{ 
                      width: 500, 
                      height: 300, 
                      className: 'sigCanvas', 
                      style: { margin: '0px auto', display: 'block' }
                    }}
                    ref={sigCanvasRef}
                  />
                </Box>
                <DialogActions>
                  <Button onClick={handleSaveSignature} color="secondary">
                    Guardar Firma
                  </Button>
                  <Button onClick={handleClearSignature} color="warning">
                    Limpiar
                  </Button>
                </DialogActions>
              </DialogContent>
            </Dialog>
          </form>
        )}
    </Box>
  );
}

export default IngresoEfectivo;