import { Box, /*TextField, Typography,*/ useMediaQuery,useTheme } from "@mui/material";
import LogoDelivery from "../assets/package.png"
import LogoPickup from "../assets/boxes.png"
import LogoReparto from "../assets/delivery-truck.png"
import LogoReportes from "../assets/document.png"
import LogoCierre from "../assets/save-money.png"
import LogoGastos from "../assets/wallet.png"
import LogoIngresos from "../assets/revenue.png"
import LogoDropOff from "../assets/delivery.png"
import LogoCarga from "../assets/shipping.png"
import LogoRutasEEUU from "../assets/rutas-eeuu-1.png"
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { tokens } from "../theme";


const AppsCards = ({ permissionsListData }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode)
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // hasta 600px
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md")); // 600px - 900px
  const isLaptop = useMediaQuery(theme.breakpoints.between("md", "lg")); // 900px - 1200px
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg")); // más de 1200px
  const navigate = useNavigate();
  //const auth = useAuth();
  //const token = auth.globals.token
  /*const {data,loading,error} = useFetch2(`${url}/api/pools`,token)
 */

  let gridAutoRowsValue;
  if (isMobile) {
    gridAutoRowsValue = "200px"; // Ajustar según necesidad
  } else if (isTablet) {
    gridAutoRowsValue = "150px"; // Ajustar según necesidad
  } else if (isLaptop) {
    gridAutoRowsValue = "150px"; // Ajustar según necesidad
  } else if (isDesktop) {
    gridAutoRowsValue = "200px"; // Ajustar según necesidad
  }

  return (
      <Box
        display="grid"
        gridTemplateColumns="repeat(12,1fr)"
        gridAutoRows={gridAutoRowsValue}
        gap="20px"
      > 
        {permissionsListData.includes(10)&&<Box 
          p={1}
          gridColumn= {!isMobile? "span 3" : "span 12"}
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          flexDirection="column"
          borderRadius="15px"
          sx={{
            boxShadow: '0px 5px 7px rgba(0, 0, 0, 0.2)',
            transition: 'background-color 0.1s ease-in-out',
            ':hover': {
              backgroundColor: colors.primary[500], // Change background color on hover
            },
    
          }}
          onClick={()=>{navigate('/DeliveryForm')}}
        >
          <Typography
            padding="3px"
            variant="h5"
            fontWeight="600"
            color={colors.greenAccent[400]}
          >
            Delivery
          </Typography>
          <img src={LogoDelivery} alt={'D'} style={{ width:'45%' ,maxWidth: '100%' }} />
        </Box>}        
        {permissionsListData.includes(11)&&<Box 
          p={1}
          gridColumn={!isMobile? "span 3" : "span 12"}
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          flexDirection="column"
          borderRadius="15px"
          sx={{
            boxShadow: '0px 5px 7px rgba(0, 0, 0, 0.2)',
            transition: 'background-color 0.1s ease-in-out',
            ':hover': {
              backgroundColor: colors.primary[500], // Change background color on hover
            },
    
          }}
          onClick={()=>{navigate('/PickupForm')}}
        >
          <Typography
            padding="3px"
            variant="h5"
            fontWeight="600"
            color={colors.greenAccent[400]}
          >
            Pick Up
          </Typography>
            <img src={LogoPickup} alt={'D'} style={{ width:'45%' ,maxWidth: '100%' }} />
        </Box>} 
        {permissionsListData.includes(13)&&<Box 
          p={1}
          gridColumn={!isMobile? "span 3" : "span 12"}
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          flexDirection="column"
          borderRadius="15px"
          sx={{
            boxShadow: '0px 5px 7px rgba(0, 0, 0, 0.2)',
            transition: 'background-color 0.1s ease-in-out',
            ':hover': {
              backgroundColor: colors.primary[500], // Change background color on hover
            },
    
          }}
          onClick={()=>{navigate('/RutasOrigen')}}
        >
          <Typography
            padding="3px"
            variant="h5"
            fontWeight="600"
            color={colors.greenAccent[400]}
          >
            Rutas EEUU
          </Typography>
            <img src={LogoRutasEEUU} alt={'D'} style={{ width:'45%' ,maxWidth: '100%' }} />
        </Box>}
        {permissionsListData.includes(21)&&<Box 
          p={1}
          gridColumn={!isMobile? "span 3" : "span 12"}
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          flexDirection="column"
          borderRadius="15px"
          sx={{
            boxShadow: '0px 5px 7px rgba(0, 0, 0, 0.2)',
            transition: 'background-color 0.1s ease-in-out',
            ':hover': {
              backgroundColor: colors.primary[500], // Change background color on hover
            },
    
          }}
          onClick={()=>{navigate('/Reparto')}}
        >
          <Typography
            padding="3px"
            variant="h5"
            fontWeight="600"
            color={colors.greenAccent[400]}
          >
            Reparto
          </Typography>
            <img src={LogoReparto} alt={'D'} style={{ width:'45%' ,maxWidth: '100%' }} />
        </Box>}
        {permissionsListData.includes(31)&&<Box 
          p={1}
          gridColumn={!isMobile? "span 3" : "span 12"}
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          flexDirection="column"
          borderRadius="15px"
          sx={{
            boxShadow: '0px 5px 7px rgba(0, 0, 0, 0.2)',
            transition: 'background-color 0.1s ease-in-out',
            ':hover': {
              backgroundColor: colors.primary[500], // Change background color on hover
            },
    
          }}
          onClick={()=>{navigate('/Reportes')}}
        >
          <Typography
            padding="3px"
            variant="h5"
            fontWeight="600"
            color={colors.greenAccent[400]}
          >
            Reportes
          </Typography>
            <img src={LogoReportes} alt={'D'} style={{ width:'45%' ,maxWidth: '100%' }} />
        </Box>}
        {permissionsListData.includes(14)&&<Box 
          p={1}
          gridColumn={!isMobile? "span 3" : "span 12"}
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          flexDirection="column"
          borderRadius="15px"
          sx={{
            boxShadow: '0px 5px 7px rgba(0, 0, 0, 0.2)',
            transition: 'background-color 0.1s ease-in-out',
            ':hover': {
              backgroundColor: colors.primary[500], // Change background color on hover
            },
    
          }}
          onClick={()=>{navigate('/CierreRuta')}}
        >
          <Typography
            padding="3px"
            variant="h5"
            fontWeight="600"
            color={colors.greenAccent[400]}
          >
            Cierre de ruta
          </Typography>
            <img src={LogoCierre} alt={'D'} style={{ width:'45%' ,maxWidth: '100%' }} />
        </Box>}
        {permissionsListData.includes(16)&&<Box 
          p={1}
          gridColumn={!isMobile? "span 3" : "span 12"}
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          flexDirection="column"
          borderRadius="15px"
          sx={{
            boxShadow: '0px 5px 7px rgba(0, 0, 0, 0.2)',
            transition: 'background-color 0.1s ease-in-out',
            ':hover': {
              backgroundColor: colors.primary[500], // Change background color on hover
            },
    
          }}
          onClick={()=>{navigate('/Gastos')}}
        >
          <Typography
            padding="3px"
            variant="h5"
            fontWeight="600"
            color={colors.greenAccent[400]}
          >
            Gastos
          </Typography>
            <img src={LogoGastos} alt={'D'} style={{ width:'45%' ,maxWidth: '100%' }} />
        </Box>}
        {permissionsListData.includes(12)&&<Box 
          p={1}
          gridColumn={!isMobile? "span 3" : "span 12"}
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          flexDirection="column"
          borderRadius="15px"
          sx={{
            boxShadow: '0px 5px 7px rgba(0, 0, 0, 0.2)',
            transition: 'background-color 0.1s ease-in-out',
            ':hover': {
              backgroundColor: colors.primary[500], // Change background color on hover
            },
    
          }}
          onClick={()=>{navigate('/IngresoEfectivo')}}
        >
          <Typography
            padding="3px"
            variant="h5"
            fontWeight="600"
            color={colors.greenAccent[400]}
          >
            Ingreso Efectivo
          </Typography>
            <img src={LogoIngresos} alt={'D'} style={{ width:'45%' ,maxWidth: '100%' }} />
        </Box>}
        {permissionsListData.includes(20)&&<Box 
          p={1}
          gridColumn={!isMobile? "span 3" : "span 12"}
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          flexDirection="column"
          borderRadius="15px"
          sx={{
            boxShadow: '0px 5px 7px rgba(0, 0, 0, 0.2)',
            transition: 'background-color 0.1s ease-in-out',
            ':hover': {
              backgroundColor: colors.primary[500], // Change background color on hover
            },
    
          }}
          onClick={()=>{navigate('/DropOff')}}
        >
          <Typography
            padding="3px"
            variant="h5"
            fontWeight="600"
            color={colors.greenAccent[400]}
          >
            Drop Off
          </Typography>
            <img src={LogoDropOff} alt={'D'} style={{ width:'45%' ,maxWidth: '100%' }} />
        </Box>}
        {permissionsListData.includes(18)&&<Box 
          p={1}
          gridColumn={!isMobile? "span 3" : "span 12"}
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          flexDirection="column"
          borderRadius="15px"
          sx={{
            boxShadow: '0px 5px 7px rgba(0, 0, 0, 0.2)',
            transition: 'background-color 0.1s ease-in-out',
            ':hover': {
              backgroundColor: colors.primary[500], // Change background color on hover
            },
    
          }}
          onClick={()=>{navigate('/Carga')}}
        >
          <Typography
            padding="3px"
            variant="h5"
            fontWeight="600"
            color={colors.greenAccent[400]}
          >
            Carga
          </Typography>
            <img src={LogoCarga} alt={'D'} style={{ width:'45%' ,maxWidth: '100%' }} />
        </Box>}
      </Box>
  )
}

export default AppsCards;