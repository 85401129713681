import { Box/*,useTheme*/ } from "@mui/material";
//import { useNavigate } from "react-router-dom";
//import useAuth from "../../../../auth/useAuth";
import Header from "../../../../components/Header";
//import { tokens } from "../../../../theme";
//import Loading from "../global/Loading";
//import FatalError from "../global/FatalError";

const Reparto = () => {
  //const theme = useTheme();
  //const colors = tokens(theme.palette.mode)
  //const auth = useAuth();
  //const token = auth.globals.token
  //const {data,loading,error} = useFetch2(`${url}/api/clients`,token)

  //const navigate = useNavigate();


  return (
    <Box p={2}>
      {/*<Box display="flex" justifyContent="space-between" alignItems="center">*/}
        <Header title="Reparto" subtitle="Reparto"/>
    </Box>
  );
}

export default Reparto;