import { Box,Button,useMediaQuery,useTheme,TextField,FormControl, MenuItem, InputLabel } from "@mui/material";
import Select from '@mui/material/Select';
import { DataGrid, esES, GridToolbar } from "@mui/x-data-grid";
import { useLocation } from "react-router-dom";
//import { useNavigate } from "react-router-dom";
import useAuth from "../../../../auth/useAuth";
import Header from "../../../../components/Header";
import { useFetchGET } from "../../../../state/FetchHelpers";
import AddIcon from '@mui/icons-material/Add';
import { tokens } from "../../../../theme";
import { boxPricesDetailRoute, companiesListRoute, modifyPriceBoxRoute, newPriceBoxRoute, reseiverCountriesRoute } from "../../../../config";
import Loading from "../../../global/Loading";
import FatalError from "../../../global/FatalError";
import { useEffect, useState } from "react";
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import swal from "sweetalert";
import * as yup from "yup";
import { Formik } from "formik"

const BoxSizesPrices = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode)
  const auth = useAuth();
  const token = auth.globals.token

  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const _id = query.get('id')
  const _name = query.get('name')

  const isNonMobile = useMediaQuery("(min-width:600px)")

  const [ loadingRequest, setLoadingRequest ] = useState(false)
  const [ errorRequest, setErrorRequest ] = useState(null)

  const [reloadData, setReloadData] = useState(false);
  const handleReloadData = () => {
    setReloadData(true);
  };

  useEffect(() => {
    // Llama a la función para recargar datos cuando reloadData cambie a true
    if (reloadData) {
      handleReloadData();
      setReloadData(false);
      setInicitalValues({
        id_box: _id?_id:"",
        id_country: "",
        id_company: "",
        price: "",
        create_user: auth.globals.id.toString()
      })
    }
  }, [_id,reloadData,auth.globals.id]);

  const { data: companiesListData, loading: companiesListLoading, error: companiesListError } = useFetchGET(companiesListRoute(),token,[]);
  const { data: countriesListData, loading: countriesListLoading, error: countriesListError } = useFetchGET(reseiverCountriesRoute(),token,[]);
  const { data: boxesPricesListData, loading: boxesPricesListLoading, error: boxesPricesListError } = useFetchGET(boxPricesDetailRoute(_id?_id:undefined),token,[],reloadData);

  const mostrarErrorRequest=(error)=>{
    if(error!=null){
      try{
        swal({
          title:"Error",
          text: "Resultado: "+error,
          icon: "error",
          button:"Aceptar"
        })
        if(error==="No tiene autorización o su sesión ha expirado"){
          auth.logout();
        }
      }catch(e){
        swal({
          title:"Error",
          text: "Error inesperado ",
          icon: "error",
          button:"Aceptar"
        })
      }
    }
    setErrorRequest(null)
  }

  const alertSucess=(text_title,text_content='')=>{    
    try{
      swal({
        title:text_title,
        text: text_content,
        icon: "success",
        button:"Aceptar"
      })
    }catch(e){
      swal({
        title:"Error inesperado",
        text: "Error inesperado ",
        icon: "error",
        button:"Aceptar"
      })
    }
    //navigate("/Sizes")
  }

  const rows = boxesPricesListData.map((element, index) => ({
    id: index,
    country: element.country,
    company: element.company,
    price: element.price,
    id_box: element.id_box,
    id_country: element.id_country,
    id_company: element.id_company,
  }));

  const newPriceBox = async(body,id_box,id_country,id_company) =>{
    //console.log('bpdy',body)
    try{      
      let res = await fetch(newPriceBoxRoute(id_box,id_country,id_company), {
        method: "POST",
        headers: {
          //"Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Accept":"application/json",
          "Authorization": `Bearer ${token}`
          //"x-access-token": token,
        },
        body: JSON.stringify(body),
        //cors: 'no-cors'
      })
      let data = await res.json()
      //console.log('data crear precio',data)
      if(res.ok){
        setLoadingRequest(false)
        alertSucess('Precio creado con éxito',data.message)
        setReloadData(true);       
      }else{
        //console.log('res else',res)
        if(res.status === 401||res.status === 403){
          mostrarErrorRequest("No tiene autorización o su sesión ha expirado")          
        }
        mostrarErrorRequest(data.detail)
        //setErrorRequest(data.detail)    
      }
      setLoadingRequest(false)
      //setLoadingRequest(false) 
      return data;
    }catch(errorRequest){
      //console.log('errorRequest',errorRequest)
      setLoadingRequest(false)
      setErrorRequest(errorRequest)
    }
  }

  const [initialValues,setInicitalValues] = useState({
    id_box: _id?_id:"",
    id_country: "",
    id_company: "",
    price: "",
    create_user: auth.globals.id.toString()
  })

  const userSchema = yup.object().shape({
    price: yup.number().required("* Valor Requerido").min(0.00, 'El valor debe ser mayor o igual a 0').typeError('Debe ser un número válido'),
  })

  const modifyPriceBox = async(body,id_box,id_country,id_company) =>{
    try{      
      let res = await fetch(modifyPriceBoxRoute(id_box,id_country,id_company), {
        method: "PUT",
        headers: {
          //"Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Accept":"application/json",
          "Authorization": `Bearer ${token}`
          //"x-access-token": token,
        },
        body: JSON.stringify(body),
        //cors: 'no-cors'
      })
      let data = await res.json()
      //console.log('data',data)
      if(res.ok){
        setLoadingRequest(false)
        alertSucess('Precio actualizado',data.message)
        setReloadData(true);       
      }else{
        //console.log('res else',res)
        if(res.status === 401||res.status === 403){
          mostrarErrorRequest("No tiene autorización o su sesión ha expirado")          
        }
        mostrarErrorRequest(data.detail)    
      }
      setLoadingRequest(false) 
      return data;
    }catch(err){
      //console.log('errorRequest',errorRequest)
      setLoadingRequest(false)
      setErrorRequest(err)
    }
  }


  const handleFormSubmit = (values) => {
    //console.log('values formulario',values)
    const body = {
      "price" : values.price.toString()
    }
    setInicitalValues(values)
    setLoadingRequest(true)
    newPriceBox(body,values.id_box,values.id_country,values.id_company)
  }

  const mostrarAlertRequest=(info)=>{
    if(info!=null){
      try{
        swal({
          title:"Alerta",
          text: info,
          icon: "warning",
          button:"Aceptar"
        })
        
      }catch(e){
        swal({
          title:"Error",
          text: "Error inesperado ",
          icon: "error",
          button:"Aceptar"
        })
      }
    }
  }

  const handleUpdate = (id_box,id_country,id_company, value) => {
    // Intentar convertir el valor a un número float
    const floatValue = parseFloat(value);
    
    // Verificar si el valor es un número
    if (!isNaN(floatValue)) {
      const body = {
        "price" : floatValue.toString()
      }
      modifyPriceBox(body,id_box,id_country,id_company)
    } else {
      mostrarAlertRequest('Ingrese un número valido')
    }
  };

  const columns=[
    {
      //hide: true,
      field:"company", 
      headerName: "Empresa",
      flex: 0.75, 
      cellClassName: "company-column--cell",
      minWidth:120
    },
    {
      //hide: true,
      field:"country", 
      headerName: "País",
      flex: 0.75, 
      cellClassName: "country-column--cell",
      minWidth:120
    },
    {
      minWidth:150,
      field: "price",
      headerName: "Precio (USD)",
      flex: 0.5,
      editable: true, // Hace la celda editable
      renderCell: (params) => {    
        return (
          <Box             
            width="98%"
            height="100%"
            display="flex"
            alignItems="center" // Alinea los elementos verticalmente
            justifyContent="left"
            m="0 auto"
            gap={1}
          >
            <TextField
              size="small"
              type="text"
              value={params.value}
              sx={{ width: 'auto', maxWidth: '100px' }}
            />
            <Button color="success" variant="contained" size="small" onClick={() => handleUpdate(params.row.id_box,params.row.id_country,params.row.id_company,params.value)}>
              <ChangeCircleIcon/>
              Aplicar
            </Button>
          </Box>
        );
      },
    },
  ]

  if(boxesPricesListLoading||loadingRequest||companiesListLoading||countriesListLoading)
  return (<Loading/>)

  if(boxesPricesListError||errorRequest||companiesListError||countriesListError)
  return(<FatalError/>)

  return (
    <Box p={1} >
      {/*<Box display="flex" justifyContent="space-between" alignItems="center">*/}
      {<Header title={"Tamaño: "+(_name?_name:"")} subtitle="Configuración de precios por País/Empresa"/>}
      <Formik
        enableReinitialize={true}
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema = {userSchema}
      >
      {({
        values, 
        errors, 
        touched, 
        handleBlur, 
        handleChange,
        handleSubmit,
        setFieldValue
      }) => (
        <form onSubmit={handleSubmit} >
          <Box
            display="grid" 
            gap="15px"
            marginBottom="15px" 
            gridTemplateColumns="repeat(12, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile? undefined : "span 12"}
            }}
          >        
            <FormControl variant="filled"
              disabled={false}
              sx={{ gridColumn: "span 4"}}
              required>
              <InputLabel id="select-company-label" name="select-company-label">Empresa</InputLabel>
              <Select
                labelId="select-company-label"
                id="select-company"
                fullWidth={!isNonMobile}
                onBlur = {handleBlur}
                onChange = {handleChange}
                value = {values.id_company}
                name = "id_company"
                size="small"
              >
                {companiesListData.map((company) => (
                  <MenuItem key={company.id} value={company.id.toString()}>
                    {company.name}
                  </MenuItem>
                ))}
              </Select>  
            </FormControl>
            <FormControl variant="filled"
              sx={{ gridColumn: "span 4"}}
              size="small"
              required>
              <InputLabel>País</InputLabel>
              <Select
                name="id_country"
                value={values.id_country}
                onChange={handleChange}
              >
                {countriesListData.map((country) => (
                  <MenuItem key={country.id} value={country.id.toString()}>
                    {country.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
                  fullWidth={!isNonMobile}
                  size="small"
                  variant="filled"
                  type="text"
                  label="Precio"
                  onBlur = {handleBlur}
                  onChange = {handleChange}
                  value = { values.price}
                  name = "price"
                  error = {!!touched.price && !!errors.price}
                  helperText={touched.price && errors.price}
                  sx={{ gridColumn: "span 4"}}
            />
          </Box>
              <Box display="flex" justifyContent="end" mt="20px">               
                <Button type="submit" color="secondary" variant="contained">
                  <AddIcon/>
                    Agregar nuevo tamaño
                </Button>                           
              </Box>
        </form>
        )}
      </Formik>
      <Box m="5px 0px 50px 0" /*height={isNonMobile?"100%":undefined}*/ sx={{
        "& .MuiDataGrid-root":{
          border: "none",
        },
        "& .MuiDataGrid-cell":{
          borderBottom: "none"
        },
        "& .MuiDataGrid-columnHeaders":{
          backgroundColor: colors.blueAccent[700],
          borderBottom: "none",
          color: "white"
        },
        "& .MuiDataGrid-virtualScroller":{
          backgroundColor: colors.primary[400],
        },
        "& .MuiDataGrid-footerContainer":{
          borderTop: "none",
          backgroundColor: colors.blueAccent[700],
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text":{
          color: `${colors.grey[100]} !important`,
        },
        "& .MuiToolbar-gutters":{
          color: "white"
        }

      }}>
        <DataGrid
          autoHeight
          initialState={{
            columns: {
              columnVisibilityModel: {
                // Hide columns status and traderName, the other columns will remain visible
                id: false,
                //actions: true
              },
            },
          }}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          rows={rows}
          columns={columns}
          getRowId={(row) => row.id}
          density="compact"
          slots={{
            toolbar: GridToolbar,
          }}
          /*columnVisibilityModel={{
            id: false,
          }}*/
          
        />
      </Box>
    </Box>
  );

}

export default BoxSizesPrices;