import { Box,FormControl,InputLabel,Select,TextField,MenuItem, Button, useMediaQuery, InputAdornment} from "@mui/material";
import Header from "../../../../components/Header";
import Loading from "../../../global/Loading";
import FatalError from "../../../global/FatalError";
import { useFormik } from "formik";
import useAuth from "../../../../auth/useAuth";
import { fee_categories,fee_types,fee_payments_methods } from "../../../../Data/dataPrueba";
import SaveIcon from '@mui/icons-material/Save';
import { useState } from "react";
import { newFeeRoute } from "../../../../config";
import swal from "sweetalert";
import * as Yup from 'yup';


const Gastos = () => {
  //const theme = useTheme();
  //const colors = tokens(theme.palette.mode)
  const [ loadingRequest, setLoadingRequest ] = useState(false);
  const [ errorRequest, setErrorRequest ] = useState(null);
  const auth = useAuth();
  const token = auth.globals.token
  const isNonMobile = useMediaQuery("(min-width:600px)")

  const getLocalDate = () => {
    return new Intl.DateTimeFormat('en-CA', { // 'en-CA' utiliza el formato YYYY-MM-DD
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }).format(new Date());
  };


  const today = getLocalDate()

  const validationSchema = Yup.object().shape({
    amount: Yup.number()
      .typeError('Debe ser un número')
      .positive('El número debe ser positivo')
      .required('Requerido'),
    // ... otras validaciones ...
  });

  const feeFormik = useFormik({
    initialValues: {
        fee_date: today,
        category: fee_categories[0].id,
        fee_type: fee_types[fee_categories[0].id][0].id,
        payment_type: fee_payments_methods[0].id,
        fee_description: '',
        amount: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values,{ resetForm }) => {
      //console.log(values)
      newFee(values,resetForm)
    },
  });

  const handleSelectChange = (event) => {
    const { name, value } = event.target;
  
    // Actualiza los valores de Formik
    feeFormik.setFieldValue(name, value);
  
    // Si cambia la categoría, actualiza también fee_type
    if (name === 'category') {
      const firstFeeType = fee_types[value][0].id;
      feeFormik.setFieldValue('fee_type', firstFeeType);
    }
  };

  const newFee = async(body,resetForm) =>{
    try{      
      setLoadingRequest(true)
      let res = await fetch(newFeeRoute(), {
        method: "POST",
        headers: {
          //"Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Accept":"application/json",
          "Authorization": `Bearer ${token}`
          //"x-access-token": token,
        },
        body: JSON.stringify(body),
        //cors: 'no-cors'
      })
      let data = await res.json()
      //console.log('resultados nuevo gasto: ',data)
      if(res.ok){
        alertSucess("Gasto registrado con éxito",data.message)
        resetForm()         
      }else{
        if(res.status === 401||res.status === 403){
          mostrarErrorRequest("No tiene autorización o su sesión ha expirado")          
        }
        mostrarErrorRequest(data.detail)
        setErrorRequest(data.detail)
        //console.log('gasto error')     
      }
      setLoadingRequest(false)
      return data;
    }catch(errorRequest){
      //console.log('errorRequest',errorRequest)
      setLoadingRequest(false)
      setErrorRequest(errorRequest)
      //console.log('gasto catch') 
    }
  }

  const mostrarErrorRequest=(error)=>{
    if(error!=null){
      try{
        swal({
          title:"Error",
          text: "Resultado: "+error,
          icon: "error",
          button:"Aceptar"
        })
        if(error==="No tiene autorización o su sesión ha expirado"){
          auth.logout();
        }
      }catch(e){
        swal({
          title:"Error",
          text: "Error inesperado ",
          icon: "error",
          button:"Aceptar"
        })
      }
    }
    setErrorRequest(null)
  }

  const alertSucess=(text_title,text_content='')=>{    
    try{
      swal({
        title:text_title,
        text: text_content,
        icon: "success",
        button:"Aceptar"
      })
    }catch(e){
      swal({
        title:"Error inesperado",
        text: "Error inesperado ",
        icon: "error",
        button:"Aceptar"
      })
    }
  }

  if (loadingRequest)
  return (<Loading/>)
  if (errorRequest)
  return (<FatalError/>)

  return (
    <Box m="20px" marginBottom="64px">
      <Header title="Gastos" subtitle="Gastos"/>
        <form onSubmit={feeFormik.handleSubmit}>
          <Box
            display="grid" 
            gap="15px"
            marginBottom="15px" 
            gridTemplateColumns="repeat(12, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile? undefined : "span 12"}
            }}
          >
            <TextField
              required
              label="Fecha"
              name="fee_date"
              type="date"
              value={feeFormik.values.fee_date}
              onChange={feeFormik.handleChange}
              fullWidth
              variant="filled"
              InputLabelProps={{ shrink: true }}
              size="small"
              sx={{ gridColumn: "span 6"}}
            />
          </Box>
          <Box
            display="grid" 
            gap="15px"
            marginBottom="15px" 
            gridTemplateColumns="repeat(12, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile? undefined : "span 12"}
            }}
          >
            <FormControl variant="filled"
              disabled={false}
              sx={{ gridColumn: "span 6"}}
              required>
              <InputLabel id="select-category-label" name="select-category-label">Categoría Gastos</InputLabel>
              <Select
                labelId="select-category-label"
                id="select-category"
                fullWidth={!isNonMobile}
                value = {feeFormik.values.category||''}
                onChange={handleSelectChange}
                name = "category"
                size="small"
              >
                {fee_categories.map((category) => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>  
            </FormControl>
            <FormControl variant="filled"
              disabled={false}
              sx={{ gridColumn: "span 6"}}
              required>
              <InputLabel id="select-fee_type-label" name="select-fee_type-label">Tipo Gastos</InputLabel>
              <Select
                labelId="select-fee_type-label"
                id="select-fee_type"
                fullWidth={!isNonMobile}
                value = {feeFormik.values.fee_type||''}
                onChange={feeFormik.handleChange}
                name = "fee_type"
                size="small"
              >
                {fee_types[feeFormik.values.category]?fee_types[feeFormik.values.category].map((type) => (
                  <MenuItem key={type.id} value={type.id}>
                    {type.name}
                  </MenuItem>
                )):[]}
              </Select>  
            </FormControl>
            <FormControl variant="filled"
              disabled={false}
              sx={{ gridColumn: "span 6"}}
              required>
              <InputLabel id="select-payment_type-label" name="select-payment_type-label">Tipo de Pago</InputLabel>
              <Select
                labelId="select-payment_type-label"
                id="select-payment_type"
                fullWidth={!isNonMobile}
                value = {feeFormik.values.payment_type||''}
                onChange={feeFormik.handleChange}
                name = "payment_type"
                size="small"
              >
                {fee_payments_methods.map((method) => (
                  <MenuItem key={method.id} value={method.id}>
                    {method.name}
                  </MenuItem>
                ))}
              </Select>  
            </FormControl>
            <TextField
              required
              label="Monto (USD)"
              name="amount"
              value={feeFormik.values.amount}
              onChange={feeFormik.handleChange}
              fullWidth
              variant="filled"
              size="small"
              onBlur={feeFormik.handleBlur}
              error={feeFormik.touched.amount && Boolean(feeFormik.errors.amount)}
              helperText={feeFormik.touched.amount && feeFormik.errors.amount}
              sx={{ gridColumn: "span 6"}}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    $
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              required
              label="Descripción del gasto"
              name="fee_description"
              value={feeFormik.values.fee_description}
              onChange={feeFormik.handleChange}
              fullWidth
              variant="filled"
              size="small"
              sx={{ gridColumn: "span 12"}}
            />
          </Box>
          <Box
            display="flex"
            flexDirection={isNonMobile ? 'row' : 'column'}
            justifyContent="flex-start"
            mt="10px"
            gap={1}
          >
            <Button type="submit" variant="contained" color="secondary" >
              <SaveIcon/> Guardar
            </Button>
          </Box>  
        </form>
    </Box>
  );
}

export default Gastos;