export function generateHTML(data,box_id) {
  const htmlContent = `
  <!DOCTYPE html>
  <html lang="en">
  <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Etiqueta - ${box_id}</title>
    <style>
    @page {
      margin: 0.5cm 0.5cm 0.5cm 0.5cm;
      padding: 0;
      size: 10cm 15cm;
    }
    body {
      font-family: 'Helvetica Bold', 'Arial', sans-serif;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
    }
    table {
      width: 90mm;
      table-layout: fixed;
      border-collapse: collapse;
    }
    th, td {
      text-align: center;
      padding: 0.5px;
      border-bottom: 0px solid #ddd;
      font-size: 16.5px;
    }
    th {
      background-color: #f2f2f2;
    }
    h1 {
      margin: 0;
      padding: 0;
      font-size: 40px;
      font-weight: bold;
    }
  </style>
  </head>
  <body>
    <table>
      <tbody>
        <tr>
          <th>${data.company.toUpperCase()}</th>
        </tr>
        <tr>
          <td>As agent of INTERPORT LINE LIC # 003172NF</td>
        </tr>
        <tr>
          <th>Remitente</th>
        </tr>
        <tr>
          <td>${data.sender.toUpperCase()}</td>
        </tr>
        <tr>
          <th>Beneficiario</th>
        </tr>
        <tr>
          <td>${data.receiver.toUpperCase()}</td>
        </tr>
        <tr>
          <td>Tel: ${data.phone}</td>
        </tr>
        <tr>
          <td>${data.address.toUpperCase()}</td>
        </tr>
        <tr>
          <td><h1>${data.country.toUpperCase()}</h1></td>
        </tr>
        <tr>
          <td>${data.type.toUpperCase()}</td>
        </tr>
        <tr>
          <td>${data.size}</td>
        </tr>
        <tr>
          <td><img alt="Error al generar codigo de barras" src="${data.barcode}"/></td>
        </tr>
        <tr>
          <td>FR.${data.date}</td>
        </tr>
      </tbody>
    </table>
  </body>
  </html>
  `;

  const newWindow = window.open();
  //const newWindow = window.open("", "", "width=900,height=600");
  newWindow.document.write(htmlContent);
  newWindow.document.close();
}

export function generateMultipleHTML(dataArray) {
  const htmlContent = dataArray.map(data => `
  <div class="page">
    <table>
      <tbody>
        <tr>
          <th>${data.company.toUpperCase()}</th>
        </tr>
        <tr>
          <td>As agent of INTERPORT LINE LIC # 003172NF</td>
        </tr>
        <tr>
          <th>Remitente</th>
        </tr>
        <tr>
          <td>${data.sender.toUpperCase()}</td>
        </tr>
        <tr>
          <th>Beneficiario</th>
        </tr>
        <tr>
          <td>${data.receiver.toUpperCase()}</td>
        </tr>
        <tr>
          <td>Tel: ${data.phone}</td>
        </tr>
        <tr>
          <td>${data.address.toUpperCase()}</td>
        </tr>
        <tr>
          <td><h1>${data.country.toUpperCase()}</h1></td>
        </tr>
        <tr>
          <td>${data.type.toUpperCase()}</td>
        </tr>
        <tr>
          <td>${data.size}</td>
        </tr>
        <tr>
          <td><img alt="Error al generar codigo de barras" src="${data.barcode}"/></td>
        </tr>
        <tr>
          <td>FR.${data.date}</td>
        </tr>
      </tbody>
    </table>
  </div>`).join('');

  const fullHtmlContent = `
  <!DOCTYPE html>
  <html lang="en">
  <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Reporte de etiquetas</title>
    <style>
    @page {
      margin: 0.5cm 0.5cm 0.5cm 0.5cm;
      size: 10cm 15cm;
    }
    body {
      font-family: 'Arial', 'Helvetica Bold', sans-serif;
      padding: 0;
      margin: 0;
    }
    .page {
      page-break-before: always;
      display: flex;
      justify-content: center;
      padding-top: 0.5cm;
    }
    table {
      width: 90mm;
      table-layout: fixed;
      border-collapse: collapse;
    }
    th, td {
      text-align: center;
      padding: 0.5px;
      border-bottom: 0px solid #ddd;
      font-size: 16.5px;
    }
    th {
      background-color: #f2f2f2;
    }
    h1 {
      margin: 0;
      padding: 0;
      font-size: 40px;
      font-weight: bold;
    }
  </style>
  </head>
  <body>
    ${htmlContent}
  </body>
  </html>
  `;

  const newWindow = window.open();
  newWindow.document.write(fullHtmlContent);
  newWindow.document.close();
}