import { useTheme } from "@emotion/react";
import { Box, Button, FormControl, InputLabel, MenuItem, Paper, TextField, Typography } from "@mui/material";
import Select from '@mui/material/Select';
import useMediaQuery from "@mui/material/useMediaQuery";
import { DataGrid, esES, GridToolbar } from "@mui/x-data-grid";
import { useFormik } from "formik";
import { useState } from "react";
import swal from "sweetalert";
import useAuth from "../../../../auth/useAuth";
import Header from "../../../../components/Header";
import { changeBoxRouteRoute, driverRouteAssignmentRoute, getDriverRouteAssignmentRoute, getDriverRouteDetail, originCountryRoutesRoute, usersByRolRoute } from "../../../../config";
import { useFetchGET } from "../../../../state/FetchHelpers";
import { tokens } from "../../../../theme";
import FatalError from "../../../global/FatalError";
import Loading from "../../../global/Loading";

  const AsignaDriver = () => {

    const auth = useAuth();
    const token = auth.globals.token;
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [loadingRequest, setLoadingRequest] = useState(false);
    const [errorRequest, setErrorRequest] = useState(null);
    const [record, setRecord] = useState(null);
    const [routesDetailData, setRoutesDetailData] = useState([]);
    const [showTable, setShowTable] = useState(false);

    const { data: routesListData, loading: routesListLoading, error: routesListError } = useFetchGET(originCountryRoutesRoute(), token, []);
    //console.log(routesListData)
    const { data: usersListData, loading: usersListLoading, error: usersListError } = useFetchGET(usersByRolRoute(2), token, []);

    // name of driver
    const getNameDriver = async (id_route, date_route) => {
      if (date_route && date_route !== "") {
        try {
          setLoadingRequest(true)
          let res = await fetch(getDriverRouteAssignmentRoute(id_route, date_route), {
            method: "GET",
            headers: {
              //"Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
              "Accept": "application/json",
              "Authorization": `Bearer ${token}`
              //"x-access-token": token,
            },
            //cors: 'no-cors'
          })
          let data = await res.json()
          if (res.ok) {
            setRecord(data[0])
          } else {
            if (res.status === 404) {
              mostrarErrorRequest("Registro no encontrado")
              setRecord(null)
            }
            if (res.status === 401 || res.status === 403) {
              mostrarErrorRequest("No tiene autorización o su sesión ha expirado")
            }
          }
          setLoadingRequest(false)
          return data;
        } catch (errorRequest) {
          setLoadingRequest(false)
          setErrorRequest(errorRequest)
        }
      }
    }

    const setNewDriver = async(body) =>{
      //console.log('bpdy',body)
      try{
        
        let res = await fetch(driverRouteAssignmentRoute(), {
          method: "POST",
          headers: {
            //"Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Accept":"application/json",
            "Authorization": `Bearer ${token}`
            //"x-access-token": token,
          },
          body: JSON.stringify(body),
          //cors: 'no-cors'
        })
        let data = await res.json()
        if(res.ok){
          setLoadingRequest(false)
          getNameDriver(searchFormik.values.id_route,searchFormik.values.date_route);
          alertSucess('Driver actualizado',data.message);
          
        }else{
          //console.log('res else',res)
          if(res.status === 401||res.status === 403){
            mostrarErrorRequest("No tiene autorización o su sesión ha expirado")          
          }
          mostrarErrorRequest(data.detail)
          setErrorRequest(data.detail)    
        }
        setLoadingRequest(false) 
        return data;
      }catch(errorRequest){
        //console.log('errorRequest',errorRequest)
        setLoadingRequest(false)
        setErrorRequest(errorRequest)
      }
    }

    // detail driver
    const getDetailDriver = async (id_route, date_route) => {
      if (date_route && date_route !== "") {
        try {
          setLoadingRequest(true)
          let res = await fetch(getDriverRouteDetail(id_route, date_route), {
            method: "GET",
            headers: {
              //"Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
              "Accept": "application/json",
              "Authorization": `Bearer ${token}`
              //"x-access-token": token,
            },
            //cors: 'no-cors'
          })
          let data = await res.json()
          //console.log('detalle ruta', data)
          if (res.ok) {
            setRoutesDetailData(Array.isArray(data) ? data : []);
          } else {
            //console.log('res', res)
            if (res.status === 404) {
              mostrarErrorRequest("Registro no encontrado")
              setRecord(null)
            }
            if (res.status === 401 || res.status === 403) {
              mostrarErrorRequest("No tiene autorización o su sesión ha expirado")
            }
          }
          setLoadingRequest(false)
          return data;
        } catch (errorRequest) {
          setLoadingRequest(false)
          setErrorRequest(errorRequest)
        }
      }
    }

    const handleBoxRouteChange = async (box_id, new_route) => {
      //console.log('conteneodr',containerId)
      //console.log('newStatus',newStatus)
      try{
        setLoadingRequest(true)
        let res = await fetch(changeBoxRouteRoute(box_id), {
          method: "PUT",
          headers: {
            //"Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Accept":"application/json",
            "Authorization": `Bearer ${token}`
            //"x-access-token": token,
          },
          body: JSON.stringify({
            "new_route": new_route.toString(),
          }),
          //cors: 'no-cors'
        })
        let data = await res.json()
        //console.log('resultados nuevo contenedor ',data)
        if(res.ok){
          getDetailDriver(searchFormik.values.id_route, searchFormik.values.date_route)
          alertSucess('Cambio de ruta Exitoso',data.message)
        }else{
          if(res.status === 401||res.status === 403){
            mostrarErrorRequest("No tiene autorización o su sesión ha expirado")          
          }
          mostrarErrorRequest(data.detail)
          //console.log('Error')     
        }
        setLoadingRequest(false)
        return data;
      }catch(errorRequest){
        //console.log('errorRequest',errorRequest)
        setLoadingRequest(false)
        mostrarErrorRequest(errorRequest)
        //console.log('Error catch') 
      }
    };

    const searchFormik = useFormik({
      initialValues: {
        date_route: '',
        id_route: ''

      },
      onSubmit: (values) => {

        try {
          setLoadingRequest(true);
          getNameDriver(values.id_route, values.date_route);
          getDetailDriver(values.id_route, values.date_route);
          setShowTable(true);
        } catch (error) {
          console.error(error);
        }

        
        
      },
    });

    const postFormikDriver = useFormik({
      initialValues: {
        assignment_date: '',
        route_id: '',
        user_driver: ''
      },
      onSubmit: (values) => {
        setLoadingRequest(true)
        setNewDriver({...values, assignment_date: searchFormik.values.date_route, route_id:searchFormik.values.id_route});
      },
    });

    const columns = [
      {
        field: "correlative",
        headerName: "ID",
        flex: 0.4,
        cellClassName: "correlative-column--cell",
        minWidth: 75
      },
      {
        field: "senders_name",
        headerName: "Cliente",
        flex: 1,
        cellClassName: "status-column--cell",
        minWidth: 150
      },
      {
        field: "senders_phone",
        headerName: "Teléfono",
        flex: 0.5,
        cellClassName: "phone-column--cell",
        minWidth: 75
      },
      {
        field: "company",
        headerName: "Empresa",
        flex: 0.75,
        cellClassName: "company-column--cell",
        minWidth: 100
      },
      {
        field: "name",
        headerName: "Tipo",
        flex: 0.4,
        cellClassName: "status-column--cell",
        minWidth: 80
      },
      {
        field: "senders_address",
        headerName: "Dirección",
        flex: 1.7,
        cellClassName: "address-column--cell",
        minWidth: 280
      },
      // {
      //   field: "senders_state",
      //   headerName: "Estado",
      //   flex: 0.75,
      //   cellClassName: "senders_state-column--cell",
      //   minWidth: 75
      // },
      // {
      //   field: "senders_city",
      //   headerName: "Ciudad",
      //   flex: 0.75,
      //   cellClassName: "senders_city-column--cell",
      //   minWidth: 110
      // },
      {
        minWidth: 200,
        field: "reassignment",
        headerName: "Reasignar Ruta",
        flex: 1,
        renderCell: ({ row }) => {
          return (
            <Select
              value={''}
              onChange={(e) => handleBoxRouteChange(row.id, e.target.value)}
              style={{ width: '100%' }}
            >
              {routesListData.map((route) => (
                <MenuItem key={route.id} value={route.id}>
                  {route.route_name}
                </MenuItem>
              ))}
            </Select>
          );
        },
      }
    ]

    const paperStyle = {
      backgroundColor: 'lightgreen',
      padding: '15px',
      margin: '15px 0',
      textAlign: 'center'
    };

    // ---------------------------------------------------------- ALERTS ----------------------------------------------------------------------------
    const mostrarErrorRequest = (error) => {
      if (error != null) {
        try {
          swal({
            title: "Error",
            text: "Resultado: " + error,
            icon: "error",
            button: "Aceptar"
          })
          if (error === "No tiene autorización o su sesión ha expirado") {
            auth.logout();
          }
        } catch (e) {
          swal({
            title: "Error",
            text: "Error inesperado ",
            icon: "error",
            button: "Aceptar"
          })
        }
      }
      setErrorRequest(null)
    }

    const alertSucess=(text_title,text_content='')=>{    
      try{
        swal({
          title:text_title,
          text: text_content,
          icon: "success",
          button:"Aceptar"
        })
      }catch(e){
        swal({
          title:"Error inesperado",
          text: "Error inesperado ",
          icon: "error",
          button:"Aceptar"
        })
      }
    }

    if (loadingRequest || routesListLoading || usersListLoading)
      return (<Loading />)

    if (errorRequest || routesListError || usersListError)
      return (<FatalError />)

    // --------------------------------------------------------- END ALERTS ----------------------------------------------------------------------------
    return (

      <Box m="20px" marginBottom="64px">
        <Header title="Ruta asignada" subtitle="Detalle" />

        <form onSubmit={searchFormik.handleSubmit}>
          <Box display="grid"
            gap="15px"
            marginBottom="15px"
            gridTemplateColumns="repeat(12, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 12" }
            }} >

            <TextField
              disabled={false}
              fullWidth={!isNonMobile}
              variant="filled"
              type="date"
              label="Fecha"
              asp-format="{0:yyyy-MM-dd}"
              name="date_route"
              value={searchFormik.values.date_route}
              onChange={searchFormik.handleChange}
              size="small"
              sx={{ gridColumn: "span 3" }}
              required
            />

            <FormControl variant="filled"
              disabled={false}
              sx={{ gridColumn: "span 5" }}
              required>
              <InputLabel id="select-route-label" name="select-route-label">Ruta</InputLabel>
              <Select
                labelId="select-route-label"
                id="select-route"
                fullWidth={!isNonMobile}
                name="id_route"
                size="small"
                value={searchFormik.values.id_route}
                onChange={searchFormik.handleChange}
              >

                {routesListData.map((route) => (
                  <MenuItem key={route.id} value={route.id.toString()}>
                    {route.route_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Button type="submit" variant="contained" color="secondary">
              Buscar
            </Button>
          </Box>
        </form>

        { routesDetailData.length > 0 && (  
        <form onSubmit={postFormikDriver.handleSubmit}>
          <Box display="grid"
            gap="15px"
            marginBottom="15px"
            gridTemplateColumns="repeat(12, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 12" }
            }}>
            <FormControl variant="filled"
              disabled={false}
              sx={{ gridColumn: "span 5" }}
              required>
              <InputLabel id="select-driver-label" name="select-driver-label">Driver</InputLabel>
              <Select
                labelId="select-driver-label"
                id="select-driver"
                fullWidth={!isNonMobile}
                name="user_driver"
                value={postFormikDriver.values.user_driver}
                onChange={postFormikDriver.handleChange}
                size="small"
              >

                {usersListData.map((user) => (
                  <MenuItem key={user.id} value={user.id.toString()}>
                    {user.name + ' ' + user.lastname}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button type="submit" color="secondary" variant="contained">
              Reasignar
            </Button>
          </Box>
        </form>
        )}

        {record &&(
          <Paper style={paperStyle}>
            <Typography variant="h3">
              {'Driver: ' + record.Nombre}
            </Typography>
          </Paper>
        )}

        { showTable && (
        <Box m="5px 0px 50px 0" /*height={isNonMobile?"100%":undefined}*/ sx={{
          "& .MuiDataGrid-root":{
            border: "none",
          },
          "& .MuiDataGrid-cell":{
            borderBottom: "none"
          },
          "& .MuiDataGrid-columnHeaders":{
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            color: "white"
          },
          "& .MuiDataGrid-virtualScroller":{
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer":{
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text":{
            color: `${colors.grey[100]} !important`,
          },
          "& .MuiToolbar-gutters":{
            color: "white"
          }

        }}>
          <DataGrid
            autoHeight
            initialState={{
              columns: {
                columnVisibilityModel: {
                  // Hide columns status and traderName, the other columns will remain visible
                  id: false,
                  actions:true
                },
              },
            }}
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            rows={routesDetailData}
            columns={columns}
            getRowId={(row) => row.id}
            density="compact"
            slots={{
              toolbar: GridToolbar,
            }}
          />
        </Box>
        )}


      </Box>

    );
  }

  export default AsignaDriver;