import { Box, IconButton, useTheme } from "@mui/material";
import { useContext } from "react";
import { ColorModeContext } from "../../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
//import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import useAuth from "../../auth/useAuth";
import { useNavigate } from "react-router-dom";
import { useProSidebar } from "react-pro-sidebar";

const Topbar = () => {
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);
  const auth = useAuth();
  //const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const onSubmit = () =>{
    auth.logout();
    navigate("/");
  }

  const {collapseSidebar,toggleSidebar,broken}= useProSidebar();

  if(auth.globals.auth)
  return (
    <Box display="flex" justifyContent="space-between">
      {/* SEARCH BAR */}

      <Box 
        display="flex" justifyContent="flex-start"
        //backgroundColor={colors.primary[400]}
        borderRadius = "3px"
      >
        <IconButton onClick={() => broken ? toggleSidebar() : collapseSidebar()} type="button" sx={{ p: 1 }}>
          <MenuOutlinedIcon />
        </IconButton>
      </Box>

      {/*<Box 
        display="flex" 
        backgroundColor={colors.primary[400]}
        borderRadius = "3px"
      >
        <InputBase sx={{ ml: 2, flex: 1}} placeholder = "Search" />
        <IconButton type="button" sx={{ p: 1 }}>
          <SearchIcon />
        </IconButton>
      </Box>*/}

      {/* ICONS */}
      <Box display="flex" justifyContent="flex-end">
        <IconButton onClick = { colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
        {/*<IconButton onClick={() => navigate("/Alertas")console.log('ALERTAS')}>
          < NotificationsOutlinedIcon/>
        </IconButton>*/}
        <IconButton onClick={() => onSubmit()} >
          <ExitToAppIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Topbar;