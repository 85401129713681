import { Box,TextField, Button, InputAdornment} from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";
import Header from "../../../../components/Header";
import Loading from "../../../global/Loading";
import FatalError from "../../../global/FatalError";
import useAuth from "../../../../auth/useAuth";
//import SaveIcon from '@mui/icons-material/Save';
import { useEffect, useState } from "react";
import { getCahsBalanceDriversRoute, newCashDriverRoute } from "../../../../config";
import swal from "sweetalert";
import { useFetchGET } from "../../../../state/FetchHelpers";
import HistoryCash from "./HistoryCash";
import { format } from "date-fns";


const Cuadre = () => {
  const [ loadingRequest, setLoadingRequest ] = useState(false);
  const [ errorRequest, setErrorRequest ] = useState(null);
  const [receivedAmounts, setReceivedAmounts] = useState({});
  const auth = useAuth();
  const token = auth.globals.token

  const getUSDate = () => {
    return new Intl.DateTimeFormat('en-US', { // 'en-CA' utiliza el formato YYYY-MM-DD
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }).format(new Date());
  };

  const handleAmountChange = (id, amount) => {
    setReceivedAmounts(prevAmounts => ({
      ...prevAmounts,
      [id]: parseFloat(amount)
    }));
  };

  const customLocaleText = {
    ...esES.components.MuiDataGrid.defaultProps.localeText,
    noRowsLabel: 'No hay se encontraron resultados...',
  };

  const todayUS = getUSDate()

  const handleReloadData = () => {
    setReloadData(true);
  };

  const [reloadData, setReloadData] = useState(false);

  useEffect(() => {
    // Llama a la función para recargar datos cuando reloadData cambie a true
    if (reloadData) {
      handleReloadData();
      setReloadData(false);
    }
  }, [reloadData]);


  const { data: balanceListData, loading: balanceListLoading, error: balanceListError } = useFetchGET(getCahsBalanceDriversRoute(),token,[],reloadData);
  //console.log('balanceListData',balanceListData)

  const newCashDriver = async(amount,driver_cash_date,id_user_driver) =>{
    try{      
      setLoadingRequest(true)
      let res = await fetch(newCashDriverRoute(), {
        method: "POST",
        headers: {
          //"Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Accept":"application/json",
          "Authorization": `Bearer ${token}`
          //"x-access-token": token,
        },
        body: JSON.stringify(
        {
          "amount": amount,
          "driver_cash_date": driver_cash_date,
          "id_user_driver": id_user_driver 
        }),
        //cors: 'no-cors'
      })
      let data = await res.json()
      //console.log('resultados nuevo cash: ',data)
      if(res.ok){
        alertSucess("Cash driver registrado con éxito",data.message)
        handleAmountChange(id_user_driver,0)
        setReloadData(true)       
      }else{
        if(res.status === 401||res.status === 403){
          mostrarErrorRequest("No tiene autorización o su sesión ha expirado")          
        }
        mostrarErrorRequest(data.detail)
        setErrorRequest(data.detail)
        //console.log('cash error')     
      }
      setLoadingRequest(false)
      return data;
    }catch(errorRequest){
      //console.log('errorRequest',errorRequest)
      setLoadingRequest(false)
      setErrorRequest(errorRequest)
      //console.log('cash catch') 
    }
  }

  const mostrarErrorRequest=(error)=>{
    if(error!=null){
      try{
        swal({
          title:"Error",
          text: "Resultado: "+error,
          icon: "error",
          button:"Aceptar"
        })
        if(error==="No tiene autorización o su sesión ha expirado"){
          auth.logout();
        }
      }catch(e){
        swal({
          title:"Error",
          text: "Error inesperado ",
          icon: "error",
          button:"Aceptar"
        })
      }
    }
    setErrorRequest(null)
  }

  const alertSucess=(text_title,text_content='')=>{    
    try{
      swal({
        title:text_title,
        text: text_content,
        icon: "success",
        button:"Aceptar"
      })
    }catch(e){
      swal({
        title:"Error inesperado",
        text: "Error inesperado ",
        icon: "error",
        button:"Aceptar"
      })
    }
  }

  const mostrarAlertRequest=(info)=>{
    if(info!=null){
      try{
        swal({
          title:"Alerta",
          text: info,
          icon: "warning",
          button:"Aceptar"
        })
        
      }catch(e){
        swal({
          title:"Error",
          text: "Error inesperado ",
          icon: "error",
          button:"Aceptar"
        })
      }
    }
  }

  const handleConfirm = (id) => {
    const amount = receivedAmounts[id] || 0;
    //console.log('Fecha:', format(new Date(todayUS),'yyyy-MM-dd'));
    //console.log('ID:', id);
    //console.log('Cantidad recibida:', amount);
    if (amount<=0) {
      mostrarAlertRequest('La cantidad debe ser mayor a 0')
      return;
    }else{
      newCashDriver(amount.toString(),format(new Date(todayUS),'yyyy-MM-dd').toString(),id.toString())
    }
  };

  const columns = [
    {
      flex:1,
      field: 'date', 
      headerName: 'Fecha', 
      //maxWidth: 150, 
      renderCell: () => todayUS 
    },
    { 
      flex:1,
      field: 'cash_balance', 
      headerName: 'Efectivo (USD)', 
      //maxWidth: 150 
    },
    { 
      flex:1,
      field: 'name', 
      headerName: 'Usuario', 
      //maxWidth: 150 
    },
    {
      flex:2,
      field: 'actions',
      headerName: 'Cantidad recibida (USD)',
      //maxWidth: 300,
      renderCell: (params) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            type="number"
            variant="outlined"
            size="small"
            style={{ marginRight: '10px' }}
            value={receivedAmounts[params.id] ||''||0}
            onChange={(e) => handleAmountChange(params.id, e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  $
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            color="success"
            onClick={() => handleConfirm(params.id)}
          >
            Confirmar
          </Button>
        </div>
      ),
    },
  ];



  if (loadingRequest||balanceListLoading)
  return (<Loading/>)
  if (errorRequest||balanceListError)
  return (<FatalError/>)

  return (
    <Box m="20px" marginBottom="64px">
      <Header title="Cuadre" subtitle="Cuadre"/>
        {balanceListData && (
          <DataGrid
            autoHeight
            rows={balanceListData.map((balance) => ({
              id: balance.id,
              date: balance.date,
              cash_balance: balance.cash_balance || '0.00',
              name: balance.name,
            }))}
            columns={columns}
            localeText={customLocaleText}
            pageSize={10}
          />
    )}
    <HistoryCash reloadData={reloadData}/>
    </Box>
  );
}

export default Cuadre;