import { Box,useTheme,Button} from "@mui/material";
import { DataGrid, esES, GridToolbar } from "@mui/x-data-grid";
import useAuth from "../../../../auth/useAuth";
import Header from "../../../../components/Header";
import { useFetchGET } from "../../../../state/FetchHelpers";
import { getBalanceBoxesRoute } from "../../../../config";
import Loading from "../../../global/Loading";
import FatalError from "../../../global/FatalError";
import { tokens } from "../../../../theme";
import { useEffect, useState } from "react";
//import swal from "sweetalert";
import UpdateIcon from '@mui/icons-material/Update';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import InactivateDialog from "../Delivery/InactivateDialog";
import { useNavigate } from "react-router-dom";
import PaymentDialog from "./PaymentDialog";

const CajasConSaldo = ({ permissionsListData }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode)
  const auth = useAuth();
  const token = auth.globals.token

  const [reloadData, setReloadData] = useState(false);
  const { data: boxesListData, loading: boxesListLoading, error: boxesListError } = useFetchGET(getBalanceBoxesRoute(),token,[],reloadData);
  //console.log(boxesListData)

  const [openInactivateDialog, setOpenInactivateDialog] = useState(false);
  const [openPaymentDialog, setOpenPaymentDialog] = useState(false);

  const handleReloadData = () => {
    setReloadData(true);
  };



  const [selectedRow, setSelectedRow] = useState('');

  const handleOpenInactivateDialog = (row) => {
    setSelectedRow(row);
    setOpenInactivateDialog(true);
  };
  
  const handleOpenPaymentDialog = (row) => {
    setSelectedRow(row);
    setOpenPaymentDialog(true);
  };

  useEffect(() => {
    // Llama a la función para recargar datos cuando reloadData cambie a true
    if (reloadData) {
      handleReloadData();
      setReloadData(false);
    }
  }, [reloadData]);
    
  /*const mostrarErrorRequest=(error)=>{
    if(error!=null){
      try{
        swal({
          title:"Error",
          text: "Resultado: "+error,
          icon: "error",
          button:"Aceptar"
        })
        if(error==="No tiene autorización o su sesión ha expirado"){
          auth.logout();
        }
      }catch(e){
        swal({
          title:"Error",
          text: "Error inesperado ",
          icon: "error",
          button:"Aceptar"
        })
      }
    }
    setErrorRequest(null)
  }*/

  
  /*const alertSucess=(text_title,text_content='')=>{    
    try{
      swal({
        title:text_title,
        text: text_content,
        icon: "success",
        button:"Aceptar"
      })
    }catch(e){
      swal({
        title:"Error inesperado",
        text: "Error inesperado ",
        icon: "error",
        button:"Aceptar"
      })
    }
  }*/

  const columns=[
    {
      flex: 0.75,
      field:"correlative", 
      headerName: "ID",
      cellClassName: "correlative--cell",
      headerClassName: "correlative--cell",
      hideable:false,
      minWidth:75
    },
    {
      field:"company", 
      headerName: "Empresa",
      flex: 1.5, 
      cellClassName: "company-column--cell",
      minWidth:110
    },
    {
      field:"status", 
      headerName: "Estado",
      flex: 1.5, 
      cellClassName: "status-column--cell",
      minWidth:130
    },
    {
      field:"container_id", 
      headerName: "Id Contenedor",
      flex: 0.80, 
      cellClassName: "container_id-column--cell",
      minWidth:75
    },
    {
      field:"container_code", 
      headerName: "Código Contenedor",
      flex: 1.2, 
      cellClassName: "container_code-column--cell",
      minWidth:120
    },
    {
      field:"size", 
      headerName: "Tamaño",
      flex: 0.75, 
      cellClassName: "size-column--cell",
      minWidth:75
    },
    {
      field:"payed", 
      headerName: "Pagado",
      flex: 0.5, 
      cellClassName: "payed-column--cell",
      minWidth:50
    },
    {
      field:"balance", 
      headerName: "Saldo",
      flex: 0.5, 
      cellClassName: "balance-column--cell",
      minWidth:50
    },
    {
      field:"total_cost", 
      headerName: "Costo Total",
      flex: 0.6, 
      cellClassName: "total_cost-column--cell",
      minWidth:60
    },
    {
      field: "actions",
      headerName: "Acciones",
      flex: 4,
      minWidth: 450,
      renderCell: ({ row }) => {
        return (
          <Box
            width="98%"
            height="75%"
            maxHeight="35px" 
            display="flex" 
            justifyContent="center"
          >
            <Button
              color="info"
              variant="contained"
              onClick={()=>{
                window.open("/Tracking?id=" + row.id, "_blank");
              }}
              sx={{ mx: 0.5 }}
            >
              <UpdateIcon/>
              <Box sx={{m:"5px"}}>
                Tracking
              </Box>
            </Button>
            {permissionsListData.includes(27)&&<Button
              color="info"
              variant="contained"
              onClick={()=>{
                navigate("/VerPagos?id="+row.id)
              }}
              sx={{ mx: 0.5 }}
            >
              <CurrencyExchangeIcon/>
              <Box sx={{ml:"5px"}}>
                Pagos
              </Box>
            </Button>}
            {row.record_status==='ACTIVO' && permissionsListData.includes(43)?
            <Button
              color="success"
              variant="contained"
              onClick={()=>{handleOpenPaymentDialog(row)}}
              sx={{ mx:  0.5 }}
            >
              <PointOfSaleIcon/>
              <Box sx={{ml:"5px"}}>
                Ingresar Pago
              </Box>
            </Button>
            :undefined}
            {row.record_status==='ACTIVO' && permissionsListData.includes(30)?
              <Button
                color="error"
                variant="contained"
                onClick={()=>{handleOpenInactivateDialog(row)}}
                sx={{ mx:  0.5 }}
              >
              <DisabledByDefaultIcon/>
                <Box sx={{ml:"5px"}}>
                  Anular
                </Box>
              </Button>
            :undefined}
          </Box>
        );
      },
    }
  ]
  
  if(boxesListLoading)
  return (<Loading/>)

  if(boxesListError)
  return(<FatalError/>)

  return (
    <Box m="15px" marginBottom="64px">
      <Header title="Cajas con saldo" subtitle="Listado de cajas con saldo" />
      <InactivateDialog
        openInactivateDialog={openInactivateDialog}
        setOpenInactivateDialog={setOpenInactivateDialog}
        id={selectedRow.id}
        correlative={selectedRow.correlative}
        record_status={selectedRow.record_status}
        pageNavigate = {'/CajasConSaldo'}
        setReloadData = { setReloadData}
      />
      <PaymentDialog
        openPaymentDialog={openPaymentDialog}
        setOpenPaymentDialog={setOpenPaymentDialog}
        id={selectedRow.id}
        correlative={selectedRow.correlative}
        id_status={selectedRow.id_status}
        container_code={selectedRow.container_code}
        balance={selectedRow.balance}
        setReloadData = { setReloadData}
      />
      <Box m="5px 0px 50px 0" /*height={isNonMobile?"100%":undefined}*/ sx={{
        "& .MuiDataGrid-root":{
          border: "none",
        },
        "& .MuiDataGrid-cell":{
          borderBottom: "none"
        },
        "& .MuiDataGrid-columnHeaders":{
          backgroundColor: colors.blueAccent[700],
          borderBottom: "none",
          color: "white"
        },
        "& .MuiDataGrid-virtualScroller":{
          backgroundColor: colors.primary[400],
        },
        "& .MuiDataGrid-footerContainer":{
          borderTop: "none",
          backgroundColor: colors.blueAccent[700],
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text":{
          color: `${colors.grey[100]} !important`,
        },
        "& .MuiToolbar-gutters":{
          color: "white"
        }

      }}>
        { boxesListData &&<DataGrid
          autoHeight
          initialState={{
            columns: {
              columnVisibilityModel: {
                actions:true
              },
            },
          }}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          rows={boxesListData}
          columns={columns}
          getRowId={(row) => row.id}
          density="compact"
          slots={{
            toolbar: GridToolbar,
          }}
        />}
      </Box>   
    </Box>
  );
}
export default CajasConSaldo;