import { Box,MenuItem,Select,useTheme } from "@mui/material";
import { DataGrid, esES, GridToolbar } from "@mui/x-data-grid";
import useAuth from "../../../../auth/useAuth";
import Header from "../../../../components/Header";
import { useFetchGET } from "../../../../state/FetchHelpers";
import {getContainerStatusRoute, getContainersRoute, modifyContainerStatusRoute, storesListRoute } from "../../../../config";
import Loading from "../../../global/Loading";
import FatalError from "../../../global/FatalError";
import { tokens } from "../../../../theme";
import { useEffect, useState } from "react";
import swal from "sweetalert";

const Contenedores = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode)
  const auth = useAuth();
  const token = auth.globals.token

  const [ loadingRequest, setLoadingRequest ] = useState(false)
  const [ errorRequest, setErrorRequest] = useState(null)


  const handleStatusChange = async (containerId, newStatus) => {
    //console.log('conteneodr',containerId)
    //console.log('newStatus',newStatus)
    try{
      setLoadingRequest(true)
      let res = await fetch(modifyContainerStatusRoute(containerId), {
        method: "PUT",
        headers: {
          //"Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Accept":"application/json",
          "Authorization": `Bearer ${token}`
          //"x-access-token": token,
        },
        body: JSON.stringify({
          "status": newStatus,
        }),
        //cors: 'no-cors'
      })
      let data = await res.json()
      //console.log('resultados nuevo contenedor ',data)
      if(res.ok){
        setReloadData(true);    
      }else{
        if(res.status === 401||res.status === 403){
          mostrarErrorRequest("No tiene autorización o su sesión ha expirado")          
        }
        mostrarErrorRequest(data.detail)
        setErrorRequest(data.detail)
        //console.log('Error')     
      }
      setLoadingRequest(false)
      return data;
    }catch(errorRequest){
      //console.log('errorRequest',errorRequest)
      setLoadingRequest(false)
      setErrorRequest(errorRequest)
      //console.log('Error catch') 
    }
  };

  const [reloadData, setReloadData] = useState(false);
  const handleReloadData = () => {
    setReloadData(true);
  };
  
  useEffect(() => {
    // Llama a la función para recargar datos cuando reloadData cambie a true
    if (reloadData) {
      handleReloadData();
      setReloadData(false);
    }
  }, [reloadData,auth.globals.id]);

  const { data: statusListData, loading: statusListLoading, error: statusListError } = useFetchGET(getContainerStatusRoute(),token,[]);
  //console.log('estados',statusListData)
  
  const { data: storesListData, loading: storesListLoading, error: storesListError } = useFetchGET(storesListRoute(),token,[]);
  //console.log('storesListData',storesListData)

  const { data: containersListData, loading: containersListLoading, error: containersListError } = useFetchGET(getContainersRoute(),token,[],reloadData);
  //console.log('containersListData',containersListData)

  const [updatedContainersList, setUpdatedContainersList] = useState([]);

  useEffect(() => {
    if (storesListData && containersListData ) {
      const newContainersList = containersListData.map(container => {
        const store = storesListData.find(store => store.id === container.origin_store);
        return {
          ...container,
          origin_store: store ? store.name : 'Desconocido'
        };
      });
      setUpdatedContainersList(newContainersList);
    }
  }, [storesListData, containersListData]);
    
  const mostrarErrorRequest=(error)=>{
    if(error!=null){
      try{
        swal({
          title:"Error",
          text: "Resultado: "+error,
          icon: "error",
          button:"Aceptar"
        })
        if(error==="No tiene autorización o su sesión ha expirado"){
          auth.logout();
        }
      }catch(e){
        swal({
          title:"Error",
          text: "Error inesperado ",
          icon: "error",
          button:"Aceptar"
        })
      }
    }
    setErrorRequest(null)
  }
  
  const columns=[
    {
      flex: 0.5,
      field:"id", 
      headerName: "ID",
      cellClassName: "id--cell",
      headerClassName: "id--cell",
      hideable:false
    },
    {
      field:"container_date", 
      headerName: "Fecha",
      flex: 0.5, 
      cellClassName: "container_date-column--cell",
      minWidth:120
    },
    {
      field:"container_code", 
      headerName: "Codigo",
      flex: 1.5, 
      cellClassName: "container_code-column--cell",
      minWidth:120
    },
    {
      field:"country", 
      headerName: "Pais destino",
      flex: 1, 
      cellClassName: "country-column--cell",
      minWidth:120
    },
    {
      field:"origin_store", 
      headerName: "Bodega de origen",
      flex: 1, 
      cellClassName: "origin_store-column--cell",
      minWidth:120
    },
    {
      field: "status",
      headerName: "Estado",
      flex: 1,
      renderCell: ({ row }) => {
        const matchingStatus = statusListData.find(status => status.id === row.status);
        const isValidStatus = matchingStatus != null;
  
        return (
          <Select
            value={isValidStatus ? row.status : ''}
            onChange={(e) => handleStatusChange(row.id, e.target.value)}
            style={{ width: '100%' }}
          >
            {statusListData.map((status) => (
              <MenuItem key={status.id} value={status.id}>
                {status.name}
              </MenuItem>
            ))}
            {!isValidStatus && <MenuItem value=''>Estado no válido</MenuItem>}
          </Select>
        );
      },
    }
  ]
  
  if(containersListLoading||loadingRequest||storesListLoading||statusListLoading)
  return (<Loading/>)

  if(containersListError||errorRequest||storesListError||statusListError)
  return(<FatalError/>)
  
  return (
    <Box m="15px" marginBottom="64px">
      <Header title="Contenedores" subtitle="Listado de contenedores" />
      <Box m="5px 0px 50px 0" /*height={isNonMobile?"100%":undefined}*/ sx={{
        "& .MuiDataGrid-root":{
          border: "none",
        },
        "& .MuiDataGrid-cell":{
          borderBottom: "none"
        },
        "& .MuiDataGrid-columnHeaders":{
          backgroundColor: colors.blueAccent[700],
          borderBottom: "none",
          color: "white"
        },
        "& .MuiDataGrid-virtualScroller":{
          backgroundColor: colors.primary[400],
        },
        "& .MuiDataGrid-footerContainer":{
          borderTop: "none",
          backgroundColor: colors.blueAccent[700],
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text":{
          color: `${colors.grey[100]} !important`,
        },
        "& .MuiToolbar-gutters":{
          color: "white"
        }

      }}>
        {containersListData && storesListData && statusListData &&<DataGrid
          autoHeight
          initialState={{
            columns: {
              columnVisibilityModel: {
                // Hide columns status and traderName, the other columns will remain visible
                //id: false,
                actions:true
              },
            },
          }}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          rows={updatedContainersList}
          columns={columns}
          getRowId={(row) => row.id}
          density="compact"
          slots={{
            toolbar: GridToolbar,
          }}
        />}
      </Box>    
    </Box>
  );
}
  
export default Contenedores;