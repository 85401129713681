import React, { useEffect, useState } from 'react';
import { Box, Button, MenuItem, InputLabel, FormControl, Select,TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const SearchComponent = ({ onSearch }) => {
  const [searchParam, setSearchParam] = useState('all');
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    const handleResetSearch = () => {
      setSearchValue('');
      onSearch({ parameter: searchParam, value: '' }); 
    };

    window.addEventListener('resetSearch', handleResetSearch);

    return () => {
      window.removeEventListener('resetSearch', handleResetSearch);
    };
  }, [searchParam, onSearch]);

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value.trim() === "") {
      onSearch({ parameter: searchParam, value: '' });
    }
  };

  const handleParamChange = (e) => {
    setSearchParam(e.target.value);
    if (e.target.value.trim() === "") {
      onSearch({ parameter: searchParam, value: e.target.value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    onSearch({ parameter: searchParam, value: searchValue.trim() });
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', alignItems: 'center',gap:1}}>
      <FormControl>
        <InputLabel id="search-param-label">Parámetro</InputLabel>
        <Select
          size="small"
          labelId="search-param-label"
          id="search-param"
          value={searchParam}
          label="Parámetro"
          onChange={handleParamChange}
        >
          <MenuItem value="all">Todo</MenuItem>
          <MenuItem value="senders_phone">Teléfono</MenuItem>
          <MenuItem value="status">Estado</MenuItem>
          <MenuItem value="correlative">Identificador</MenuItem>
          <MenuItem value="senders_name">Cliente</MenuItem>
        </Select>
      </FormControl>
      <Box sx={{ flexGrow: 1 }}>
      <TextField
        size="small"
        type="text"
        label="Buscar"
        placeholder='Ingrese su búsqueda'
        value={searchValue}
        onChange={handleSearchChange}
        style={{ width: '100%' }}
        />
      </Box>
      <Button type="submit" variant="contained" color="secondary"><SearchIcon/>Buscar</Button>
    </Box>
  );
};

export default SearchComponent;