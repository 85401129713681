import { Box,Button,useMediaQuery,useTheme,TextField } from "@mui/material";
import { DataGrid, esES, GridToolbar } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../../auth/useAuth";
import Header from "../../../../components/Header";
import { useFetchGET } from "../../../../state/FetchHelpers";
import { newOriginCountryRoute, originCountryRoutesRoute } from "../../../../config";
import Loading from "../../../global/Loading";
import FatalError from "../../../global/FatalError";
import AddIcon from '@mui/icons-material/Add';
import { tokens } from "../../../../theme";
import { useEffect, useState } from "react";
import swal from "sweetalert";
import * as yup from "yup";
import { Formik } from "formik"
import BorderColorIcon from '@mui/icons-material/BorderColor';

const RutasPaisOrigen = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode)
  const auth = useAuth();
  const token = auth.globals.token

  const isNonMobile = useMediaQuery("(min-width:600px)")


  const [ loadingRequest, setLoadingRequest ] = useState(false)
  const [ errorRequest, setErrorRequest ] = useState(null)

  const [reloadData, setReloadData] = useState(false);
  const handleReloadData = () => {
    setReloadData(true);
  };



  useEffect(() => {
    // Llama a la función para recargar datos cuando reloadData cambie a true
    if (reloadData) {
      handleReloadData();
      setReloadData(false);
      setInicitalValues({
        route_name: ""
      })
    }
  }, [reloadData,auth.globals.id]);

  const { data: routesListData, loading: routesListLoading, error: routesListError } = useFetchGET(originCountryRoutesRoute(),token,[],reloadData);
  //const {data,loading,error} = useFetch2(_user!=null?`${url}/api/pools/user/${_user}`:`${url}/api/pools`,token)

  //const isNonMobile = useMediaQuery("(min-width:600px)")
  const navigate = useNavigate();

  const mostrarErrorRequest=(error)=>{
    if(error!=null){
      try{
        swal({
          title:"Error",
          text: "Resultado: "+error,
          icon: "error",
          button:"Aceptar"
        })
        if(error==="No tiene autorización o su sesión ha expirado"){
          auth.logout();
        }
      }catch(e){
        swal({
          title:"Error",
          text: "Error inesperado ",
          icon: "error",
          button:"Aceptar"
        })
      }
    }
    setErrorRequest(null)
  }

  const alertSucess=(text_title,text_content='')=>{    
    try{
      swal({
        title:text_title,
        text: text_content,
        icon: "success",
        button:"Aceptar"
      })
    }catch(e){
      swal({
        title:"Error inesperado",
        text: "Error inesperado ",
        icon: "error",
        button:"Aceptar"
      })
    }
    navigate("/Stores")
  }

  const newRoute = async(body) =>{
    //console.log('bpdy',body)
    try{
      
      let res = await fetch(newOriginCountryRoute(), {
        method: "POST",
        headers: {
          //"Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Accept":"application/json",
          "Authorization": `Bearer ${token}`
          //"x-access-token": token,
        },
        body: JSON.stringify(body),
        //cors: 'no-cors'
      })
      let data = await res.json()
      if(res.ok){
        setLoadingRequest(false)
        alertSucess('Ruta Ingresada',data.message)
        navigate("/RutasOrigen")
        setReloadData(true);       
      }else{
        //console.log('res else',res)
        if(res.status === 401||res.status === 403){
          mostrarErrorRequest("No tiene autorización o su sesión ha expirado")          
        }
        mostrarErrorRequest(data.detail)
        setErrorRequest(data.detail)    
      }
      setLoadingRequest(false) 
      return data;
    }catch(errorRequest){
      //console.log('errorRequest',errorRequest)
      setLoadingRequest(false)
      setErrorRequest(errorRequest)
    }
  }

  const [initialValues,setInicitalValues] = useState({
    route_name: "",
  })

  const userSchema = yup.object().shape({
    route_name: yup.string().required("* Valor Requerido"),
  })


  const handleFormSubmit = (values) => {
    //console.log('values',values)
    setInicitalValues(values)
    setLoadingRequest(true)
    newRoute(values)
  }

  const columns=[
    {
      field:"id", 
      headerName: "ID",
      flex: 0.25,
      cellClassName: "id-column--cell",
      headerClassName: "id-header--cell",
      hideable:false
    },
    {
      //hide: true,
      field:"route_name",
      headerName: "Nombre",
      flex: 1.5, 
      cellClassName: "route_name-column--cell",
      minWidth:120
    },
    {
      minWidth:100,
      filterable:false,
      sortable:false,
      field:"edit", 
      headerName: "Acciones",
      headerAlign:"center",
      flex: 0.5, 
      cellClassName: "edit-column--cell",
      renderCell:({ row: { id,route_name} })=>{
      //renderCell:({ row: { id,estado} })=>{
        return(
          <Box
            width="80%"
            maxWidth="175px"
            height="75%"
            maxHeight="35px" 
            display="flex" 
            justifyContent="center" 
            m="0 auto">
            {<Button
              color="success"
              variant="contained"
              onClick={()=>{navigate("/RutasOrigen/DetalleRuta?id="+id+"&name="+route_name)}}>
            <BorderColorIcon/>
              <Box sx={{ml:"5px"}}>
                Editar
              </Box>
            </Button>}
          </Box>
        )
      }
    }
  ]

  if(loadingRequest)
  return (<Loading/>)

  if(routesListLoading)
  return (<Loading/>)

  if(errorRequest)
  return(<FatalError/>)

  if(routesListError)
  return(<FatalError/>)

  return (
    <Box p={1} >
      {/*<Box display="flex" justifyContent="space-between" alignItems="center">*/}
      {<Header title="Rutas EEUU" subtitle="Listado de Rutas"/>}
      <Formik
        enableReinitialize={true}
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema = {userSchema}
      >
      {({
        values, 
        errors, 
        touched, 
        handleBlur, 
        handleChange,
        handleSubmit,
        setFieldValue
      }) => (
        <form onSubmit={handleSubmit} >
          <Box
            display="grid" 
            gap="15px"
            marginBottom="15px" 
            gridTemplateColumns="repeat(12, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile? undefined : "span 12"}
            }}
          >        
            <TextField
                  fullWidth={!isNonMobile}
                  size="small"
                  variant="filled"
                  type="text"
                  label="Ingrese el nombre de la nueva ruta"
                  onBlur = {handleBlur}
                  onChange = {handleChange}
                  value = { values.route_name}
                  name = "route_name"
                  error = {!!touched.route_name && !!errors.route_name}
                  helperText={touched.route_name && errors.route_name}
                  sx={{ gridColumn: "span 6"}}
            />
            {/*<TextField
                  fullWidth={!isNonMobile}
                  size="small"
                  variant="filled"
                  type="text"
                  label="Direccion"
                  onBlur = {handleBlur}
                  onChange = {handleChange}
                  value = { values.address}
                  name = "address"
                  error = {!!touched.address && !!errors.address}
                  helperText={touched.address && errors.address}
                  sx={{ gridColumn: "span 8"}}
          />*/}
          </Box>
              <Box display="flex" justifyContent="end" mt="20px">               
                <Button type="submit" color="secondary" variant="contained">
                  <AddIcon/>
                    Agregar nueva Ruta
                </Button>                           
              </Box>
        </form>
        )}
      </Formik>
          {/*<Box
              width="100px"
              p= "5px"
              display="flex"
              justifyContent="center"
              backgroundColor= {colors.greenAccent[600]}
              borderRadius = "4px"
              onClick={()=>{navigate("/Clientes/Form")}}
              >
              <PersonAddIcon/>
              <Typography color={colors.grey[100]} sx={{ml:"5px"}}>
                Nuevo
              </Typography>
          </Box>*/}
      <Box m="5px 0px 50px 0" /*height={isNonMobile?"100%":undefined}*/ sx={{
        "& .MuiDataGrid-root":{
          border: "none",
        },
        "& .MuiDataGrid-cell":{
          borderBottom: "none"
        },
        "& .MuiDataGrid-columnHeaders":{
          backgroundColor: colors.blueAccent[700],
          borderBottom: "none",
          color: "white"
        },
        "& .MuiDataGrid-virtualScroller":{
          backgroundColor: colors.primary[400],
        },
        "& .MuiDataGrid-footerContainer":{
          borderTop: "none",
          backgroundColor: colors.blueAccent[700],
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text":{
          color: `${colors.grey[100]} !important`,
        },
        "& .MuiToolbar-gutters":{
          color: "white"
        }

      }}>
        <DataGrid
          autoHeight
          initialState={{
            columns: {
              columnVisibilityModel: {
                // Hide columns status and traderName, the other columns will remain visible
                id: false,
              },
            },
          }}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          rows={routesListData/*cambiarStatus(storesListData)*/}
          columns={columns}
          getRowId={(row) => row.id}
          density="compact"
          slots={{
            toolbar: GridToolbar,
          }}
          /*columnVisibilityModel={{
            id: false,
          }}*/
          
        />
      </Box>
    </Box>
  );


}

export default RutasPaisOrigen;