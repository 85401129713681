import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';
//import { useFormikContext } from 'formik';
import { changePasswordRoute } from '../../../../config';
import { useNavigate } from "react-router-dom";
import useAuth from '../../../../auth/useAuth';
import swal from 'sweetalert';
import Loading from '../../../global/Loading';

const ChangePasswordDialog = ({ openChangePasswordDialog, setOpenChangePasswordDialog}) => {
  const auth = useAuth();
  const token = auth.globals.token
  const [newPassword,setNewPassword] = useState('');
  const [oldPassword,setOldPassword] = useState('');
  const [confirmPassword,setConfirmPassword] = useState('');
  const [ loadingRequest, setLoadingRequest ] = useState(false)
  //const { setFieldValue } = useFormikContext();
  const navigate = useNavigate();

  const activateInactivateBox = async() =>{
    try{
      setLoadingRequest(true)
      let res = await fetch(changePasswordRoute(auth.globals.id), {
        method: "PUT",
        headers: {
          //"Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Accept":"application/json",
          "Authorization": `Bearer ${token}`
          //"x-access-token": token,
        },
        body: JSON.stringify(
          {
            "old_password": oldPassword,
            "new_password": newPassword
          }
        ),
        //cors: 'no-cors'
      })
      let data = await res.json()
      //console.log('Change password: ',data)
      if(res.ok){
        alertSucess("Cambio de password exitoso, por favor ingrese con la contraseña nueva",data.message)
        auth.logout()
        navigate("/Dashboard")
      }else{
        if(res.status === 401||res.status === 403){
          mostrarErrorRequest("No tiene autorización o su sesión ha expirado")          
        }
        mostrarErrorRequest(data.detail)    
      }
      setLoadingRequest(false)
      setNewPassword('')
      setOldPassword('')
      setConfirmPassword('')
      return data;
    }catch(errorRequest){
      mostrarErrorRequest(errorRequest)
      setLoadingRequest(false)
      setNewPassword('')
      setOldPassword('')
      setConfirmPassword('')
    }
  }

  const mostrarErrorRequest=(error)=>{
    if(error!=null){
      try{
        swal({
          title:"Error",
          text: "Resultado: "+error,
          icon: "error",
          button:"Aceptar"
        })
        if(error==="No tiene autorización o su sesión ha expirado"){
          auth.logout();
        }
      }catch(e){
        swal({
          title:"Error",
          text: "Error inesperado ",
          icon: "error",
          button:"Aceptar"
        })
      }
    }
  }

  const alertSucess=(text_title,text_content='')=>{    
    try{
      swal({
        title:text_title,
        text: text_content,
        icon: "success",
        button:"Aceptar"
      })
    }catch(e){
      swal({
        title:"Error inesperado",
        text: "Error inesperado ",
        icon: "error",
        button:"Aceptar"
      })
    }
    navigate("/Dashboard")
  }

  const handleChangePassword = () => {
    if(oldPassword && newPassword && confirmPassword ){
      if (newPassword !== confirmPassword) {
        swal({
          title: "Error",
          text: "La nueva contraseña y la confirmación no coinciden",
          icon: "error",
          button: "Aceptar"
        });
        return; // Detiene la ejecución de la función aquí
      }else if (newPassword.length < 8){
        swal({
            title: "Error",
            text: "La nueva contraseña debe tener 8 caracteres como mínimo",
            icon: "error",
            button: "Aceptar"
          });
          return;    
      }else{
        setOpenChangePasswordDialog(false);
        activateInactivateBox()        
      }
    }else{
      swal({
        title: "Error",
        text: "Complete todos los campos",
        icon: "error",
        button: "Aceptar"
        });
        return;         
      }
  };

  if(loadingRequest)
  return(<Loading/>)

  return (
    <Dialog open={openChangePasswordDialog} onClose={() => setOpenChangePasswordDialog(false)}>
      <DialogContent>
        <DialogTitle>Cambio de Contraseña</DialogTitle>
        <TextField
          label="Contraseña Actual"
          value={oldPassword}
          onChange={e => setOldPassword(e.target.value)}
          fullWidth
          margin="normal"
          type='password'
        />
        <TextField
          label="Nueva Contraseña"
          value={newPassword}
          onChange={e => setNewPassword(e.target.value)}
          fullWidth
          margin="normal"
          type='password'
        />
        <TextField
          label="Confirmar nueva contraseña"
          value={confirmPassword}
          onChange={e => setConfirmPassword(e.target.value)}
          fullWidth
          margin="normal"
          type='password'        
        />
        <DialogActions>
          <Button onClick={() => setOpenChangePasswordDialog(false)}>Cancelar</Button>
          <Button color="success" onClick={handleChangePassword}>Aceptar</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default ChangePasswordDialog;