import { Box,Button,useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { DataGrid, esES, GridToolbar } from "@mui/x-data-grid";
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom";
import Header from "../../../../components/Header";
import { tokens } from "../../../../theme";
import useAuth from "../../../../auth/useAuth";
import { usersListRoute, userChangeStatusRoute } from "../../../../config";
import Loading from "../../../global/Loading";
//import { useFetchGET as fetchUsuarios } from "../../../../state/useFetch";
import { useFetchGET } from "../../../../state/FetchHelpers";
import FatalError from "../../../global/FatalError";
import { useState,useEffect } from "react";
import ToggleOnOutlinedIcon from '@mui/icons-material/ToggleOnOutlined';
import ToggleOffOutlinedIcon from '@mui/icons-material/ToggleOffOutlined';
import BlockIcon from '@mui/icons-material/Block';
import swal from 'sweetalert';

const Users = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode)
  const auth = useAuth();
  const token = auth.globals.token
  const [ loadingRequest, setLoadingRequest ] = useState(false)
  const [ errorRequest, setErrorRequest ] = useState(null)
  const statusOptions = [
    { id: 1, name: 'Activo' },
    { id: 2, name: 'Inactivo' },
  ];

  const cambiarStatus = (data) => {
    const newData = data.map((item) => {
      const statusOption = statusOptions.find((option) => option.id === item.status);
      if (statusOption) {
        return { ...item, status: statusOption.name };
      }
      return item;
    });
    return newData;
  };


  const mostrarErrorRequest=(error)=>{
    if(error!=null){
      try{
        swal({
          title:"Error al cambiar el estado del usuario",
          text: "Resultado: "+error,
          icon: "error",
          button:"Aceptar"
        })
        if(error==="No tiene autorización o su sesión ha expirado"){
          auth.logout();
        }
      }catch(e){
        swal({
          title:"Error al cambiar el estado del usuario",
          text: "Error al cambiar el estado del usuario",
          icon: "error",
          button:"Aceptar"
        })
      }
    }
    setErrorRequest(null)
  }
  //const {data,loading,error} = fetchUsuarios(usersListRoute(),token)
  const [reloadData, setReloadData] = useState(false);
  const handleReloadData = () => {
    setReloadData(true);
  };

  useEffect(() => {
    // Llama a la función para recargar datos cuando reloadData cambie a true
    if (reloadData) {
      handleReloadData();
      setReloadData(false);
    }
  }, [reloadData]);

  const { data: usersData, loading: usersLoading, error: usersError } = useFetchGET(usersListRoute(), token, [], reloadData);

  //const { data: usersData, loading: usersLoading, error: usersError } = useFetchGET(usersListRoute(), token, []);
  //const { data: usersData2, loading: usersLoading2, error: usersError2 } = useFetchGET(usersListRoute(), token, []);

  //console.log(usersData)
  //console.log('dataaaaa',usersData)
  //console.log('dataaaaa22222',usersData2)
  const isNonMobile = useMediaQuery("(min-width:600px)")

  const navigate = useNavigate();

  const changeStatus = async(newStatus,user_id) =>{
    //console.log('bpdy',body)
    try{
      //setLoadingRequest(true)
      let res = await fetch(userChangeStatusRoute(user_id), {
        method: "PUT",
        headers: {
          //"Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Accept":"application/json",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(
          {
          "status": newStatus.toString()
          }),
        //cors: 'no-cors'
      })
      let data = await res.json()
      if(res.ok){
        setReloadData(true);             
      }else{
        //console.log('res else',res)
        if(res.status === 401||res.status === 403){
          mostrarErrorRequest("No tiene autorización o su sesión ha expirado")          
        }
        mostrarErrorRequest(data.detail)
        setErrorRequest(data.detail)    
      }
      setLoadingRequest(false) 
      return data;
    }catch(errorRequest){
      //console.log('errorRequest',errorRequest)
      setLoadingRequest(false)
      setErrorRequest(errorRequest)
    }
  }


  const columns=[
    {
      field:"id", 
      headerName: "ID",
      flex: 0.25,
      cellClassName: "id-column--cell",
      headerClassName: "id-header--cell",
      hideable:false,
      filterable:false
    },
    {
      field:"email", 
      headerName: "Email", 
      flex: 1, 
      cellClassName: "email-column--cell",
      minWidth:135
    },
    {
      //hide: true,
      field:"name", 
      headerName: "Nombre",
      flex: 0.75, 
      cellClassName: "name-column--cell",
      minWidth:120
    },
    {
      field:"lastname", 
      headerName: "Apellido",
      flex: 0.75, 
      cellClassName: "lastname-column--cell",
      minWidth:120
    },
    {
      field:"user", 
      headerName: "Usuario",
      flex: 0.75, 
      cellClassName: "user-column--cell",
      minWidth:120
    },
    {
      field:"status", 
      headerName: "Estado",
      headerAlign:"center",
      flex: 0.50, 
      cellClassName: "status-column--cell",
      minWidth:120,
      renderCell:({ row: { id,status } })=>{
        return(
          <Box
            width="80%"
            maxWidth="175px"
            height="75%"
            maxHeight="35px" 
            display="flex" 
            justifyContent="center" 
            m="0 auto">
            {id===auth.globals.id?<Button
              color="neutral"
              variant="contained"
              onClick={()=>{console.log('Accion invalida')}}>
            <BlockIcon/>
              <Box sx={{ml:"5px"}}>
                {status}
              </Box>
            </Button>:status==='Activo'?<Button
              color="success"
              variant="contained"
              onClick={()=>{changeStatus(2,id)}}>
            <ToggleOffOutlinedIcon/>
              <Box sx={{ml:"5px"}}>
                Activo
              </Box>
            </Button>:<Button
              color="warning"
              variant="contained"
              onClick={()=>{changeStatus(1,id)}}>
            <ToggleOnOutlinedIcon/>
              <Box sx={{ml:"5px"}}>
                Inactivo
              </Box>
            </Button>}
          </Box>
        )
      }
    },
    {
      minWidth:100,
      filterable:false,
      sortable:false,
      field:"actions", 
      headerName: "Ver",
      headerAlign:"center",
      flex: 0.5, 
      cellClassName: "actions-column--cell",
      renderCell:({ row: { id } })=>{
        return(
          <Box
            width="80%"
            maxWidth="175px"
            height="75%"
            maxHeight="35px" 
            display="flex" 
            justifyContent="center" 
            m="0 auto">
            <Button
              color="secondary"
              variant="contained"
              onClick={()=>{navigate("/Users/Form?id="+id)}}>
            <VisibilityIcon/>
              <Box sx={{ml:"5px"}}>
                Ver
              </Box>
            </Button>
          </Box>
        )
      }
    }
  ]

  if(usersLoading)
  return (<Loading/>)
  else
  if(usersError)
  return(<FatalError/>)
  else
  if(errorRequest)
  return(<FatalError/>)
  else
  if(loadingRequest)
  return (<Loading/>)

  return (
    <Box p={1} >
      {/*<Box display="flex" justifyContent="space-between" alignItems="center">*/}
        <Header title="Usuarios" subtitle="Listado de Usuarios"/>
          <Box display="flex" justifyContent="end" mt="20px" >
            <Button
              color="secondary"
              variant="contained"
              onClick={()=>{navigate("/Users/Form")}}>
              <AddIcon/>
              Nuevo Usuario
            </Button>
          </Box>
          {/*<Box
              width="100px"
              p= "5px"
              display="flex"
              justifyContent="center"
              backgroundColor= {colors.greenAccent[600]}
              borderRadius = "4px"
              onClick={()=>{navigate("/Clientes/Form")}}
              >
              <PersonAddIcon/>
              <Typography color={colors.grey[100]} sx={{ml:"5px"}}>
                Nuevo
              </Typography>
          </Box>*/}
      <Box m="5px 0px 50px 0" /*height={isNonMobile?"100%":undefined}*/ sx={{
        "& .MuiDataGrid-root":{
          border: "none",
        },
        "& .MuiDataGrid-cell":{
          borderBottom: "none"
        },
        "& .email-column--cell":{
          color: colors.greenAccent[500],
        },
        /*"& .id-column--cell":{
          display: 'none!important'
        },
        "& .id-header--cell":{
          display: 'none!important'
        },*/
        "& .MuiDataGrid-columnHeaders":{
          backgroundColor: colors.blueAccent[700],
          borderBottom: "none",
          color: "white"
        },
        "& .MuiDataGrid-virtualScroller":{
          backgroundColor: colors.primary[400],
        },
        "& .MuiDataGrid-footerContainer":{
          borderTop: "none",
          backgroundColor: colors.blueAccent[700],
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text":{
          color: `${colors.grey[100]} !important`,
        },
        "& .MuiToolbar-gutters":{
          color: "white"
        }

      }}>
        <DataGrid
          autoHeight
          initialState={{
            columns: {
              columnVisibilityModel: {
                // Hide columns status and traderName, the other columns will remain visible
                id: false,
                status: isNonMobile? true : false,
                name: isNonMobile? true : false,
                lastname: isNonMobile? true : false,
              },
            },
          }}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          rows={cambiarStatus(usersData)}
          columns={columns}
          getRowId={(row) => row.id}
          density="compact"
          slots={{
            toolbar: GridToolbar,
          }}
          /*columnVisibilityModel={{
            id: false,
          }}*/
          
        />
      </Box>
    </Box>
  );
}

export default Users;