import { Dialog, DialogTitle,DialogContent,FormControl,Select,MenuItem,TextField,InputLabel,DialogActions,Button,InputAdornment} from "@mui/material";
import React, { useEffect, useState } from 'react';
import { newPaymentRoute, usersByRolRoute } from '../../../../config';
import { useFetchGET } from '../../../../state/FetchHelpers';
import useAuth from '../../../../auth/useAuth';
import FatalError from '../../../global/FatalError';
import Loading from '../../../global/Loading';
import swal from "sweetalert";

const PaymentDialog = ({  openPaymentDialog, setOpenPaymentDialog, id, correlative, id_status,container_code,balance, setReloadData }) => {
  
  const auth = useAuth();
  const token = auth.globals.token
  const { data: usersListData, loading: usersListLoading, error: usersListError } = useFetchGET(usersByRolRoute(23), token, []);
  const [ loadingRequest, setLoadingRequest ] = useState(false)
  //console.log('usersListData',usersListData)
  const [payment, setPayment] = useState(balance)
  const [paymentMethod, setPaymentMethod] = useState('Tarjeta')
  const [userDriver, setUserDriver] = useState('')

  useEffect(() => {
    setPayment(balance);
  }, [balance]);
  

  const newPayment = async(body) =>{
    try{     
      console.log('json pago',body)     
      setLoadingRequest(true)
      let res = await fetch(newPaymentRoute(), {
        method: "POST",
        headers: {
          //"Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Accept":"application/json",
          "Authorization": `Bearer ${token}`
          //"x-access-token": token,
        },
        body: JSON.stringify(body),
        //cors: 'no-cors'
      })
      let data = await res.json()
      //console.log('resultados newPayment ',data)
      if(res.ok){
        alertSucess("Pago registrado con éxito",data.message)
        setOpenPaymentDialog(false);
        setReloadData(true)
                  
      }else{
        if(res.status === 401||res.status === 403){
          mostrarErrorRequest("No tiene autorización o su sesión ha expirado")          
        }
        mostrarErrorRequest(data.detail)  
      }
      setLoadingRequest(false)
      return data;
    }catch(errorRequest){
      //console.log('errorRequest',errorRequest)
      setLoadingRequest(false)
      mostrarErrorRequest(errorRequest)
      setReloadData(true)
      //setErrorRequest(errorRequest)
      //console.log('busqueda catch') 
    }
  }  

  const mostrarErrorRequest=(error)=>{
    if(error!=null){
      try{
        swal({
          title:"Error",
          text: "Resultado: "+error,
          icon: "error",
          button:"Aceptar"
        })
        if(error==="No tiene autorización o su sesión ha expirado"){
          auth.logout();
        }
      }catch(e){
        swal({
          title:"Error",
          text: "Error inesperado ",
          icon: "error",
          button:"Aceptar"
        })
      }
    }
    //setErrorRequest(null)
  }

  const mostrarAlertRequest=(info)=>{
    if(info!=null){
      try{
        swal({
          title:"Alerta",
          text: info,
          icon: "warning",
          button:"Aceptar"
        })
        
      }catch(e){
        swal({
          title:"Error",
          text: "Error inesperado ",
          icon: "error",
          button:"Aceptar"
        })
      }
    }
  }

  const alertSucess=(text_title,text_content='')=>{    
    try{
      swal({
        title:text_title,
        text: text_content,
        icon: "success",
        button:"Aceptar"
      })
    }catch(e){
      swal({
        title:"Error inesperado",
        text: "Error inesperado ",
        icon: "error",
        button:"Aceptar"
      })
    }
    //navigate("/Dashboard")
  }


  const handlePayment = () => {
    if (payment <= 0) {
      mostrarAlertRequest("Debe ingresar un pago mayor a 0")
      return; // Detiene la ejecución de la función aquí
    }
    if (userDriver === '') {
      mostrarAlertRequest("Seleccione un Repartidor")
      return; // Detiene la ejecución de la función aquí
    }

    const body = {
      "id_box":id.toString(),
      "box_status" : id_status.toString(),
      "payment_signature" : "",
      "comment" : "Pago desde cajas con saldo",
      "destination_user" : userDriver.toString(),
      "detail" : [
        {
          "payment_method": paymentMethod,
          "payment": payment.toString()
        }   
      ]
    }
    newPayment(body)
    //setOpenPaymentDialog(false);
  };


  if(loadingRequest||usersListLoading)
  return(<Loading/>)

  if(usersListError)
  return(<FatalError/>)

  return (
    <Dialog open={openPaymentDialog} onClose={() => setOpenPaymentDialog(false)}>
      <DialogTitle>{'Ingresar Pago Caja ' + correlative }</DialogTitle>
      <DialogContent>
        {/* Tipo de pago */}
        <FormControl fullWidth margin="normal" required>
          <InputLabel id="paymentMethod-label">Tienda de Origen</InputLabel>
          <Select
            labelId="paymentMethod-label"
            name="paymentMethod"
            value={paymentMethod}
            label="Tipo de pago"
            onChange={e => setPaymentMethod(e.target.value)}
            //onBlur={paymentFormik.handleBlur}
          >
              <MenuItem key={"Tarjeta"} value={"Tarjeta"}>
                {"Zelle"}
              </MenuItem>
              <MenuItem key={"Efectivo"} value={"Efectivo"}>
                {"Efectivo"}
              </MenuItem>
          </Select>
        </FormControl>
        {/* Código Contenedor */}
        <TextField
          disabled={true}
          label="Código Contenedor"
          name="container_code"
          value={container_code ?? ''}
          fullWidth
          margin="normal"
          //onBlur={paymentFormik.handleBlur}
        />
        {/* Pago */}
        <TextField
          label="Cantidad"
          name="payment"
          value={payment ?? ''}
          onChange={e => setPayment(e.target.value)}
          fullWidth
          margin="normal"
          //onBlur={paymentFormik.handleBlur}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                $
              </InputAdornment>
            ),
          }}
        />
        {/* Tienda de Origen */}
        <FormControl fullWidth margin="normal" required>
          <InputLabel id="user_driver-label">Repartidor</InputLabel>
          <Select
            labelId="user_driver-label"
            name="user_driver"
            value={userDriver}
            label="user_driver"
            onChange={e => setUserDriver(e.target.value)}
            //onBlur={paymentFormik.handleBlur}
          >
            {usersListData.map((user) => (
              <MenuItem key={user.id} value={user.id.toString()}>
                {user.name + ' ' + user.lastname}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenPaymentDialog(false)} color="error">Cancelar</Button>
        <Button onClick={handlePayment} color="success">
          Ingresar Pago
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PaymentDialog;