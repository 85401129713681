import { Box,Button,Table,TableBody,TableCell,TableContainer,TableHead,TableRow,Typography} from "@mui/material";
import Header from "../../../../components/Header";
import Loading from "../../../global/Loading";
import FatalError from "../../../global/FatalError";
import useAuth from "../../../../auth/useAuth";
//import SaveIcon from '@mui/icons-material/Save';
import { useState } from "react";
import { getBoxesByDateRoute, sendSMSRoute } from "../../../../config";
import swal from "sweetalert";
import { useFetchGET } from "../../../../state/FetchHelpers";
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import { useNavigate } from "react-router-dom";


const SmsDeRuta = ({permissionsListData}) => {
  const [ loadingRequest, setLoadingRequest ] = useState(false);
  const [ errorRequest, setErrorRequest ] = useState(null);
  const auth = useAuth();
  const token = auth.globals.token
  const navigate = useNavigate();

  const getLocalDate = () => {
    return new Intl.DateTimeFormat('en-CA', { // 'en-CA' utiliza el formato YYYY-MM-DD
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }).format(new Date(),'yyyy-MM-dd');
  };

  const today = getLocalDate()
  //console.log(today)

  const { data: boxesListData, loading: boxesListLoading, error: boxesListError } = useFetchGET(getBoxesByDateRoute(today),token,[]);
  //console.log('boxesListData',boxesListData)

  const sortedBoxesListData = boxesListData.sort((a, b) => {
    if(a.status_name < b.status_name) { return -1; }
    if(a.status_name > b.status_name) { return 1; }
    return 0;
  });

  const sendMessege = async(type) =>{
    try{            
      setLoadingRequest(true)
      let res = await fetch(sendSMSRoute(type), {
        method: "GET",
        headers: {
          //"Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Accept":"application/json",
          "Authorization": `Bearer ${token}`
          //"x-access-token": token,
        },
        //cors: 'no-cors'
      })
      let data = await res.json()
      console.log('resultados envio SMS ',data)
      if(res.ok){
        alertSucess("Mensajes enviados exitosamente","Mensajes enviados exitosamente")     
      }else{
        if(res.status === 401||res.status === 403){
          mostrarErrorRequest("No tiene autorización o su sesión ha expirado")          
        }
        mostrarErrorRequest("Error no esperado")
        console.log('error SMS',data.detail)     
      }
      setLoadingRequest(false)
      return data;
    }catch(err){
      //console.log('errorRequest',errorRequest)
      setLoadingRequest(false)
      setErrorRequest(err)
      console.log('catch SMS',err) 
    }
  }

  const mostrarErrorRequest=(error)=>{
    if(error!=null){
      try{
        swal({
          title:"Error",
          text: "Resultado: "+error,
          icon: "error",
          button:"Aceptar"
        })
        if(error==="No tiene autorización o su sesión ha expirado"){
          auth.logout();
        }
      }catch(e){
        swal({
          title:"Error",
          text: "Error inesperado ",
          icon: "error",
          button:"Aceptar"
        })
      }
    }
    //setErrorRequest(null)
  }

  const alertSucess=(text_title,text_content='')=>{    
    try{
      swal({
        title:text_title,
        text: text_content,
        icon: "success",
        button:"Aceptar"
      })
    }catch(e){
      swal({
        title:"Error inesperado",
        text: "Error inesperado ",
        icon: "error",
        button:"Aceptar"
      })
    }
  }

  if (loadingRequest||boxesListLoading)
  return (<Loading/>)
  if (errorRequest||boxesListError)
  return (<FatalError/>)

  return (
    <Box m="20px" marginBottom="64px">
      <Header title="SMS" subtitle="Envío de SMS"/>
      {sortedBoxesListData  && sortedBoxesListData.length > 0?(
        <Box sx={{ marginTop: 2 }}>
          <Box display="flex" justifyContent="end" mt="20px">
            <Button sx={{marginRight: "10px"}} color="secondary" variant="contained" onClick={()=>{sendMessege('delivery')}}>
              <ForwardToInboxIcon/>
              Enviar mensajes Delivery
            </Button>
            <Button sx={{marginRight: "10px"}} color="secondary" variant="contained" onClick={()=>{sendMessege('pickup')}}>
              <ForwardToInboxIcon/>
              Enviar mensajes Pickup
            </Button>
            {permissionsListData.includes(33)&&<Button sx={{marginRight: "10px"}} color="secondary" variant="contained" onClick={()=>{navigate("/CajasPorFecha")}}>
              <EditCalendarIcon/>
              Proximos Delivery/Pickups
            </Button>}                              
          </Box> 
          <Typography variant="h6" sx={{ marginBottom: 2 }}> * Si Driver Asignado se encuentra vacío significa que esa ruta aún no se encuentra asignada para este día.</Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell component="th" style={{ fontWeight: 'bold' }}>ID</TableCell>
                  <TableCell component="th" style={{ fontWeight: 'bold' }}>Empresa</TableCell>
                  <TableCell component="th" style={{ fontWeight: 'bold' }}>Cliente</TableCell>
                  <TableCell component="th" style={{ fontWeight: 'bold' }}>Teléfono</TableCell>
                  <TableCell component="th" style={{ fontWeight: 'bold' }}>Dirección</TableCell>
                  <TableCell component="th" style={{ fontWeight: 'bold' }}>Estado</TableCell>
                  <TableCell component="th" style={{ fontWeight: 'bold' }}>Ruta</TableCell>
                  <TableCell component="th" style={{ fontWeight: 'bold' }}>Driver Asignado</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedBoxesListData.map((box) => (
                  <TableRow key={box.id} >
                    <TableCell style={{ color: box.driver === null ? 'red' : 'inherit' }}>{box.correlative}</TableCell>
                    <TableCell style={{ color: box.driver === null ? 'red' : 'inherit' }}>{box.company}</TableCell>
                    <TableCell style={{ color: box.driver === null ? 'red' : 'inherit' }}>{box.senders_name}</TableCell>
                    <TableCell style={{ color: box.driver === null ? 'red' : 'inherit' }}>{box.senders_phone}</TableCell>
                    <TableCell style={{ color: box.driver === null ? 'red' : 'inherit' }}>{box.senders_address}</TableCell>
                    <TableCell style={{ color: box.driver === null ? 'red' : 'inherit' }}>{box.status_name}</TableCell>
                    <TableCell style={{ color: box.driver === null ? 'red' : 'inherit' }}>{box.route_name}</TableCell>
                    <TableCell style={{ color: box.driver === null ? 'red' : 'inherit' }}>{box.driver}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ):
      <Typography variant="h3" sx={{ marginBottom: 2 }}> No existen registros para este día...</Typography>}
    </Box>
  );
}

export default SmsDeRuta;