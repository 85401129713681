import { Box,useTheme,TextField,Button} from "@mui/material";
//import { useNavigate } from "react-router-dom";
import { DataGrid, esES } from "@mui/x-data-grid";
import useAuth from "../../../../auth/useAuth";
import Header from "../../../../components/Header";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { tokens } from "../../../../theme";
import { getBoxAuditRoute } from "../../../../config";
import FatalError from "../../../global/FatalError";
import Loading from "../../../global/Loading";
import swal from "sweetalert";
import { format } from 'date-fns';
import React from 'react';
import { useLocation } from "react-router-dom";

const Audit = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode)
  const auth = useAuth();
  const token = auth.globals.token
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const _id = query.get('id')

  const [ loadingRequest, setLoadingRequest ] = useState(false)
  const [ errorRequest, setErrorRequest ] = useState(null)

  const [sortModel, setSortModel] = useState([
    {
      field: 'last_update_date',
      sort: 'desc',
    },
  ]);

  const getBoxAudit = async(id_box) =>{
    if(id_box && id_box !== "" ){
      try{
        setLoadingRequest(true)
        //console.log('id_box',id_box)
        let res = await fetch(getBoxAuditRoute(id_box), {
          method: "GET",
          headers: {
            //"Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Accept":"application/json",
            "Authorization": `Bearer ${token}`
            //"x-access-token": token,
          },
          //cors: 'no-cors'
        })
        let data = await res.json()
        //console.log('History',data)
        if(res.ok){
          setRecord(data)
        }else{
          //console.log('res',res)
          if(res.status === 404){
            mostrarAlertRequest("Caja no encontrada")
            setRecord(null)
          }
          if(res.status === 401||res.status === 403){
            mostrarErrorRequest("No tiene autorización o su sesión ha expirado")          
          }  
        }
        setLoadingRequest(false)
        return data;
      }catch(errorRequest){
        setLoadingRequest(false)
        setErrorRequest(errorRequest)
      }
    }
  }

  const [record, setRecord] = useState(null);

  const searchFormik = useFormik({
      initialValues: {
        recordId: '',
      },
      onSubmit: (values) => {
        //console.log(values)
        getBoxAudit(values.recordId)
      },
  });

  // Función para manejar la búsqueda inicial
  const handleInitialSearch = () => {
    if (_id) {
      searchFormik.setFieldValue('recordId', _id, false);
      searchFormik.submitForm();
    }
  };

  useEffect(() => {
    handleInitialSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_id]);

  const mostrarErrorRequest=(error)=>{
    if(error!=null){
      try{
        swal({
          title:"Error",
          text: "Resultado: "+error,
          icon: "error",
          button:"Aceptar"
        })
        if(error==="No tiene autorización o su sesión ha expirado"){
          auth.logout();
        }
      }catch(e){
        swal({
          title:"Error",
          text: "Error inesperado ",
          icon: "error",
          button:"Aceptar"
        })
      }
    }
    setErrorRequest(null)
  }

  const mostrarAlertRequest=(info)=>{
    if(info!=null){
      try{
        swal({
          title:"Alerta",
          text: info,
          icon: "warning",
          button:"Aceptar"
        })
        
      }catch(e){
        swal({
          title:"Error",
          text: "Error inesperado ",
          icon: "error",
          button:"Aceptar"
        })
      }
    }
  }

  const customLocaleText = {
    ...esES.components.MuiDataGrid.defaultProps.localeText,
    noRowsLabel: 'No hay se encontraron resultados...',
  };

  const formatDateTime = (value) => {
    const date = new Date(value);
    return isNaN(date) ? "" : format(date, 'yyyy-MM-dd HH:mm');
  };

  const rows = record && record.map((element, index) => ({
    id: index,
    actions: element.actions,
    field_name: element.field_name,
    last_update_date: element.last_update_date,
    last_update_user_name: element.last_update_user_name,
    new_value: element.new_value,
    old_value: element.old_value,
  }));

  const columns=[
    {
      flex:0.75,
      minWidth:75,
      //hide: true,
      field:"field_name", 
      headerName: "Nombre del campo",
      cellClassName: "field_name-column--cell",
    },
    {
      flex:0.35,
      minWidth:50,
      //hide: true,
      field:"actions", 
      headerName: "Acción",
      cellClassName: "actions-column--cell",
    },
    {
      flex:2.5,
      minWidth:250,
      //hide: true,
      field:"old_value", 
      headerName: "Valor anterior",
      cellClassName: "old_value-column--cell",
    },
    {
      flex:2.5,
      minWidth:250,
      //hide: true,
      field:"new_value", 
      headerName: "Nuevo Valor",
      cellClassName: "new_value-column--cell",
    },
    {
      flex:0.75,
      minWidth:75,
      //hide: true,
      field:"last_update_user_name", 
      headerName: "Usuario",
      cellClassName: "last_update_user_name-column--cell",
    },
    {
      flex:0.75,
      minWidth:75,
      //hide: true,
      field:"last_update_date", 
      headerName: "Fecha de la acción",
      renderCell: ({ row: { last_update_date} }) => formatDateTime(last_update_date)
    },
  ]

  if(loadingRequest)
  return(<Loading/>)

  if(errorRequest)
  return(<FatalError/>)


  return (
    <Box m="20px" marginBottom="64px">
      {/*<Box display="flex" justifyContent="space-between" alignItems="center">*/}
        <Header title="Audit" subtitle={"Ingrese la caja a buscar"}/>
        <form onSubmit={searchFormik.handleSubmit}>
        {
        <Box 
            sx={{ 
              display: 'flex', 
              justifyContent: 'left', 
              alignItems: 'center', 
              gap: 2,  // Espacio entre los elementos
              padding: 1 // Espacio alrededor del contenido
            }}
          >
            <TextField
              variant="filled"
              label="Código de caja"
              name="recordId"
              value={searchFormik.values.recordId}
              onChange={searchFormik.handleChange}
              size="small"
            />
            <Button type="submit" variant="contained" color="secondary">
              Buscar
            </Button>
          </Box>
        }
        </form>

      {record && (
        <Box m="5px 0px 50px 0" /*height={isNonMobile?"100%":undefined}*/ sx={{
          "& .MuiDataGrid-root":{
            border: "none",
          },
          "& .MuiDataGrid-cell":{
            borderBottom: "none"
          },
          "& .MuiDataGrid-columnHeaders":{
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            color: "white"
          },
          "& .MuiDataGrid-virtualScroller":{
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer":{
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text":{
            color: `${colors.grey[100]} !important`,
          },
          "& .MuiToolbar-gutters":{
            color: "white"
          }

        }}>
          <DataGrid
            autoHeight
            initialState={{
              columns: {
                columnVisibilityModel: {
                  id: false,
                },
              },
            }}
            localeText={customLocaleText}
            rows={rows}
            columns={columns}
            sortModel={sortModel}
            onSortModelChange={(newModel) => setSortModel(newModel)}
            getRowId={(row) => row.id}
            density="compact"
            /*slots={{
              toolbar: GridToolbar,
            }}*/
          />
        </Box>
      )}
    </Box>
  );
}

export default Audit;