import React from 'react'
import AuthProvider from './auth/AuthProvider';
import { ColorModeContext, useMode} from "./theme";
import AppRouter from "./routers/AppRouter"
import { CssBaseline, ThemeProvider} from "@mui/material";
import { BrowserRouter as Router} from "react-router-dom";
import { ProSidebarProvider } from 'react-pro-sidebar';
import GoogleMapsScriptLoader from './GoogleMapsScriptLoader';

function App() {

  //const estadoIncial = initialState.mode;
  const estadoIncial = localStorage.getItem('themeMode') || 'light';
  const [theme, colorMode] = useMode(estadoIncial);

  return (
    <AuthProvider>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <div translate="no">
          <ProSidebarProvider>
            <CssBaseline/>
            <Router>
              <GoogleMapsScriptLoader>
                <AppRouter/>
              </GoogleMapsScriptLoader>
            </Router>
          </ProSidebarProvider>  
          </div>            
        </ThemeProvider>
      </ColorModeContext.Provider>
    </AuthProvider>
  );
}

export default App;
