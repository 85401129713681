import { Route, Routes ,Navigate} from "react-router-dom";
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';


import Topbar from "../scenes/global/Topbar";
//import Sidebar from "../scenes/global/Sidebar";
//import Sidebar2 from "../scenes/global/Sidebar2";
import MySidebar from "../scenes/global/MySidebar";
import Dashboard from "../scenes/pages/Dashboard";
import LogIn from "../scenes/LogIn/LogIn";
import useAuth from "../auth/useAuth";
import NoDataFound from "../scenes/global/NoDataFound";
import Footer from "../scenes/global/Footer";
import Users from "../scenes/pages/Apps/Users/Users";
import DeliveryForm from "../scenes/pages/Apps/Delivery/DeliveryForm";
import PickupForm from "../scenes/pages/Apps/Pickup/PickupForm";
import Reparto from "../scenes/pages/Apps/Reparto/Reparto";
import Reportes from "../scenes/pages/Apps/Reportes/Reportes";
import Gastos from "../scenes/pages/Apps/Gastos/Gastos";
import IngresoEfectivo from "../scenes/pages/Apps/IngresoEfectivo/IngresoEfectivo";
import DropOff from "../scenes/pages/Apps/DropOff/DropOff";
import Carga from "../scenes/pages/Apps/Carga/Carga";
import CierreRuta from "../scenes/pages/Apps/CierreRuta/CierreRuta";
import Cuenta from "../scenes/pages/Cuenta";
import FormUser from "../scenes/pages/Apps/Users/FormUser";
import Roles from "../scenes/pages/Apps/Catalogos/Roles";
import Stores from "../scenes/pages/Apps/Catalogos/Stores";
import Companies from "../scenes/pages/Apps/Catalogos/Companies";
import ActionsRoles from "../scenes/pages/Apps/Catalogos/ActionsRoles";
import BoxSizes from "../scenes/pages/Apps/Catalogos/BoxSizes";
import RutasPaisOrigen from "../scenes/pages/Apps/RutasPaisOrigen/RutasPaisOrigen";
import DetalleRuta from "../scenes/pages/Apps/RutasPaisOrigen/DetalleRuta";
import VerPagos from "../scenes/pages/Apps/IngresoEfectivo/VerPagos";
import AsignaDriver from "../scenes/pages/Apps/RutasPaisOrigen/AsignaDriver";
import Contenedores from "../scenes/pages/Apps/Carga/Contenedores";
import Cuadre from "../scenes/pages/Apps/Cuadre/Cuadre";
import SmsDeRuta from "../scenes/pages/Apps/SMSdeRuta/SmsDeRuta";
import { useFetchGET } from "../state/FetchHelpers";
import { getPermissionsRoute } from "../config";
import Loading from "../scenes/global/Loading";
import BoxesByDate from "../scenes/pages/Apps/SMSdeRuta/BoxesByDate";
import CajasConSaldo from "../scenes/pages/Apps/CajasConSaldo/CajasConSaldo";
import Tracking from "../scenes/pages/Apps/Tracking/Tracking";
import BoxSizesPrices from "../scenes/pages/Apps/Catalogos/BoxSizesPrices";
import Audit from "../scenes/pages/Apps/Audit/Audit";
import Receipts from "../scenes/pages/Apps/Reportes/Receipts";

function AppRouter(){
  const auth = useAuth();
  let location = useLocation();
  const token = auth.globals.token

  const [showTopbarFooter, setShowTopbarFooter] = useState(true);
  const { data: permissionsListData, loading: permissionsListLoading } = useFetchGET(auth.globals.auth?getPermissionsRoute(auth.globals.id):undefined,token,[]);


  useEffect(() => {
    setShowTopbarFooter(location.pathname !== '/NotFound');
  },[location]);


  if(permissionsListLoading)
  return(<Loading/>)

  return(
    <div className="app">
      {<MySidebar permissionsListData={permissionsListData}/>}
      <main className="content">
        {showTopbarFooter&&<Topbar/>  }
          <Routes>
            <Route exact path="/" element={!auth.globals.auth?<LogIn/>:<Navigate to="/Dashboard" />} />
            <Route exact path="/Dashboard" element={auth.globals.auth?<Dashboard permissionsListData={permissionsListData}/>:<Navigate to="/" />} />
            <Route exact path="/Cuenta" element={auth.globals.auth?<Cuenta/>:<Navigate to="/" />} />
            <Route exact path="/NotFound" element={<NoDataFound/>} />
            {/*USUARIOS*/}
            {permissionsListData.includes(22)&&<Route exact path="/Users" element={auth.globals.auth?<Users permissionsListData={permissionsListData}/>:<Navigate to="/" />} />}
            {permissionsListData.includes(22)&&<Route exact path="/Users/Form" element={auth.globals.auth?<FormUser permissionsListData={permissionsListData}/>:<Navigate to="/" />} />}
            {/*DELIVERY*/}
            {permissionsListData.includes(10)||permissionsListData.includes(28)?<Route exact path="/DeliveryForm" element={auth.globals.auth?<DeliveryForm permissionsListData={permissionsListData}/>:<Navigate to="/" />} />:undefined}
            {/*PICKUP*/}
            {permissionsListData.includes(11)||permissionsListData.includes(29)?<Route exact path="/PickupForm" element={auth.globals.auth?<PickupForm permissionsListData={permissionsListData}/>:<Navigate to="/" />} />:undefined}
            {/*REPARTO*/}
            {permissionsListData.includes(21)&&<Route exact path="/Reparto" element={auth.globals.auth?<Reparto permissionsListData={permissionsListData}/>:<Navigate to="/" />} />}
            {/*REPORTES*/}
            {permissionsListData.includes(31)&&<Route exact path="/Reportes" element={auth.globals.auth?<Reportes permissionsListData={permissionsListData}/>:<Navigate to="/" />} />}
            {/*CIERRE DE RUTA*/}
            {(permissionsListData.includes(14)||permissionsListData.includes(41))&&<Route exact path="/CierreRuta" element={auth.globals.auth?<CierreRuta permissionsListData={permissionsListData}/>:<Navigate to="/" />} />}
            {/*GASTOS*/}
            {permissionsListData.includes(16)&&<Route exact path="/Gastos" element={auth.globals.auth?<Gastos permissionsListData={permissionsListData}/>:<Navigate to="/" />} />}
            {/*DROP OFF*/}
            {permissionsListData.includes(20)&&<Route exact path="/DropOff" element={auth.globals.auth?<DropOff permissionsListData={permissionsListData}/>:<Navigate to="/" />} />}
            {/*CONTENEDORES*/}
            {permissionsListData.includes(18)&&<Route exact path="/Carga" element={auth.globals.auth?<Carga permissionsListData={permissionsListData}/>:<Navigate to="/" />} />}
            {permissionsListData.includes(19)&&<Route exact path="/Contenedores" element={auth.globals.auth?<Contenedores permissionsListData={permissionsListData}/>:<Navigate to="/" />} />}
            {/*INGRESO EFECTIVO*/}
            {permissionsListData.includes(12)&&<Route exact path="/IngresoEfectivo" element={auth.globals.auth?<IngresoEfectivo permissionsListData={permissionsListData}/>:<Navigate to="/" />} />}
            {permissionsListData.includes(27)&&<Route exact path="/VerPagos" element={auth.globals.auth?<VerPagos permissionsListData={permissionsListData}/>:<Navigate to="/" />} />}
            {/*CATALOGO EMPRESAS*/}
            {permissionsListData.includes(23)&&<Route exact path="/Companies" element={auth.globals.auth?<Companies permissionsListData={permissionsListData}/>:<Navigate to="/" />} />}
            {/*CATALOGO ROLES*/}
            {permissionsListData.includes(25)&&<Route exact path="/Roles" element={auth.globals.auth?<Roles permissionsListData={permissionsListData}/>:<Navigate to="/" />} />}
            {permissionsListData.includes(25)&&<Route exact path="/Roles/Acciones" element={auth.globals.auth?<ActionsRoles permissionsListData={permissionsListData}/>:<Navigate to="/" />} />}
            {/*CATALOGO BOX SIZES*/}
            {permissionsListData.includes(26)&&<Route exact path="/Sizes" element={auth.globals.auth?<BoxSizes permissionsListData={permissionsListData}/>:<Navigate to="/" />} />}
            {permissionsListData.includes(26)&&<Route exact path="/Sizes/BoxPrices" element={auth.globals.auth?<BoxSizesPrices permissionsListData={permissionsListData}/>:<Navigate to="/" />} />}
            {/*CATALOGO BODEGAS*/}
            {permissionsListData.includes(24)&&<Route exact path="/Stores" element={auth.globals.auth?<Stores permissionsListData={permissionsListData}/>:<Navigate to="/" />} />}
            {/*ADMINISTRAR RUTAS EEUU*/}
            {permissionsListData.includes(13)&&<Route exact path="/RutasOrigen" element={auth.globals.auth?<RutasPaisOrigen permissionsListData={permissionsListData}/>:<Navigate to="/" />} />}
            {permissionsListData.includes(13)&&<Route exact path="/RutasOrigen/DetalleRuta" element={auth.globals.auth?<DetalleRuta permissionsListData={permissionsListData}/>:<Navigate to="/" />} />}
            {permissionsListData.includes(15)&&<Route exact path="/RutasOrigen/DetalleAsignacion" element={auth.globals.auth?<AsignaDriver permissionsListData={permissionsListData}/>:<Navigate to="/" />} />}
            {permissionsListData.includes(32)&&<Route exact path="/SMS" element={auth.globals.auth?<SmsDeRuta permissionsListData={permissionsListData}/>:<Navigate to="/" />} />}
            {permissionsListData.includes(33)&&<Route exact path="/CajasPorFecha" element={auth.globals.auth?<BoxesByDate permissionsListData={permissionsListData}/>:<Navigate to="/" />} />}
            {/*CUADRE*/}
            {permissionsListData.includes(17)&&<Route exact path="/Cuadre" element={auth.globals.auth?<Cuadre permissionsListData={permissionsListData}/>:<Navigate to="/" />} />}
            {/*CAJAS CON SALDO*/}
            {permissionsListData.includes(42)&&<Route exact path="/CajasConSaldo" element={auth.globals.auth?<CajasConSaldo permissionsListData={permissionsListData}/>:<Navigate to="/" />} />}
            {/*TRACKING*/}
            {<Route exact path="/Tracking" element={<Tracking permissionsListData={permissionsListData}/>} />}
            {/*TRACKING*/}
            {<Route exact path="/Receip" element={<Receipts/>} />}
            {/*AUDIT CAJAS*/}
            {permissionsListData.includes(46)&&<Route exact path="/Audit" element={<Audit permissionsListData={permissionsListData}/>} />}
            <Route path="*" element={<Navigate to="/NotFound" />} />
          </Routes>
        {/*<Footer/>*/}
      </main>
      <Footer/>
    </div>
  )

}

export default AppRouter