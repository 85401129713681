import { Box, Typography, useTheme,Button } from "@mui/material"
import React from 'react'
import FatalErrorImg from '../../../src/assets/500.png'
import { tokens } from "../../theme";
import {useNavigate } from 'react-router-dom';

const FatalError = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  return(
    <Box p={5}>
      <Box display="flex" alignItems="center" flexDirection="column" mt="20px">
        <Typography 
          variant="h2" 
          color={colors.grey[100]}
          fontWeight="bold"
          sx={{ mb: "40px"}}
        >
          Error: 500 Unexpected Error
        </Typography>
        <img src={FatalErrorImg} alt="500 Unexpected Error" className="Error_Image" />
        <Button variant="contained" color="primary" size="large" onClick={()=>{navigate('/')}} sx={{mt:4}}>
          Volver al inicio
        </Button>
      </Box>
    </Box>
  )
}


export default FatalError