import React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';
import { tokens } from '../../theme';
import useAuth from '../../auth/useAuth';

function Footer() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const auth = useAuth();
  
  if(auth.globals.auth)
  return (
    <Box
      sx={{
        position: "fixed",
        bottom: "0px",
        width: "100%",
        //maxHeight:"50px",
        bgcolor: colors.primary[400],
        textAlign: "center",
        p: 0.2,
      }}
    >
      <p><a href="https://www.google.com/" rel="noreferrer" target="_blank">© Transportes Serrano, 2023.</a></p>
    </Box>
  );
}

export default Footer;