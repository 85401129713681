import { Box,Button,useMediaQuery,useTheme, Dialog, DialogTitle, DialogContent, TextField, DialogActions } from "@mui/material";
import { DataGrid, esES, GridToolbar } from "@mui/x-data-grid";
import useAuth from "../../../../auth/useAuth";
import Header from "../../../../components/Header";
import { useFetchGET } from "../../../../state/FetchHelpers";
import { deleteBoxContainerRoute, getContainerDetailsRoute, newContainerBoxRoute } from "../../../../config";
import Loading from "../../../global/Loading";
import FatalError from "../../../global/FatalError";
import DeleteIcon from '@mui/icons-material/Delete';
import { tokens } from "../../../../theme";
import { useEffect, useState } from "react";
import swal from "sweetalert";
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';

const ContainerDetail = ({ id_container }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode)
  const auth = useAuth();
  const token = auth.globals.token
  const wideScreen = useMediaQuery(theme.breakpoints.up('lg')); // Pantallas grandes


  const [ loadingRequest, setLoadingRequest ] = useState(false)
  const [ errorRequest, setErrorRequest ] = useState(null)

  const [openDialog, setOpenDialog] = useState(false);
  const [barcode, setBarcode] = useState('');

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleAddBox = async(id_container,id_box) =>{
    //console.log('id_container',id_container)
    //console.log('id_box',id_box)
    try{
      setLoadingRequest(true)
      let res = await fetch(newContainerBoxRoute(), {
        method: "POST",
        headers: {
          //"Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Accept":"application/json",
          "Authorization": `Bearer ${token}`
          //"x-access-token": token,
        },
        body: JSON.stringify({
          "id_container": id_container,
          "id_box": id_box
        }),
        //cors: 'no-cors'
      })
      let data = await res.json()
      //console.log('resultados nuevo contenedor ',data)
      if(res.ok){
        setBarcode('');
        if(data[1] === 400){
          mostrarErrorRequest("La caja debe estar en - Transito a bodega USA - para ser ingresada al contenedor")
        }        
      }else{
        if(res.status === 401||res.status === 403){
          mostrarErrorRequest("No tiene autorización o su sesión ha expirado")          
        }
        mostrarErrorRequest(data.detail)
        setErrorRequest(data.detail)
        //console.log('Error')     
      }
      setLoadingRequest(false)
      return data;
    }catch(errorRequest){
      //console.log('errorRequest',errorRequest)
      setLoadingRequest(false)
      setErrorRequest(errorRequest)
      //console.log('Error catch') 
    }
  }

  const deleteContainerBox = async(container,box) =>{
    //console.log('bpdy',body)
    try{
      //setLoadingRequest(true)
      let res = await fetch(deleteBoxContainerRoute(container,box), {
        method: "DELETE",
        headers: {
          //"Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Accept":"application/json",
          "Authorization": `Bearer ${token}`
        },
        //cors: 'no-cors'
      })
      let data = await res.json()
      //console.log(res)
      if(res.ok){ 
        setReloadData(true);             
      }else{
        //console.log('res else',res)
        if(res.status === 401||res.status === 403){
          mostrarErrorRequest("No tiene autorización o su sesión ha expirado")          
        }
        mostrarErrorRequest(data.detail)
        setErrorRequest(data.detail)    
      }
      setLoadingRequest(false) 
      return data;
    }catch(errorRequest){
      //console.log('errorRequest',errorRequest)
      setLoadingRequest(false)
      setErrorRequest(errorRequest)
    }
  }


  const [reloadData, setReloadData] = useState(false);
  const handleReloadData = () => {
    setReloadData(true);
  };
  
  useEffect(() => {
    // Llama a la función para recargar datos cuando reloadData cambie a true
    if (reloadData) {
      handleReloadData();
      setReloadData(false);
    }
  }, [reloadData,auth.globals.id,id_container]);
  
  const { data: boxDetailListData, loading: boxDetailListLoading, error: boxDetailListError } = useFetchGET(id_container!=null?getContainerDetailsRoute(id_container):undefined,token,[],reloadData);
  //console.log('listadoCajas',boxDetailListData)

    
  const mostrarErrorRequest=(error)=>{
    if(error!=null){
      try{
        swal({
          title:"Error",
          text: "Resultado: "+error,
          icon: "error",
          button:"Aceptar"
        })
        if(error==="No tiene autorización o su sesión ha expirado"){
          auth.logout();
        }
      }catch(e){
        swal({
          title:"Error",
          text: "Error inesperado ",
          icon: "error",
          button:"Aceptar"
        })
      }
    }
    setErrorRequest(null)
  }
  
  /*const alertSucess=(text_title,text_content='')=>{    
    try{
      swal({
        title:text_title,
        text: text_content,
        icon: "success",
        button:"Aceptar"
      })
    }catch(e){
      swal({
        title:"Error inesperado",
        text: "Error inesperado ",
        icon: "error",
        button:"Aceptar"
      })
    }
    //navigate("/Users/Form?id="+_id)
  }*/

  const limpiarCodigoDeBarras = (codigo) => {
    // Encuentra el índice del primer dígito numérico
    const indicePrimerDigito = codigo.search(/\d/);

    if (indicePrimerDigito === -1) {
        // No se encontraron dígitos, retorna un string vacío o el código original
        return '';
    }

    // Retorna la subcadena desde el primer dígito numérico hasta el final
    return codigo.substring(indicePrimerDigito);
  };
  
  const columns=[
    {
      field:"id_box", 
      headerName: "ID",
      cellClassName: "id-column--cell",
      headerClassName: "id-header--cell",
      hideable:false
    },
    {
      field:"correlative", 
      headerName: "Correlativo",
      flex: 0.25, 
      cellClassName: "correlative-column--cell",
      minWidth:75
    },
    {
      field:"company", 
      headerName: "Empresa",
      flex: 0.5, 
      cellClassName: "company-column--cell",
      minWidth:140
    },
    {
      field:"senders_name", 
      headerName: "Cliente",
      flex: 1.5, 
      cellClassName: "senders_name-column--cell",
      minWidth:120
    },
    {
      field:"senders_phone", 
      headerName: "Telefono",
      flex: 0.5, 
      cellClassName: "senders_phone-column--cell",
      minWidth:85
    },
    {
      field:"name", 
      headerName: "Estado",
      flex: 1, 
      cellClassName: "name-column--cell",
      minWidth:120
    },
    {
      field:"size", 
      headerName: "Tamaño",
      flex: 0.75, 
      cellClassName: "size-column--cell",
      minWidth:100
    },
    {
      field:"saldo_pendiente", 
      headerName: "Saldo ($)",
      flex: 0.75, 
      cellClassName: "saldo_pendiente-column--cell",
      minWidth:100
    },
    {
      minWidth:200,
      filterable:false,
      sortable:false,
      field:"actions", 
      headerName: "Acciones",
      headerAlign:"center",
      cellClassName: "actions-column--cell",
      renderCell:({ row: { id_box} })=>{
        return(
          <Box
            width="98%"
            maxWidth="200px"
            height="75%"
            maxHeight="35px" 
            display="flex" 
            justifyContent="center" 
            m="0 auto">
            <Button
              color="error"
              variant="contained"
              onClick={()=>{deleteContainerBox(id_container,id_box)}}>
            <DeleteIcon/>
              <Box sx={{ml:"5px"}}>
                Eliminar del contenedor
              </Box>
            </Button>
          </Box>
        )
      }
    }
  ]
  
  if(boxDetailListLoading||loadingRequest)
  return (<Loading/>)

  if(boxDetailListError||errorRequest)
  return(<FatalError/>)
  
  return (
    <Box p={0.5} >

      {<Header title="" subtitle="Listado de Cajas"/>}
      <Box display="flex" justifyContent="flex-end">
        <Button 
          variant="contained"
          color='secondary' 
          startIcon={<DocumentScannerIcon />} 
          onClick={handleOpenDialog}
        >
          Scanner
        </Button>
      </Box>
      <Box m="5px 0px 50px 0" /*height={isNonMobile?"100%":undefined}*/ sx={{
        "& .MuiDataGrid-root":{
          border: "none",
        },
        "& .MuiDataGrid-cell":{
          borderBottom: "none"
        },
        "& .MuiDataGrid-columnHeaders":{
          backgroundColor: colors.blueAccent[700],
          borderBottom: "none",
          color: "white"
        },
        "& .MuiDataGrid-virtualScroller":{
          backgroundColor: colors.primary[400],
        },
        "& .MuiDataGrid-footerContainer":{
          borderTop: "none",
          backgroundColor: colors.blueAccent[700],
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text":{
          color: `${colors.grey[100]} !important`,
        },
        "& .MuiToolbar-gutters":{
          color: "white"
        }

      }}>
        <DataGrid
          autoHeight
          initialState={{
            columns: {
              columnVisibilityModel: {
                // Hide columns status and traderName, the other columns will remain visible
                id_box: false,
                actions:true
              },
            },
          }}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          rows={boxDetailListData}
          columns={columns}
          getRowId={(row) => row.id_box}
          density="compact"
          slots={{
            toolbar: GridToolbar,
          }}
        />
      </Box>
      {/* Cuadro de diálogo */}
      <Dialog 
        open={openDialog} 
        onClose={handleCloseDialog}
        //fullScreen={fullScreen} // Diálogo de pantalla completa para pantallas pequeñas
        maxWidth={wideScreen ? 'lg' : 'md'} // Ancho máximo cambia según el tamaño de pantalla
        PaperProps={{
            style: { 
                width: wideScreen ? '30%' : '60%', // Ancho ajustado según el tamaño de pantalla
            },
        }}
      >
        <DialogTitle>Agregar Nueva Caja</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="barcode"
            label="Código de Barras"
            type="text"
            fullWidth
            variant="standard"
            value={barcode}
            onChange={(e) => setBarcode(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleAddBox(id_container.toString(),limpiarCodigoDeBarras(barcode));
                setReloadData(true)
              }
            }}
          />
        </DialogContent>
        <DialogActions>
            <Button onClick={handleCloseDialog} color='secondary'>Terminar</Button>
        </DialogActions>
      </Dialog>      
    </Box>
  );
  
  
}
  
export default ContainerDetail;