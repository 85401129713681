import { useEffect } from "react";
import { createContext,useState } from "react";

export const AuthContext = createContext()

const AuthProvider =({children}) =>{
  /*const [auth,setAuth] = useState(JSON.parse(localStorage.getItem("auth")) || false);*/
  const [globals,setGlobals] = useState(JSON.parse(localStorage.getItem("globals")) || 
    {
        auth: false,
        token: null,
        user: null,
        email:null,
        id: null,
        name_role:null,
        roles: {}
    }
  );

  useEffect(()=> {
    try{
      localStorage.setItem("globals",JSON.stringify(globals));
    }catch(error){
      localStorage.removeItem("globals");
      console.log(error);
    }
  },[globals])
  
  const contextValue = {
    globals,
    login(token,user,email,id,name_role,roles){
      if(token !=null && user!=null && email!=null && id !=null && name_role != null){
        setGlobals(
          {
            auth: true,
            token: token,
            user: user,
            email: email,
            id: id,
            name_role: name_role,
            roles: roles
          }
        );
      }else{
        setGlobals(globals)
      }

    },
    logout(){
        setGlobals(
          {
            auth: false,
            token: null,
            user: null,
            email: null,
            id: null,
            name_role: null,
            roles: {}
          }
        );
    },
    isLogged(){
      return!!globals.auth;
    }
  }
  
  return(
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  )
}


export default AuthProvider