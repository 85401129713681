import { Box, useTheme } from "@mui/material";
import { DataGrid, esES, GridToolbar } from "@mui/x-data-grid";
//import { useNavigate } from "react-router-dom";
import useAuth from "../../../../auth/useAuth";
import Header from "../../../../components/Header";
import { useFetchGET } from "../../../../state/FetchHelpers";
import { companiesListRoute } from "../../../../config";
import { tokens } from "../../../../theme";
import FatalError from "../../../global/FatalError";
import Loading from "../../../global/Loading";

const Companies = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode)
  const auth = useAuth();
  const token = auth.globals.token

  const { data: companiesListData, loading: companiesListLoading, error: companiesListError } = useFetchGET(companiesListRoute(),token,[]);
  //const {data,loading,error} = useFetch2(_user!=null?`${url}/api/pools/user/${_user}`:`${url}/api/pools`,token)

  //const isNonMobile = useMediaQuery("(min-width:600px)")


  const columns=[
    {
      field:"id", 
      headerName: "ID",
      flex: 0.25,
      cellClassName: "id-column--cell",
      headerClassName: "id-header--cell",
      hideable:false
    },
    {
      //hide: true,
      field:"name", 
      headerName: "Nombre",
      flex: 0.75, 
      cellClassName: "name-column--cell",
      minWidth:120
    },
    {
      //hide: true,
      field:"address",  
      headerName: "Dirección",
      flex: 0.75, 
      cellClassName: "address-column--cell",
      minWidth:120
    },
  ]

  if(companiesListLoading)
  return (<Loading/>)

  if(companiesListError)
  return(<FatalError/>)

  return (
    <Box p={1} >
      {/*<Box display="flex" justifyContent="space-between" alignItems="center">*/}
      <Header title="Empresas" subtitle="Listado de empresas"/>
      <Box m="5px 0px 50px 0" /*height={isNonMobile?"100%":undefined}*/ sx={{
        "& .MuiDataGrid-root":{
          border: "none",
        },
        "& .MuiDataGrid-cell":{
          borderBottom: "none"
        },
        "& .MuiDataGrid-columnHeaders":{
          backgroundColor: colors.blueAccent[700],
          borderBottom: "none",
          color: "white"
        },
        "& .MuiDataGrid-virtualScroller":{
          backgroundColor: colors.primary[400],
        },
        "& .MuiDataGrid-footerContainer":{
          borderTop: "none",
          backgroundColor: colors.blueAccent[700],
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text":{
          color: `${colors.grey[100]} !important`,
        },
        "& .MuiToolbar-gutters":{
          color: "white"
        }

      }}>
        <DataGrid
          initialState={{
            columns: {
              columnVisibilityModel: {
                // Hide columns status and traderName, the other columns will remain visible
                id: false
              },
            },
          }}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          rows={companiesListData}
          columns={columns}
          getRowId={(row) => row.id}
          density="compact"
          slots={{
            toolbar: GridToolbar,
          }}
          /*columnVisibilityModel={{
            id: false,
          }}*/
          
        />
      </Box>
    </Box>
  );


}

export default Companies;