import { Box,useMediaQuery,FormControl,InputLabel,MenuItem} from "@mui/material";
import Select from '@mui/material/Select';
//import { useNavigate } from "react-router-dom";
//import useAuth from "../../../../auth/useAuth";
import Header from "../../../../components/Header";
import { useState } from "react";
import RoutesReport from "./RoutesReport";
import PhoneByCountry from "./PhoneByCountry";
import LoadingReport from "./LoadingReport";
import PaymentsTypes from "./PaymentsTypes";
import CashIncome from "./CashIncome";
import UserPickups from "./UserPickups";
import CashBalance from "./CashBalance";
//import { tokens } from "../../../../theme";
//import Loading from "../global/Loading";
//import FatalError from "../global/FatalError";

const Reportes = ({permissionsListData}) => {
  //const theme = useTheme();
  //const colors = tokens(theme.palette.mode)
  //const auth = useAuth();
  //const token = auth.globals.token
  //const {data,loading,error} = useFetch2(`${url}/api/clients`,token)
  const isNonMobile = useMediaQuery("(min-width:600px)")
  //const navigate = useNavigate();

  const reportOptions = [
    (permissionsListData.includes(34)||permissionsListData.includes(40)) && { id: 1, name: 'Rutas' },
    //{ id: 2, name: 'Teléfonos por país' },
    permissionsListData.includes(35) && { id: 3, name: 'Carga' },

    permissionsListData.includes(36) && { id: 5, name: 'Tipo de pago' },
    permissionsListData.includes(37) && { id: 6, name: 'Pickups de usuarios' },
    permissionsListData.includes(38) && { id: 7, name: 'Ingresos de efectivo' },
    permissionsListData.includes(39) && { id: 8, name: 'Cuadre' }
  ].filter(Boolean);

  const [report, setReport] = useState(null);

  return (
    <Box m="20px" marginBottom="64px">
      <Header title="Reportes" subtitle="Reportes"/>
      <Box
        display="grid" 
        gap="15px"
        marginBottom="15px" 
        gridTemplateColumns="repeat(12, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile? undefined : "span 12"}
        }}
      >
        <FormControl variant="filled"
          disabled={false}
          sx={{ gridColumn: "span 6"}}
          required>
          <InputLabel id="select-report-label" name="select-report-label">Reportes</InputLabel>
          <Select
            labelId="select-report-label"
            id="select-report"
            fullWidth={!isNonMobile}
            //onBlur = {handleBlur}
            onChange = {(e) => {
              setReport(e.target.value);
            }}
            value = {report||''}
            name = "report"
            size="small"
          >
            {reportOptions.map((reportSelection) => (
              <MenuItem key={reportSelection.id} value={reportSelection.id}>
                {reportSelection.name}
              </MenuItem>
            ))}
          </Select>  
        </FormControl>
      </Box>
      {report && report===1 && <RoutesReport permissionsListData={permissionsListData}/>}
      {report && report===2 && <PhoneByCountry/>}
      {report && report===3 && <LoadingReport/>}
      
      {report && report===5 && <PaymentsTypes/>}
      {report && report===6 && <UserPickups/>}
      {report && report===7 && <CashIncome/>}
      {report && report===8 && <CashBalance/>}
    </Box>
  );
}

export default Reportes;