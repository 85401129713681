import { Box,Button,useMediaQuery,useTheme,TextField } from "@mui/material";
import { DataGrid, esES, GridToolbar } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../../auth/useAuth";
import Header from "../../../../components/Header";
import { useFetchGET } from "../../../../state/FetchHelpers";
import { newStoreRoute, storeChangeStatusRoute, storesListRoute } from "../../../../config";
import Loading from "../../../global/Loading";
import FatalError from "../../../global/FatalError";
import AddIcon from '@mui/icons-material/Add';
import { tokens } from "../../../../theme";
import { useEffect, useState } from "react";
import swal from "sweetalert";
import * as yup from "yup";
import { Formik } from "formik"
import ToggleOnOutlinedIcon from '@mui/icons-material/ToggleOnOutlined';
import ToggleOffOutlinedIcon from '@mui/icons-material/ToggleOffOutlined';

const Stores = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode)
  const auth = useAuth();
  const token = auth.globals.token

  const isNonMobile = useMediaQuery("(min-width:600px)")


  const [ loadingRequest, setLoadingRequest ] = useState(false)
  const [ errorRequest, setErrorRequest ] = useState(null)
  const statusOptions = [
    { id: 1, name: 'Activo' },
    { id: 2, name: 'Inactivo' },
  ];

  const cambiarStatus = (data) => {
    const newData = data.map((item) => {
      const statusOption = statusOptions.find((option) => option.id === item.status);
      //const statusOption = statusOptions.find((option) => option.id === item.estado);
      if (statusOption) {
        return { ...item, status: statusOption.name };
      }
      return item;
    });
    return newData;
  };


  const [reloadData, setReloadData] = useState(false);
  const handleReloadData = () => {
    setReloadData(true);
  };



  useEffect(() => {
    // Llama a la función para recargar datos cuando reloadData cambie a true
    if (reloadData) {
      handleReloadData();
      setReloadData(false);
      setInicitalValues({
        name: "",
        address: "",
        status: "1",
        create_user: auth.globals.id.toString()
      })
    }
  }, [reloadData,auth.globals.id]);

  const { data: storesListData, loading: storesListLoading, error: storesListError } = useFetchGET(storesListRoute(),token,[],reloadData);
  //const {data,loading,error} = useFetch2(_user!=null?`${url}/api/pools/user/${_user}`:`${url}/api/pools`,token)

  //const isNonMobile = useMediaQuery("(min-width:600px)")
  const navigate = useNavigate();

  const changeStatus = async(newStatus,store_id) =>{
    //console.log('bpdy',body)
    try{
      //setLoadingRequest(true)
      let res = await fetch(storeChangeStatusRoute(store_id), {
        method: "PUT",
        headers: {
          //"Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Accept":"application/json",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(
          {
          "status": newStatus.toString()
          }),
        //cors: 'no-cors'
      })
      let data = await res.json()
      if(res.ok){ 
        setReloadData(true);             
      }else{
        //console.log('res else',res)
        if(res.status === 401||res.status === 403){
          mostrarErrorRequest("No tiene autorización o su sesión ha expirado")          
        }
        mostrarErrorRequest(data.detail)
        setErrorRequest(data.detail)    
      }
      setLoadingRequest(false) 
      return data;
    }catch(errorRequest){
      //console.log('errorRequest',errorRequest)
      setLoadingRequest(false)
      setErrorRequest(errorRequest)
    }
  }

  
  const mostrarErrorRequest=(error)=>{
    if(error!=null){
      try{
        swal({
          title:"Error",
          text: "Resultado: "+error,
          icon: "error",
          button:"Aceptar"
        })
        if(error==="No tiene autorización o su sesión ha expirado"){
          auth.logout();
        }
      }catch(e){
        swal({
          title:"Error",
          text: "Error inesperado ",
          icon: "error",
          button:"Aceptar"
        })
      }
    }
    setErrorRequest(null)
  }

  const alertSucess=(text_title,text_content='')=>{    
    try{
      swal({
        title:text_title,
        text: text_content,
        icon: "success",
        button:"Aceptar"
      })
    }catch(e){
      swal({
        title:"Error inesperado",
        text: "Error inesperado ",
        icon: "error",
        button:"Aceptar"
      })
    }
    navigate("/Stores")
  }

  const newStore = async(body) =>{
    //console.log('bpdy',body)
    try{
      
      let res = await fetch(newStoreRoute(), {
        method: "POST",
        headers: {
          //"Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Accept":"application/json",
          "Authorization": `Bearer ${token}`
          //"x-access-token": token,
        },
        body: JSON.stringify(body),
        //cors: 'no-cors'
      })
      let data = await res.json()
      if(res.ok){
        setLoadingRequest(false)
        alertSucess('Bodega Ingresada',data.message)
        navigate("/Stores")
        setReloadData(true);       
      }else{
        //console.log('res else',res)
        if(res.status === 401||res.status === 403){
          mostrarErrorRequest("No tiene autorización o su sesión ha expirado")          
        }
        mostrarErrorRequest(data.detail)
        setErrorRequest(data.detail)    
      }
      setLoadingRequest(false) 
      return data;
    }catch(errorRequest){
      //console.log('errorRequest',errorRequest)
      setLoadingRequest(false)
      setErrorRequest(errorRequest)
    }
  }

  const [initialValues,setInicitalValues] = useState({
    name: "",
    address: "",
    status: "1",
    create_user: auth.globals.id.toString()
  })

  const userSchema = yup.object().shape({
    address: yup.string().required("* Valor Requerido"),
    name: yup.string().required("* Valor Requerido"),
  })


  const handleFormSubmit = (values) => {
    //console.log('values',values)
    setInicitalValues(values)
    setLoadingRequest(true)
    newStore(values)
    //console.log('Nueva bodega')
  }

  const columns=[
    {
      field:"id", 
      headerName: "ID",
      flex: 0.25,
      cellClassName: "id-column--cell",
      headerClassName: "id-header--cell",
      hideable:false
    },
    {
      //hide: true,
      field:"name",
      headerName: "Nombre",
      flex: 0.75, 
      cellClassName: "name-column--cell",
      minWidth:120
    },
    {
      //hide: true,
      field:"address",
      headerName: "Dirección",
      flex: 0.75, 
      cellClassName: "address-column--cell",
      minWidth:120
    },
    {
      minWidth:100,
      filterable:false,
      sortable:false,
      field:"status", 
      headerName: "Estado",
      headerAlign:"center",
      flex: 0.5, 
      cellClassName: "status-column--cell",
      renderCell:({ row: { id,status} })=>{
      //renderCell:({ row: { id,estado} })=>{
        return(
          <Box
            width="80%"
            maxWidth="175px"
            height="75%"
            maxHeight="35px" 
            display="flex" 
            justifyContent="center" 
            m="0 auto">
            {status==='Activo'?<Button
              //estado==='Activo'?<Button
              color="success"
              variant="contained"
              onClick={()=>{changeStatus(2,id)}}>
            <ToggleOffOutlinedIcon/>
              <Box sx={{ml:"5px"}}>
                Activo
              </Box>
            </Button>:<Button
              color="warning"
              variant="contained"
              onClick={()=>{changeStatus(1,id)}}>
            <ToggleOnOutlinedIcon/>
              <Box sx={{ml:"5px"}}>
                Inactivo
              </Box>
            </Button>}
          </Box>
        )
      }
    }
  ]

  if(loadingRequest)
  return (<Loading/>)

  if(storesListLoading)
  return (<Loading/>)

  if(errorRequest)
  return(<FatalError/>)

  if(storesListError)
  return(<FatalError/>)

  return (
    <Box p={1} >
      {/*<Box display="flex" justifyContent="space-between" alignItems="center">*/}
      {<Header title="Bodegas" subtitle="Listado de bodegas"/>}
      <Formik
        enableReinitialize={true}
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema = {userSchema}
      >
      {({
        values, 
        errors, 
        touched, 
        handleBlur, 
        handleChange,
        handleSubmit,
        setFieldValue
      }) => (
        <form onSubmit={handleSubmit} >
          <Box
            display="grid" 
            gap="15px"
            marginBottom="15px" 
            gridTemplateColumns="repeat(12, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile? undefined : "span 12"}
            }}
          >        
            <TextField
                  fullWidth={!isNonMobile}
                  size="small"
                  variant="filled"
                  type="text"
                  label="Nombre"
                  onBlur = {handleBlur}
                  onChange = {handleChange}
                  value = { values.name}
                  name = "name"
                  error = {!!touched.name && !!errors.name}
                  helperText={touched.name && errors.name}
                  sx={{ gridColumn: "span 4"}}
            />
            <TextField
                  fullWidth={!isNonMobile}
                  size="small"
                  variant="filled"
                  type="text"
                  label="Direccion"
                  onBlur = {handleBlur}
                  onChange = {handleChange}
                  value = { values.address}
                  name = "address"
                  error = {!!touched.address && !!errors.address}
                  helperText={touched.address && errors.address}
                  sx={{ gridColumn: "span 8"}}
            />
          </Box>
              <Box display="flex" justifyContent="end" mt="20px">               
                <Button type="submit" color="secondary" variant="contained">
                  <AddIcon/>
                    Agregar nueva Bodega
                </Button>                           
              </Box>
        </form>
        )}
      </Formik>
          {/*<Box
              width="100px"
              p= "5px"
              display="flex"
              justifyContent="center"
              backgroundColor= {colors.greenAccent[600]}
              borderRadius = "4px"
              onClick={()=>{navigate("/Clientes/Form")}}
              >
              <PersonAddIcon/>
              <Typography color={colors.grey[100]} sx={{ml:"5px"}}>
                Nuevo
              </Typography>
          </Box>*/}
      <Box m="5px 0px 50px 0" /*height={isNonMobile?"100%":undefined}*/ sx={{
        "& .MuiDataGrid-root":{
          border: "none",
        },
        "& .MuiDataGrid-cell":{
          borderBottom: "none"
        },
        "& .MuiDataGrid-columnHeaders":{
          backgroundColor: colors.blueAccent[700],
          borderBottom: "none",
          color: "white"
        },
        "& .MuiDataGrid-virtualScroller":{
          backgroundColor: colors.primary[400],
        },
        "& .MuiDataGrid-footerContainer":{
          borderTop: "none",
          backgroundColor: colors.blueAccent[700],
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text":{
          color: `${colors.grey[100]} !important`,
        },
        "& .MuiToolbar-gutters":{
          color: "white"
        }

      }}>
        <DataGrid
          autoHeight
          initialState={{
            columns: {
              columnVisibilityModel: {
                // Hide columns status and traderName, the other columns will remain visible
                id: false,
              },
            },
          }}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          rows={cambiarStatus(storesListData)}
          columns={columns}
          getRowId={(row) => row.id}
          density="compact"
          slots={{
            toolbar: GridToolbar,
          }}
          /*columnVisibilityModel={{
            id: false,
          }}*/
          
        />
      </Box>
    </Box>
  );


}

export default Stores;