import { Box,/*,useTheme*/Button,useMediaQuery,FormControl,InputLabel,MenuItem} from "@mui/material";
import Select from '@mui/material/Select';
//import { useNavigate } from "react-router-dom";
import useAuth from "../../../../auth/useAuth";
import { useState } from "react";
import { useFormik } from "formik";
//import { tokens } from "../../../../theme";
import { reseiverCountriesRoute } from "../../../../config";
import { useFetchGET } from "../../../../state/FetchHelpers";
import Loading from "../../../global/Loading";
import FatalError from "../../../global/FatalError";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

const PhoneByCountry = () => {
  //const theme = useTheme();
  //const colors = tokens(theme.palette.mode)
  const auth = useAuth();
  const token = auth.globals.token
  const isNonMobile = useMediaQuery("(min-width:600px)")
  //const navigate = useNavigate();

  const { data: countriesListData, loading: countriesListLoading, error: countriesListError } = useFetchGET(reseiverCountriesRoute(),token,[]);
  
  const [actionType, setActionType] = useState("");

  const phoneByCountryFormik = useFormik({
    initialValues: {
        country: '',
    },
    onSubmit: (values) => {
      console.log(values)
      switch(actionType) {
        case "PDF":
          // Acción para 'Listado'
          console.log('PDF')
          break;
        case "CSV":
          // Acción para 'Busqueda'
          console.log('CSV')
          break;
        default:
          console.log('Ninguna opcion seleccionada')
      }
    },
  });

  if (countriesListLoading)
  return (<Loading/>)
  if (countriesListError)
  return (<FatalError/>)

  return (
    <Box>
      <form onSubmit={phoneByCountryFormik.handleSubmit}>
        <Box
          display="grid" 
          gap="15px"
          marginBottom="15px" 
          gridTemplateColumns="repeat(12, minmax(0, 1fr))"
          sx={{
            "& > div": { gridColumn: isNonMobile? undefined : "span 12"}
          }}
        >
          <FormControl variant="filled"
            disabled={false}
            sx={{ gridColumn: "span 6"}}
            required>
            <InputLabel id="select-country-label" name="select-country-label">País</InputLabel>
            <Select
              labelId="select-country-label"
              id="select-country"
              fullWidth={!isNonMobile}
              //onBlur = {rutasFormik.handleBlur}
              value = {phoneByCountryFormik.values.country||''}
              onChange={phoneByCountryFormik.handleChange}
              name = "country"
              size="small"
            >
              {countriesListData.map((selectedCountry) => (
                <MenuItem key={selectedCountry.id} value={selectedCountry.id.toString()}>
                  {selectedCountry.name}
                </MenuItem>
              ))}
            </Select>  
          </FormControl>
        </Box>
        <Box
          display="flex"
          flexDirection={isNonMobile ? 'row' : 'column'}
          justifyContent="flex-start"
          mt="10px"
          gap={1} // Añade un espacio entre los botones
        >
          <Button type="submit" variant="contained" color="secondary" onClick={() => setActionType("PDF")} >
            <PictureAsPdfIcon/> Reporte PDF
          </Button>
          <Button type="submit" variant="contained" color="secondary" onClick={() => setActionType("CSV")} >
            <InsertDriveFileIcon/> Reporte CSV
          </Button>
        </Box>  
      </form>
    </Box>
  );
}

export default PhoneByCountry;