import { Box,Button,Table,TableBody,TableCell,TableContainer,TableHead,TableRow,TextField,Typography, useMediaQuery} from "@mui/material";
import Header from "../../../../components/Header";
import Loading from "../../../global/Loading";
import FatalError from "../../../global/FatalError";
import useAuth from "../../../../auth/useAuth";
import { useState } from "react";
import { getBoxesByDateRoute } from "../../../../config";
import swal from "sweetalert";
import SearchIcon from '@mui/icons-material/Search';
import { useFormik } from "formik";

const BoxesByDate = ({permissionsListData}) => {
  const [ loadingRequest, setLoadingRequest ] = useState(false);
  const [ errorRequest, setErrorRequest ] = useState(null);
  const auth = useAuth();
  const token = auth.globals.token
  const isNonMobile = useMediaQuery("(min-width:600px)")

  const [boxesListData, setBoxesListData] = useState([])

  const sortedBoxesListData = boxesListData.sort((a, b) => {
    if(a.status_name < b.status_name) { return -1; }
    if(a.status_name > b.status_name) { return 1; }
    return 0;
  });

  const dateFormik = useFormik({
    initialValues: {
        formDate: '',
    },
    onSubmit: (values) => {
      getBoxesByDate(values.formDate)
    },
  });

  const getBoxesByDate = async(date) =>{
    if(date && date !=='')
    try{    
      setLoadingRequest(true)
      let res = await fetch(getBoxesByDateRoute(date), {
        method: "GET",
        headers: {
          //"Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Accept":"application/json",
          "Authorization": `Bearer ${token}`
          //"x-access-token": token,
        },
        //cors: 'no-cors'
      })
      let data = await res.json()
      console.log('resultado de cajas',data)
      if(res.ok){
        setBoxesListData(data)
        if(data.length === 0){
          mostrarAlertRequest('No existen registros para la fecha seleccionada')
        }         
      }else{
        if(res.status === 401||res.status === 403){
          mostrarErrorRequest("No tiene autorización o su sesión ha expirado")          
        }
        mostrarErrorRequest(data.detail)
        setErrorRequest(data.detail)
        console.log('gasto error')     
      }
      setLoadingRequest(false)
      return data;
    }catch(errorRequest){
      //console.log('errorRequest',errorRequest)
      setLoadingRequest(false)
      setErrorRequest(errorRequest)
      //console.log('gasto catch') 
    }
  }

  const mostrarErrorRequest=(error)=>{
    if(error!=null){
      try{
        swal({
          title:"Error",
          text: "Resultado: "+error,
          icon: "error",
          button:"Aceptar"
        })
        if(error==="No tiene autorización o su sesión ha expirado"){
          auth.logout();
        }
      }catch(e){
        swal({
          title:"Error",
          text: "Error inesperado ",
          icon: "error",
          button:"Aceptar"
        })
      }
    }
    setErrorRequest(null)
  }

  const mostrarAlertRequest=(info)=>{
    if(info!=null){
      try{
        swal({
          title:"Alerta",
          text: info,
          icon: "warning",
          button:"Aceptar"
        })
        
      }catch(e){
        swal({
          title:"Error",
          text: "Error inesperado ",
          icon: "error",
          button:"Aceptar"
        })
      }
    }
  }

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = `${today.getMonth() + 1}`.padStart(2, '0');
    const day = `${today.getDate()}`.padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  /*const alertSucess=(text_title,text_content='')=>{    
    try{
      swal({
        title:text_title,
        text: text_content,
        icon: "success",
        button:"Aceptar"
      })
    }catch(e){
      swal({
        title:"Error inesperado",
        text: "Error inesperado ",
        icon: "error",
        button:"Aceptar"
      })
    }
  }*/

  if (loadingRequest)
  return (<Loading/>)
  if (errorRequest)
  return (<FatalError/>)

  return (
    <Box m="20px" marginBottom="64px">
      <Header title="Búsqueda por fecha" subtitle="Ver próximos Deliveries / Pickups"/>
      <Box>
        <form onSubmit={dateFormik.handleSubmit}>
        <Box
          display="grid" 
          gap="15px"
          marginBottom="15px" 
          gridTemplateColumns="repeat(12, minmax(0, 1fr))"
          sx={{
            "& > div": { gridColumn: isNonMobile? undefined : "span 12"}
          }}
        >
          <TextField
            label="Fecha"
            name="formDate"
            type="date"
            value={dateFormik.values.formDate}
            onChange={dateFormik.handleChange}
            fullWidth
            variant="filled"
            InputLabelProps={{ shrink: true }}
            size="small"
            sx={{ gridColumn: "span 6"}}
            inputProps={{
              min: getCurrentDate() // Establece la fecha mínima como el día actual
            }}
            
          />
        </Box>
        <Box
          display="flex"
          flexDirection={isNonMobile ? 'row' : 'column'}
          justifyContent="flex-start"
          mt="10px"
          gap={1} // Añade un espacio entre los botones
        >
          <Button type="submit" variant="contained" color="secondary" >
            <SearchIcon/> Buscar Cajas
          </Button>
        </Box>           
        </form>
      </Box>
      {sortedBoxesListData  && sortedBoxesListData.length > 0?(
        <Box sx={{ marginTop: 2 }}>
          <Typography variant="h3" sx={{ marginBottom: 2 }}> Total de cajas encontradas: {boxesListData.length}</Typography>
          <Typography variant="h6" sx={{ marginBottom: 2 }}> * Si Driver Asignado se encuentra vacío significa que esa ruta aún no se encuentra asignada para este día.</Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell component="th" style={{ fontWeight: 'bold' }}>ID</TableCell>
                  <TableCell component="th" style={{ fontWeight: 'bold' }}>Empresa</TableCell>
                  <TableCell component="th" style={{ fontWeight: 'bold' }}>Cliente</TableCell>
                  <TableCell component="th" style={{ fontWeight: 'bold' }}>Teléfono</TableCell>
                  <TableCell component="th" style={{ fontWeight: 'bold' }}>Dirección</TableCell>
                  <TableCell component="th" style={{ fontWeight: 'bold' }}>Estado</TableCell>
                  <TableCell component="th" style={{ fontWeight: 'bold' }}>Ruta</TableCell>
                  <TableCell component="th" style={{ fontWeight: 'bold' }}>Driver Asignado</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedBoxesListData.map((box) => (
                  <TableRow key={box.id} >
                    <TableCell style={{ color: box.driver === null ? 'red' : 'inherit' }}>{box.correlative}</TableCell>
                    <TableCell style={{ color: box.driver === null ? 'red' : 'inherit' }}>{box.company}</TableCell>
                    <TableCell style={{ color: box.driver === null ? 'red' : 'inherit' }}>{box.senders_name}</TableCell>
                    <TableCell style={{ color: box.driver === null ? 'red' : 'inherit' }}>{box.senders_phone}</TableCell>
                    <TableCell style={{ color: box.driver === null ? 'red' : 'inherit' }}>{box.senders_address}</TableCell>
                    <TableCell style={{ color: box.driver === null ? 'red' : 'inherit' }}>{box.status_name}</TableCell>
                    <TableCell style={{ color: box.driver === null ? 'red' : 'inherit' }}>{box.route_name}</TableCell>
                    <TableCell style={{ color: box.driver === null ? 'red' : 'inherit' }}>{box.driver}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ):undefined}
    </Box>
  );
}

export default BoxesByDate;