import { Box,Button,useMediaQuery,useTheme,TextField} from "@mui/material";
import { DataGrid, esES, GridToolbar } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import useAuth from "../../../../auth/useAuth";
import Header from "../../../../components/Header";
import { useFetchGET } from "../../../../state/FetchHelpers";
import { deleteZipRouteRoute, newOriginCountryRouteZIP, originCountryRouteDetail} from "../../../../config";
import Loading from "../../../global/Loading";
import FatalError from "../../../global/FatalError";
import DeleteIcon from '@mui/icons-material/Delete';
import { tokens } from "../../../../theme";
import { useEffect, useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import swal from "sweetalert";
import * as yup from "yup";
import { Formik } from "formik"

const DetalleRuta = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode)
  const auth = useAuth();
  const token = auth.globals.token
  const isNonMobile = useMediaQuery("(min-width:600px)")

  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const _id = query.get('id')
  const _name = query.get('name')

  
  const [ loadingRequest, setLoadingRequest ] = useState(false)
  const [ errorRequest, setErrorRequest ] = useState(null)


  const [reloadData, setReloadData] = useState(false);

  const handleReloadData = () => {
    setReloadData(true);
  };

  useEffect(() => {
    // Llama a la función para recargar datos cuando reloadData cambie a true
    if (reloadData) {
      handleReloadData();
      setReloadData(false);
      setInicitalValues({
        route_id: _id,
        zip: "",
        state: "",
        city: "",
        create_user: auth.globals.id.toString()
      })
    }
  }, [reloadData,auth.globals.id,_id]);

  const { data: routeDetailListData, loading: routeDetailListLoading, error: routeDetailListError } = useFetchGET(_id!=null?originCountryRouteDetail(_id):undefined,token,[],reloadData);
  //console.log(routeDetailListData)

  const navigate = useNavigate();

  const deleteZipRoute = async(id_route,zip) =>{
    //console.log('bpdy',body)
    try{
      //setLoadingRequest(true)
      let res = await fetch(deleteZipRouteRoute(id_route,zip), {
        method: "DELETE",
        headers: {
          //"Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Accept":"application/json",
          "Authorization": `Bearer ${token}`
        },
        //cors: 'no-cors'
      })
      let data = await res.json()
      //console.log(res)
      if(res.ok){ 
        setReloadData(true);             
      }else{
        //console.log('res else',res)
        if(res.status === 401||res.status === 403){
          mostrarErrorRequest("No tiene autorización o su sesión ha expirado")          
        }
        mostrarErrorRequest(data.detail)
        setErrorRequest(data.detail)    
      }
      setLoadingRequest(false) 
      return data;
    }catch(errorRequest){
      //console.log('errorRequest',errorRequest)
      setLoadingRequest(false)
      setErrorRequest(errorRequest)
    }
  }

  
  const mostrarErrorRequest=(error)=>{
    if(error!=null){
      try{
        swal({
          title:"Error",
          text: "Resultado: "+error,
          icon: "error",
          button:"Aceptar"
        })
        if(error==="No tiene autorización o su sesión ha expirado"){
          auth.logout();
        }
      }catch(e){
        swal({
          title:"Error",
          text: "Error inesperado ",
          icon: "error",
          button:"Aceptar"
        })
      }
    }
    setErrorRequest(null)
  }

  const alertSucess=(text_title,text_content='')=>{    
    try{
      swal({
        title:text_title,
        text: text_content,
        icon: "success",
        button:"Aceptar"
      })
    }catch(e){
      swal({
        title:"Error inesperado",
        text: "Error inesperado ",
        icon: "error",
        button:"Aceptar"
      })
    }
    navigate("/Roles/Acciones?id="+_id+"&name="+_name)
  }

  const newZIP= async(body) =>{
    //console.log('bpdy',body)
    try{
      
      let res = await fetch(newOriginCountryRouteZIP(), {
        method: "POST",
        headers: {
          //"Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Accept":"application/json",
          "Authorization": `Bearer ${token}`
          //"x-access-token": token,
        },
        body: JSON.stringify(body),
        //cors: 'no-cors'
      })
      let data = await res.json()
      //console.log(res)
      if(res.ok){
        setLoadingRequest(false)
        alertSucess('Codigo postal agregado',data.message)
        navigate("/RutasOrigen/DetalleRuta?id="+_id+"&name="+_name)
        setReloadData(true);       
      }else{
        //console.log('res else',res)
        if(res.status === 401||res.status === 403){
          mostrarErrorRequest("No tiene autorización o su sesión ha expirado")          
        }else if (res.status === 500){
          mostrarErrorRequest(data.detail)
        }else{
          mostrarErrorRequest(data.detail)
          setErrorRequest(data.detail)          
        }
      }
      setLoadingRequest(false) 
      return data;
    }catch(errorRequest){
      //console.log('errorRequest',errorRequest)
      setLoadingRequest(false)
      setErrorRequest(errorRequest)
    }
  }

  const [initialValues,setInicitalValues] = useState({
    route_id: _id,
    zip: "",
    state: "",
    city: "",
    create_user: auth.globals.id.toString()
  })

  const userSchema = yup.object().shape({
    zip: yup.string().required("* Valor Requerido"),
    state: yup.string().required("* Valor Requerido"),
    city: yup.string().required("* Valor Requerido"),
  })


  const handleFormSubmit = (values) => {
    //console.log('values',values)
    setInicitalValues(values)
    setLoadingRequest(true)
    newZIP(values)
    //console.log('Nueva bodega')
  }

  const getDataByZIP = async (values) => {
    const newZip = values.zip;
    
    if (newZip.length === 5) { // Suponiendo que el código postal tiene 5 dígitos
      const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${newZip}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
      
      try {
        const response = await fetch(url);
        const data = await response.json();
        if (data.results.length > 0) {
          // Filtrar por país (Estados Unidos)
          const filteredResults = data.results.filter(result => 
            result.address_components.some(component =>
              component.types.includes('country') && component.short_name === 'US'
            )
          );
  
          if (filteredResults.length > 0) {
            const firstResult = filteredResults[0];
            let cities = [];
            let stateName = '';
  
            // Verificar si postcode_localities está disponible
            if (firstResult.postcode_localities) {
              cities = firstResult.postcode_localities;
            } else {
              // En caso de que postcode_localities no esté disponible
              const cityComponent = firstResult.address_components.find(component => component.types.includes('locality'));
              if (cityComponent) {
                cities.push(cityComponent.long_name);
              }
            }
  
            const stateComponent = firstResult.address_components.find(component => component.types.includes('administrative_area_level_1'));
            if (stateComponent) {
              stateName = stateComponent.long_name;
            }
  
            setInicitalValues(currentValues => ({
              ...currentValues,
              city: cities.join(', '),
              state: stateName
            }));
          }
        }
      } catch (error) {
        console.error('Error fetching location data', error);
      }
    }
  };


  const handleChanValues = (values,target) =>{
    values[target.name]=target.value.toString();
    if(target.name==="zip"&&target.value.toString().length===5){
      getDataByZIP(values)
    }
    if(target.name==="zip"&&target.value.toString().length!==5){
      values['city'] = ''
      values['state'] = ''
    }
  }



  const columns=[
    {
      field:"zip", 
      headerName: "ZIP CODE",
      flex: 1, 
      cellClassName: "zip-column--cell",
      headerClassName: "zip-header--cell",
      hideable:false
    },
    {
      //hide: true,
      field:"state", 
      headerName: "Estado",
      flex: 1, 
      cellClassName: "state-column--cell",
      minWidth:120
    },
    {
      //hide: true,
      field:"city", 
      headerName: "Ciudad/s",
      flex: 3, 
      cellClassName: "city-column--cell",
      minWidth:120
    },
    {
      flex: 1, 
      minWidth:200,
      filterable:false,
      sortable:false,
      field:"actions", 
      headerName: "Acciones",
      headerAlign:"center",
      cellClassName: "actions-column--cell",
      renderCell:({ row: { zip} })=>{
        return(
          <Box
            width="80%"
            maxWidth="175px"
            height="75%"
            maxHeight="35px" 
            display="flex" 
            justifyContent="center" 
            m="0 auto">
            <Button
              color="error"
              variant="contained"
              onClick={()=>{deleteZipRoute(_id,zip)}}>
            <DeleteIcon/>
              <Box sx={{ml:"5px"}}>
                Eliminar
              </Box>
            </Button>
          </Box>
        )
      }
    }
  ]

  if(routeDetailListLoading||loadingRequest)
  return (<Loading/>)

  if(routeDetailListError||errorRequest)
  return(<FatalError/>)

  return (
    <Box p={1} >
      {/*<Box display="flex" justifyContent="space-between" alignItems="center">*/}
      {_id!=null?<Header title={'Detalle de ruta: '+_name} subtitle="Listado de codigos postales de la ruta"/>:undefined}
      <Formik
        enableReinitialize={true}
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema = {userSchema}
      >
      {({
        values, 
        errors, 
        touched, 
        handleBlur, 
        handleChange,
        handleSubmit,
        setFieldValue
      }) => (
        <form onSubmit={handleSubmit} >
          <Box
            display="grid" 
            gap="15px"
            marginBottom="15px" 
            gridTemplateColumns="repeat(12, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile? undefined : "span 12"}
            }}
          >        
            <TextField
              fullWidth={!isNonMobile}
              size="small"
              variant="filled"
              type="text"
              label="ZIP Code"
              onBlur = {handleBlur}
              onChange = {(e) => {
                handleChanValues(values,e.target);
                setFieldValue("zip", e.target.value);
              }}
              value = { values.zip}
              name = "zip"
              error = {!!touched.zip && !!errors.zip}
              helperText={touched.zip && errors.zip}
              sx={{ gridColumn: "span 2"}}
              inputProps={{
                maxLength: 5
              }}
            />
            <TextField
              disabled={false}
              fullWidth={!isNonMobile}
              size="small"
              variant="filled"
              type="text"
              label="State"
              onBlur = {handleBlur}
              onChange = {handleChange}
              value = { values.state}
              name = "state"
              error = {!!touched.state && !!errors.state}
              helperText={touched.state && errors.state}
              sx={{ gridColumn: "span 2"}}
            />
            <TextField
              disabled={false}
              fullWidth={!isNonMobile}
              size="small"
              variant="filled"
              type="text"
              label="City"
              onBlur = {handleBlur}
              onChange = {handleChange}
              value = { values.city}
              name = "city"
              error = {!!touched.city && !!errors.city}
              helperText={touched.city && errors.city}
              sx={{ gridColumn: "span 8"}}
            />
          </Box>
              <Box display="flex" justifyContent="end" mt="20px">               
                <Button type="submit" color="secondary" variant="contained">
                  <AddIcon/>
                    Agregar ZIP
                </Button>                           
              </Box>
        </form>
        )}
      </Formik>
      <Box m="5px 0px 50px 0" /*height={isNonMobile?"100%":undefined}*/ sx={{
        "& .MuiDataGrid-root":{
          border: "none",
        },
        "& .MuiDataGrid-cell":{
          borderBottom: "none"
        },
        "& .MuiDataGrid-columnHeaders":{
          backgroundColor: colors.blueAccent[700],
          borderBottom: "none",
          color: "white"
        },
        "& .MuiDataGrid-virtualScroller":{
          backgroundColor: colors.primary[400],
        },
        "& .MuiDataGrid-footerContainer":{
          borderTop: "none",
          backgroundColor: colors.blueAccent[700],
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text":{
          color: `${colors.grey[100]} !important`,
        },
        "& .MuiToolbar-gutters":{
          color: "white"
        }

      }}>
        <DataGrid
          autoHeight
          initialState={{
            columns: {
              columnVisibilityModel: {
                // Hide columns status and traderName, the other columns will remain visible
                actions:true
              },
            },
          }}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          rows={routeDetailListData}
          columns={columns}
          getRowId={(row) => row.zip}
          density="compact"
          slots={{
            toolbar: GridToolbar,
          }}
        />
      </Box>
    </Box>
  );


}

export default DetalleRuta;