import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Select, MenuItem } from '@mui/material';
import { useFetchGET } from '../../../../state/FetchHelpers';
import { getContainersByDateRoute } from '../../../../config';
import Loading from '../../../global/Loading';
import FatalError from '../../../global/FatalError';

const ContainersDialogSelector = ({ openDialog, setOpenDialog, initialDate, finalDate, token, reportFormik,reloadDataValue}) => {
  
  const [reloadData, setReloadData] = useState(reloadDataValue);
  const handleReloadData = () => {
    setReloadData(true);
  };

  useEffect(() => {
    // Llama a la función para recargar datos cuando reloadData cambie a true
    if (reloadData) {
      handleReloadData();
      setReloadData(false);
    }
  }, [reloadData,reportFormik.values.id_container]);

  const { data: containerListData, loading: containerListLoading, error: containerListError } = useFetchGET(getContainersByDateRoute(initialDate,finalDate),token,[],reloadData);
  const setFieldValue = reportFormik.setFieldValue;

  useEffect(() => {
    // Cuando containerListData cambie, resetea el valor de id_container
    if (containerListData.length > 0) {
      setFieldValue('id_container', '');
    }
  }, [containerListData, setFieldValue]);

  if (containerListLoading)
  return (<Loading/>)
  if (containerListError)
  return (<FatalError/>)

  return (
    <Dialog 
      open={openDialog} 
      onClose={() => setOpenDialog(false)}
      sx={{
        '& .MuiDialog-paper': { // Apunta al papel del diálogo
          width: '100%', // Ancho completo en dispositivos móviles
          maxWidth: '400px', // Máximo ancho en dispositivos móviles
          mx: 2, // Margen horizontal para evitar que toque los bordes de la pantalla
          height: 'auto', // Altura automática
          maxHeight: '90vh', // Máxima altura para evitar que ocupe toda la pantalla
          // Estilos para pantallas más grandes
          '@media (min-width:600px)': {
            maxWidth: '600px', // Máximo ancho en pantallas pequeñas (tablets)
          },
          '@media (min-width:900px)': {
            maxWidth: '800px', // Máximo ancho en pantallas medianas (computadoras pequeñas)
          }
        }
      }}
    >
      <form onSubmit={reportFormik.handleSubmit}>
      <DialogTitle>Seleccione un contenedor:</DialogTitle>
      <DialogContent>
        <Select
          name = "id_container"
          value={reportFormik.values.id_container}
          onChange={e => {
            //reportFormik.values.id_container = e.target.value.toString()
            reportFormik.setFieldValue('id_container',e.target.value.toString())
          }}
          fullWidth
        >
          {containerListData.map(container => (
            <MenuItem key={container.id} value={container.id}>
              {container.container_code }
            </MenuItem>
          ))}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenDialog(false)}>Cancelar</Button>
        <Button type="submit" onClick={() => setOpenDialog(false)}>Ok</Button>
      </DialogActions>
      </form>
    </Dialog>    
  );
};

export default ContainersDialogSelector;