import { Box,Button,useMediaQuery,useTheme,TextField } from "@mui/material";
import { DataGrid, esES, GridToolbar } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../../auth/useAuth";
import Header from "../../../../components/Header";
import { useFetchGET } from "../../../../state/FetchHelpers";
import AddIcon from '@mui/icons-material/Add';
import { tokens } from "../../../../theme";
import { boxesListRoute, modifyDiscountBoxRoute, newBoxRoute } from "../../../../config";
import Loading from "../../../global/Loading";
import FatalError from "../../../global/FatalError";
import { useEffect, useState } from "react";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import swal from "sweetalert";
import * as yup from "yup";
import { Formik } from "formik"

const BoxSizes = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode)
  const auth = useAuth();
  const token = auth.globals.token

  const isNonMobile = useMediaQuery("(min-width:600px)")

  const [ loadingRequest, setLoadingRequest ] = useState(false)
  const [ errorRequest, setErrorRequest ] = useState(null)

  const [reloadData, setReloadData] = useState(false);
  const handleReloadData = () => {
    setReloadData(true);
  };

  useEffect(() => {
    // Llama a la función para recargar datos cuando reloadData cambie a true
    if (reloadData) {
      handleReloadData();
      setReloadData(false);
      setInicitalValues({
        width: "",
        height: "",
        length: "",
        maximum_discount: "",
        create_user: auth.globals.id.toString()
      })
    }
  }, [reloadData,auth.globals.id]);

  const { data: boxesListData, loading: boxesListLoading, error: boxesListError } = useFetchGET(boxesListRoute(),token,[],reloadData);
  //const {data,loading,error} = useFetch2(_user!=null?`${url}/api/pools/user/${_user}`:`${url}/api/pools`,token)
  //console.log(boxesListData)
  //const isNonMobile = useMediaQuery("(min-width:600px)")
  const navigate = useNavigate();

  const mostrarErrorRequest=(error)=>{
    if(error!=null){
      try{
        swal({
          title:"Error",
          text: "Resultado: "+error,
          icon: "error",
          button:"Aceptar"
        })
        if(error==="No tiene autorización o su sesión ha expirado"){
          auth.logout();
        }
      }catch(e){
        swal({
          title:"Error",
          text: "Error inesperado ",
          icon: "error",
          button:"Aceptar"
        })
      }
    }
    setErrorRequest(null)
  }

  const alertSucess=(text_title,text_content='')=>{    
    try{
      swal({
        title:text_title,
        text: text_content,
        icon: "success",
        button:"Aceptar"
      })
    }catch(e){
      swal({
        title:"Error inesperado",
        text: "Error inesperado ",
        icon: "error",
        button:"Aceptar"
      })
    }
    navigate("/Sizes")
  }


  const newBox = async(body) =>{
    //console.log('bpdy',body)
    try{
      
      let res = await fetch(newBoxRoute(), {
        method: "POST",
        headers: {
          //"Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Accept":"application/json",
          "Authorization": `Bearer ${token}`
          //"x-access-token": token,
        },
        body: JSON.stringify(body),
        //cors: 'no-cors'
      })
      let data = await res.json()
      if(res.ok){
        setLoadingRequest(false)
        alertSucess('Nuevo tamaño Ingresado',data.message)
        navigate("/Sizes")
        setReloadData(true);       
      }else{
        //console.log('res else',res)
        if(res.status === 401||res.status === 403){
          mostrarErrorRequest("No tiene autorización o su sesión ha expirado")          
        }
        mostrarErrorRequest(data.detail)
        setErrorRequest(data.detail)    
      }
      setLoadingRequest(false) 
      return data;
    }catch(errorRequest){
      //console.log('errorRequest',errorRequest)
      setLoadingRequest(false)
      setErrorRequest(errorRequest)
    }
  }

  const [initialValues,setInicitalValues] = useState({
    width: "",
    height: "",
    length: "",
    maximum_discount: "",
    create_user: auth.globals.id.toString()
  })

  const userSchema = yup.object().shape({
    width: yup.number().required("* Valor Requerido"),
    height: yup.number().required("* Valor Requerido"),
    length: yup.number().required("* Valor Requerido"),
    maximum_discount: yup.number().required("* Valor Requerido"),
  })

  const modifyDiscountBox = async(box_id,body) =>{
    try{      
      let res = await fetch(modifyDiscountBoxRoute(box_id), {
        method: "PUT",
        headers: {
          //"Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Accept":"application/json",
          "Authorization": `Bearer ${token}`
          //"x-access-token": token,
        },
        body: JSON.stringify(body),
        //cors: 'no-cors'
      })
      let data = await res.json()
      if(res.ok){
        setLoadingRequest(false)
        alertSucess('Descuento Máximo actualizado',data.message)
        setReloadData(true);       
      }else{
        //console.log('res else',res)
        if(res.status === 401||res.status === 403){
          mostrarErrorRequest("No tiene autorización o su sesión ha expirado")          
        }
        mostrarErrorRequest(data.detail)
        setErrorRequest(data.detail)    
      }
      setLoadingRequest(false) 
      return data;
    }catch(err){
      //console.log('errorRequest',errorRequest)
      setLoadingRequest(false)
      setErrorRequest(err)
    }
  }


  const handleFormSubmit = (values) => {
    setInicitalValues(values)
    setLoadingRequest(true)
    newBox(values)
  }

  const mostrarAlertRequest=(info)=>{
    if(info!=null){
      try{
        swal({
          title:"Alerta",
          text: info,
          icon: "warning",
          button:"Aceptar"
        })
        
      }catch(e){
        swal({
          title:"Error",
          text: "Error inesperado ",
          icon: "error",
          button:"Aceptar"
        })
      }
    }
  }

  const handleUpdate = (id, value) => {
    // Intentar convertir el valor a un número float
    const floatValue = parseFloat(value);
    
    // Verificar si el valor es un número
    if (!isNaN(floatValue)) {
      const body = {
        "maximum_discount" : floatValue.toString()
      }
      modifyDiscountBox(id,body)
    } else {
      mostrarAlertRequest('Ingrese un número valido')
    }
  };

  const columns=[
    {
      field:"id", 
      headerName: "ID",
      flex: 0.25,
      cellClassName: "id-column--cell",
      headerClassName: "id-header--cell",
      hideable:false
    },
    {
      //hide: true,
      field:"width", 
      headerName: "Ancho",
      flex: 0.75, 
      cellClassName: "width-column--cell",
      minWidth:120
    },
    {
      //hide: true,
      field:"height", 
      headerName: "Alto",
      flex: 0.75, 
      cellClassName: "height-column--cell",
      minWidth:120
    },
    {
      //hide: true,
      field:"length", 
      headerName: "Largo",
      flex: 0.75, 
      cellClassName: "length-column--cell",
      minWidth:120
    },
    /*{
      //hide: true,
      field:"maximum_discount", 
      headerName: "Descuento autorizado (USD)",
      flex: 0.75, 
      cellClassName: "maximum_discount-column--cell",
      minWidth:120
    },*/
    {
      minWidth:200,
      field: "maximum_discount",
      headerName: "Descuento autorizado (USD)",
      flex: 1,
      editable: true, // Hace la celda editable
      renderCell: (params) => {    
        return (
          <Box             
            width="98%"
            height="100%"
            display="flex"
            alignItems="center" // Alinea los elementos verticalmente
            justifyContent="left"
            m="0 auto"
            gap={1}
          >
            <TextField
              size="small"
              type="text"
              value={params.value}
              sx={{ width: 'auto', maxWidth: '100px' }}
            />
            <Button color="success" variant="contained" size="small" onClick={() => handleUpdate(params.id, params.value)}>
              <ChangeCircleIcon/>
              Aplicar
            </Button>
          </Box>
        );
      },
    },
    {
      minWidth:250,
      filterable:false,
      sortable:false,
      field:"Cambiar Descuento", 
      headerName: "Ver",
      headerAlign:"center",
      flex: 1, 
      cellClassName: "actions-column--cell",
      renderCell:({ row })=>{
        return(
          <Box
            width="98%"
            //maxWidth="175px"
            height="75%"
            maxHeight="35px" 
            display="flex" 
            justifyContent="center" 
            m="0 auto">
            <Button
              color="secondary"
              variant="contained"
              onClick={()=>{navigate("/Sizes/BoxPrices?id="+row.id+"&name="+row.dimension_concatenada)}}>
            <MonetizationOnIcon/>
              <Box sx={{ml:"5px"}}>
                Precios por País / Empresa
              </Box>
            </Button>
          </Box>
        )
      }
    }
  ]

  if(loadingRequest)
  return (<Loading/>)

  if(boxesListLoading)
  return (<Loading/>)

  if(errorRequest)
  return(<FatalError/>)

  if(boxesListError)
  return(<FatalError/>)

  return (
    <Box p={1} >
      {/*<Box display="flex" justifyContent="space-between" alignItems="center">*/}
      {<Header title="Tamaños de cajas" subtitle="Listado de tamaños"/>}
      <Formik
        enableReinitialize={true}
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema = {userSchema}
      >
      {({
        values, 
        errors, 
        touched, 
        handleBlur, 
        handleChange,
        handleSubmit,
        setFieldValue
      }) => (
        <form onSubmit={handleSubmit} >
          <Box
            display="grid" 
            gap="15px"
            marginBottom="15px" 
            gridTemplateColumns="repeat(12, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile? undefined : "span 12"}
            }}
          >        
            <TextField
                  fullWidth={!isNonMobile}
                  size="small"
                  variant="filled"
                  type="text"
                  label="Ancho"
                  onBlur = {handleBlur}
                  onChange = {handleChange}
                  value = { values.width}
                  name = "width"
                  error = {!!touched.width && !!errors.width}
                  helperText={touched.width && errors.width}
                  sx={{ gridColumn: "span 3"}}
            />
            <TextField
                  fullWidth={!isNonMobile}
                  size="small"
                  variant="filled"
                  type="text"
                  label="Alto"
                  onBlur = {handleBlur}
                  onChange = {handleChange}
                  value = { values.height}
                  name = "height"
                  error = {!!touched.height && !!errors.height}
                  helperText={touched.height && errors.height}
                  sx={{ gridColumn: "span 3"}}
            />
            <TextField
                  fullWidth={!isNonMobile}
                  size="small"
                  variant="filled"
                  type="text"
                  label="Largo"
                  onBlur = {handleBlur}
                  onChange = {handleChange}
                  value = { values.length}
                  name = "length"
                  error = {!!touched.length && !!errors.length}
                  helperText={touched.length && errors.length}
                  sx={{ gridColumn: "span 3"}}
            />
            <TextField
                  fullWidth={!isNonMobile}
                  size="small"
                  variant="filled"
                  type="text"
                  label="Descuento máximo"
                  onBlur = {handleBlur}
                  onChange = {handleChange}
                  value = { values.maximum_discount}
                  name = "maximum_discount"
                  error = {!!touched.maximum_discount && !!errors.maximum_discount}
                  helperText={touched.maximum_discount && errors.maximum_discount}
                  sx={{ gridColumn: "span 3"}}
            />
          </Box>
              <Box display="flex" justifyContent="end" mt="20px">               
                <Button type="submit" color="secondary" variant="contained">
                  <AddIcon/>
                    Agregar nuevo tamaño
                </Button>                           
              </Box>
        </form>
        )}
      </Formik>
      <Box m="5px 0px 50px 0" /*height={isNonMobile?"100%":undefined}*/ sx={{
        "& .MuiDataGrid-root":{
          border: "none",
        },
        "& .MuiDataGrid-cell":{
          borderBottom: "none"
        },
        "& .MuiDataGrid-columnHeaders":{
          backgroundColor: colors.blueAccent[700],
          borderBottom: "none",
          color: "white"
        },
        "& .MuiDataGrid-virtualScroller":{
          backgroundColor: colors.primary[400],
        },
        "& .MuiDataGrid-footerContainer":{
          borderTop: "none",
          backgroundColor: colors.blueAccent[700],
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text":{
          color: `${colors.grey[100]} !important`,
        },
        "& .MuiToolbar-gutters":{
          color: "white"
        }

      }}>
        <DataGrid
          autoHeight
          initialState={{
            columns: {
              columnVisibilityModel: {
                // Hide columns status and traderName, the other columns will remain visible
                id: false,
                //actions: true
              },
            },
          }}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          rows={boxesListData}
          columns={columns}
          getRowId={(row) => row.id}
          density="compact"
          slots={{
            toolbar: GridToolbar,
          }}
          /*columnVisibilityModel={{
            id: false,
          }}*/
          
        />
      </Box>
    </Box>
  );


}

export default BoxSizes;