export const fee_categories = [
  {
    id:"Gastos operativos",
    name:"Gastos operativos"
  },
  {
    id: "Gastos administrativos",
    name: "Gastos administrativos" 
  },
  {
    id: "Gastos de Venta",
    name: "Gastos de Venta"
  },
  { 
    id: "Otros gastos",
    name: "Otros gastos"
  },
  {
    id: "Nómina",
    name: "Nómina"
  }
]

export const fee_types = {
  "Gastos operativos" : [
    {id: "Gasolina", name: "Gasolina"},
    {id: "Mantenimiento de Vehículo", name: "Mantenimiento de Vehículo"},
    {id: "Productos de Limpieza", name: "Productos de Limpieza"},
    {id: "Materia Prima de Cocina", name: "Materia Prima de Cocina"},
    {id: "Herramientas Varias", name: "Herramientas Varias"},
    {id: "Alquiler de vehículos", name: "Alquiler de vehículos"},
    {id: "Transporte de Carga", name: "Transporte de Carga"},
  ],
  "Gastos administrativos" : [
    {id: "Energía Eléctrica", name: "Energía Eléctrica"},
    {id: "Internet", name: "Internet"},
    {id: "Agua Potable", name: "Agua Potable"},
    {id: "Utiles de Oficina", name: "Utiles de Oficina"},
    {id: "Teléfono", name: "Teléfono"},
    {id: "Cable TV", name: "Cable TV"},
    {id: "Recolección de Basura", name: "Recolección de Basura"},
  ],
  "Gastos de Venta" : [
    {id: "Publicidad e Impresiones", name: "Publicidad e Impresiones"},
    {id: "Comisiones", name: "Comisiones"},
    {id: "Alquileres", name: "Alquileres"},
  ],
  "Otros gastos" : [
    {id: "Alimentación", name: "Alimentación"},
    {id: "Hospedaje", name: "Hospedaje"},
    {id: "Viáticos", name: "Viáticos"},
    {id: "Consultas Médicas", name: "Consultas Médicas"},
    {id: "Medicinas", name: "Medicinas"},
    {id: "Otros", name: "Otros"},
    {id: "", name: ""},
  ],
  "Nómina" : [
    {id: "Sueldos", name: "Sueldos"},
    {id: "Trabajos temporales", name: "Trabajos temporales"},
  ],
}

export const fee_payments_methods = [
  {
    id:"Efectivo",
    name:"Efectivo"
  },
  {
    id: "Transferencia Bancaria",
    name: "Transferencia Bancaria" 
  },
]