import React, { useRef } from 'react';
import { LoadScriptNext } from '@react-google-maps/api';

const GoogleMapsScriptLoader = ({ children }) => {
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const librariesRef = useRef(['places']);

  return (
    <LoadScriptNext
      googleMapsApiKey={apiKey}
      libraries={librariesRef.current}
      async
      defer
    >
      {children}
    </LoadScriptNext>
  );
};

export default GoogleMapsScriptLoader;