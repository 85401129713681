import { Box, Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography} from "@mui/material";
//import { useNavigate } from "react-router-dom";
import useAuth from "../../auth/useAuth";
import Header from "../../components/Header";
import { useFetchGET } from "../../state/FetchHelpers";
import { userRolesListRoute, userRoute } from "../../config";
import Loading from "../global/Loading";
import FatalError from "../global/FatalError";
import { useState } from "react";
import ChangePasswordDialog from "./Apps/Users/ChangePasswordDialog";

//import { tokens } from "../../../../theme";
//import Loading from "../global/Loading";
//import FatalError from "../global/FatalError";

const Cuenta = () => {
  //const theme = useTheme();
  //const colors = tokens(theme.palette.mode)
  const auth = useAuth();
  const token = auth.globals.token
  //const {data,loading,error} = useFetch2(`${url}/api/clients`,token)
  const [openChangePasswordDialog,setOpenChangePasswordDialog] = useState(false)

  //const navigate = useNavigate();
  const { data: userData,loading: userLoading,error: userError } = useFetchGET(userRoute(auth.globals.id),token)
  //console.log(userData)
  const { data: userRolesListData, loading: userRolesListLoading, error: userRolesListError } = useFetchGET(userRolesListRoute(auth.globals.id),token,[]);
  //console.log(userRolesListData)

  const user = userData && userData.length > 0 ? userData[0] : null;

  if (userLoading||userRolesListLoading)
  return (<Loading/>)
  if (userError||userRolesListError)
  return (<FatalError/>)

  return (
    <Box m="20px" marginBottom="64px">
      <ChangePasswordDialog
        openChangePasswordDialog={openChangePasswordDialog}
        setOpenChangePasswordDialog={setOpenChangePasswordDialog}
      />
      {/*<Box display="flex" justifyContent="space-between" alignItems="center">*/}
      <Header title="Cuenta" subtitle="Mi cuenta"/>
      {user && (
        <Paper elevation={3} sx={{ padding: 2, margin: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField label="Nombre" value={user.name} fullWidth margin="normal" InputProps={{ readOnly: true }} />
              <TextField label="Usuario" value={user.user} fullWidth margin="normal" InputProps={{ readOnly: true }} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField label="Apellido" value={user.lastname} fullWidth margin="normal" InputProps={{ readOnly: true }} />
              <TextField label="Email" value={user.email} fullWidth margin="normal" InputProps={{ readOnly: true }} />
              <TextField label="Teléfono" value={user.phone} fullWidth margin="normal" InputProps={{ readOnly: true }} />
            </Grid>
          </Grid>
        </Paper>
      )}
      <Box display="flex" justifyContent="end" m="15px">
        {<Button color="secondary" variant="contained" onClick={()=>{setOpenChangePasswordDialog(true)}}>
          Cambiar Contraseña
        </Button>}                                
      </Box>
      {userRolesListData && userRolesListData.length > 0 && (
        <Box sx={{ marginTop: 2 }}>
          <Typography variant="h6" sx={{ marginBottom: 2 }}>Mis Roles</Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                {userRolesListData.map((role) => (
                  <TableRow key={role.id}>
                    <TableCell>{role.name}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Box>
  );
}

export default Cuenta;