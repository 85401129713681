import { Box, TextField,Button, MenuItem, InputLabel, FormControl, Paper, Typography, InputAdornment} from "@mui/material"
import Select from '@mui/material/Select';
import { useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik"
import useAuth from "../../../../auth/useAuth";
import Header from "../../../../components/Header";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useCallback, useEffect, useState } from "react";
import { useFetchGET } from "../../../../state/FetchHelpers";
import { balancePaymentRoute, boxPriceRoute, boxesListRoute, clientByPhoneRoute, companiesListRoute, getDeliveryPickupByCorrelativeRoute, getMaxDiscountBoxRoute, getRouteByZip, getStickerDataRoute, modifyDeliveryPickupByCorrelativeRoute, /*modifyDeliveryPickupByCorrelativeRoute, */municipalitiesRoute, newDeliveryPickupRoute, originCountryRoutesRoute, packageTypeListRoute, receiverClientsByIdSenderClient, reseiverCountriesRoute, statesRoute, storesListRoute } from "../../../../config";
import swal from 'sweetalert';
import * as yup from "yup";
import { useTheme } from "@emotion/react";
import { tokens } from "../../../../theme";
import PlacesAutocomplete, { geocodeByAddress } from "react-places-autocomplete";
import Loading from "../../../global/Loading";
import FatalError from "../../../global/FatalError";
import SearchIcon from '@mui/icons-material/Search';
import SaveIcon from '@mui/icons-material/Save';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import CustomDialog from "../Delivery/CustomDialog";
import InactivateDialog from "../Delivery/InactivateDialog";
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import { generateHTML } from "../Reportes/HTMLGenerator/StikersGenerator";

const PickupForm = ({permissionsListData}) => {
  const navigate = useNavigate();
  const auth = useAuth();
  const token = auth.globals.token
  const theme = useTheme();
  const colors = tokens(theme.palette.mode)
  const isNonMobile = useMediaQuery("(min-width:600px)")
  const [ loadingRequest, setLoadingRequest ] = useState(false)
  const [ errorRequest, setErrorRequest ] = useState(null)
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const _id = query.get('id')
  

  const [initialValues,setInitialValues] = useState({
    status:"2",
    id_store:"2",
    id_package_type:"2",
    company_id:"1",
    sender_id:"",
    senders_phone:"",
    senders_name:"",
    senders_address:"",
    senders_zip:"",
    senders_city:"",
    senders_state:"",
    receives_id:"-1",
    receives_phone:"",
    receives_name:"",
    receives_cell:"",
    receives_address:"",
    receives_zip:"",
    receives_country:"",
    receives_state:"",
    receives_municipality:"",
    user_driver:null,
    delivery_date:null,
    id_box_size:"-1",
    width:"",
    height:"",
    length:"",
    delivery_cost:"0",
    approximate_cost:"",
    notes:"",
    create_user: auth.globals.id.toString(),
    extra_payment:"0",
    extra_payment_description:"",
    pickup_date: "",
    pickup_time:"",
    weight:"",
    insurance_cost: "",
    discount: "0",
    content_description: "",
    amount:"0",
    correlative:"",
    origin_route:null,
    origin_route_pickup:"",
    delivery_user:null,
    pickup_user:auth.globals.id.toString(),
    last_update_user:auth.globals.id.toString()
  })


  const { data: companiesListData, loading: companiesListLoading, error: companiesListError } = useFetchGET(companiesListRoute(),token,[]);
  const { data: storesListData, loading: storesListLoading, error: storesListError } = useFetchGET(storesListRoute(),token,[]);
  const { data: packageTypesListData, loading: packageTypesListLoading, error: packageTypesListError } = useFetchGET(packageTypeListRoute(),token,[]);
  const { data: boxesListData, loading: boxesListLoading, error: boxesListError } = useFetchGET(boxesListRoute(),token,[]);
  const { data: routesListData, loading: routesListLoading, error: routesListError } = useFetchGET(originCountryRoutesRoute(),token,[]);


  const [reloadData, setReloadData] = useState(false);
  const handleReloadData = () => {
    setReloadData(true);
  };

  useEffect(() => {
    // Llama a la función para recargar datos cuando reloadData cambie a true
    if (reloadData) {
      handleReloadData();
      setReloadData(false);
    }
  }, [reloadData]);

  const [statesList, setStatesList] = useState([]);
  const [municipalitiesList, setMunicipalitiesList] = useState([]);

  const { data: pickupData,loading: pickupLoading,error: pickupError } = useFetchGET(_id!=null?getDeliveryPickupByCorrelativeRoute(_id):null,token)
  const { data: countriesListData, loading: countriesListLoading, error: countriesListError } = useFetchGET(reseiverCountriesRoute(),token,[]);
  const [isCountriesLoaded, setIsCountriesLoaded] = useState(false);
  const [isStatesLoaded, setIsStatesLoaded] = useState(false);
  const [isMunicipalitiesLoaded, setIsMunicipalitiesLoaded] = useState(false);

  const loadStates = useCallback(async (countryId) => {
    try {
      const response = await fetch(statesRoute(countryId), { headers: { 'Authorization': `Bearer ${token}` } });
      const data = await response.json();
      setStatesList(data);
    } catch (error) {
      console.error("Error al cargar estados: ", error);
    }
  },[token]);

  const loadMunicipalities = useCallback(async (stateId) => {
    try {
      const response = await fetch(municipalitiesRoute(stateId), { headers: { 'Authorization': `Bearer ${token}` } });
      const data = await response.json();
      setMunicipalitiesList(data);
    } catch (error) {
      console.error("Error al cargar municipios : ", error);
    }
  },[token]);

  const [maxDiscount, setMaxDiscount] = useState(permissionsListData && permissionsListData.includes(45)?99999:0)

  const getMaxDiscount = useCallback(async (box_size) => {
    try {
      if(permissionsListData.includes(45)){
        setMaxDiscount(99999)
      }else if(box_size && box_size !== "" && box_size !== -1){
        const response = await fetch(getMaxDiscountBoxRoute(box_size), { headers: { 'Authorization': `Bearer ${token}` } });
        const data = await response.json();
        setMaxDiscount(data[0].maximum_discount)
      }else{
        setMaxDiscount(0)
      }
    } catch (error) {
      setMaxDiscount(0)
      console.error("Error al descuento máximo : ", error);
    }
  },[token,permissionsListData]);

  useEffect(() => {
    if (_id && pickupData) {
      const data = pickupData[0];
      //console.log('pickupData',data)
      getMaxDiscount(pickupData[0].id_box_size)
      if(pickupData[0].id_box_size===null){
        pickupData[0].id_box_size="-1"
      }
      if(pickupData[0].weight===null){
        pickupData[0].weight=""
      }
      if(pickupData[0].status === "1" && pickupData[0].origin_route != null){
        pickupData[0].origin_route_pickup =  pickupData[0].origin_route
      }
      if (data.receives_country) {
        loadStates(data.receives_country).then(() => {
          setIsStatesLoaded(true);
          if (data.receives_state) {
            loadMunicipalities(data.receives_state).then(() => {
              setIsMunicipalitiesLoaded(true);
              setInitialValues(data);
            });
          } else {
            setInitialValues({ ...data, receives_state: '', receives_municipality: '' });
            setIsMunicipalitiesLoaded(true);
          }
        });
      } else {
        setInitialValues({ ...data, receives_country: '', receives_state: '', receives_municipality: '' });
        setIsStatesLoaded(true);
        setIsMunicipalitiesLoaded(true);
      }
    } else {
      setIsCountriesLoaded(true);
      setIsStatesLoaded(true);
      setIsMunicipalitiesLoaded(true);
    }
  }, [pickupData, _id,loadMunicipalities,loadStates,getMaxDiscount]);

  const [receivesClients,setReceivesClients] = useState([])
  const [openDialog, setOpenDialog] = useState(false);
  //const [selectedClient, setSelectedClient] = useState({});
  const [openInactivateDialog, setOpenInactivateDialog] = useState(false);

  const getFecha =(fecha)=>{
    return fecha.slice(0,10)
  }

  const getBoxPrice = async(box_size,country,values,setFieldValue) =>{
    if(box_size && box_size !== "" && box_size !== "-1" && country && country!=="" && values.company_id !==""){
      try{
        console.log('box',box_size)
        console.log('country',country)
        console.log('company',values.company_id)    
        let res = await fetch(boxPriceRoute(box_size,country,values.company_id), {
          method: "GET",
          headers: {
            //"Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Accept":"application/json",
            "Authorization": `Bearer ${token}`
            //"x-access-token": token,
          },
          //cors: 'no-cors'
        })
        let data = await res.json()
        console.log('respuesta precios',data)
        if(res.ok){
          values.approximate_cost=data[0].price
          setFieldValue("approximate_cost",data[0].price.toString())
        }else{
          if(res.status === 404){
            values.approximate_cost=""
            setFieldValue("approximate_cost","")
          }
          if(res.status === 401||res.status === 403){
            mostrarErrorRequest("No tiene autorización o su sesión ha expirado")          
          }  
        }
        setLoadingRequest(false)
        return data;
      }catch(errorRequest){
        setLoadingRequest(false)
        setErrorRequest(errorRequest)
      }
    }
  }

  const getStickerData = async(box_id) =>{
    if(box_id && box_id !== "" ){
      try{
        //console.log('box_id',box_id)
        let res = await fetch(getStickerDataRoute(box_id), {
          method: "GET",
          headers: {
            //"Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Accept":"application/json",
            "Authorization": `Bearer ${token}`
            //"x-access-token": token,
          },
          //cors: 'no-cors'
        })
        let data = await res.json()
        //console.log('respuesta sticker data',data)
        if(res.ok){
          //console.log('respuesta correcta')
          generateHTML(data,box_id)
        }else{
          if(res.status === 404){
            mostrarErrorRequest('No se encuentra la informacion para generar la etiqueta')
          }
          if(res.status === 401||res.status === 403){
            mostrarErrorRequest("No tiene autorización o su sesión ha expirado")          
          }  
        }
        setLoadingRequest(false)
        return data;
      }catch(errorRequest){
        setLoadingRequest(false)
      }
    }
  }

  const getRoute = async(zip,setFieldValue) =>{
    if(zip && zip !== "" && zip.length === 5 ){
      try{
        let res = await fetch(getRouteByZip(zip), {
          method: "GET",
          headers: {
            //"Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Accept":"application/json",
            "Authorization": `Bearer ${token}`
            //"x-access-token": token,
          },
          //cors: 'no-cors'
        })
        let data = await res.json()
        //console.log('respuesta rutas',data)
        if(res.ok){
          setFieldValue("origin_route_pickup",data[0].id.toString())                
        }else{
          if(res.status === 404){
            //console.log('Zip no encontrado en rutas')
            setFieldValue("origin_route_pickup",'')
          }
          if(res.status === 401||res.status === 403){
            mostrarErrorRequest("No tiene autorización o su sesión ha expirado")          
          }  
        }
        setLoadingRequest(false)
        return data;
      }catch(errorRequest){
        setLoadingRequest(false)
        setErrorRequest(errorRequest)
      }
    }
  }

  const mostrarErrorRequest=(error)=>{
    if(error!=null){
      try{
        swal({
          title:"Error",
          text: "Resultado: "+error,
          icon: "error",
          button:"Aceptar"
        })
        if(error==="No tiene autorización o su sesión ha expirado"){
          auth.logout();
        }
      }catch(e){
        swal({
          title:"Error",
          text: "Error inesperado ",
          icon: "error",
          button:"Aceptar"
        })
      }
    }
    setErrorRequest(null)
  }


  const mostrarAlertRequest=(info)=>{
    if(info!=null){
      try{
        swal({
          title:"Alerta",
          text: info,
          icon: "warning",
          button:"Aceptar"
        })
        
      }catch(e){
        swal({
          title:"Error",
          text: "Error inesperado ",
          icon: "error",
          button:"Aceptar"
        })
      }
    }
  }

  const isStateListArray = Array.isArray(statesList);//valida si es un arreglo
  const isMunicipalitiesListArray = Array.isArray(municipalitiesList);//valida si es un arreglo

  const alertSucess=(text_title,text_content='')=>{    
    try{
      swal({
        title:text_title,
        text: text_content,
        icon: "success",
        button:"Aceptar"
      })
    }catch(e){
      swal({
        title:"Error inesperado",
        text: "Error inesperado ",
        icon: "error",
        button:"Aceptar"
      })
    }
    navigate("/Dashboard")
  }

  const newPickup = async(body) =>{
    if(body.id_box_size==="-1"){
      body.id_box_size=null
    }    
    if(body.weight===""){
      body.weight=null
    }
    if(body.receives_id===null||body.receives_id===""){
      body.receives_id='-1'
    }
    try{
      console.log('body new pickup',body)
      let res = await fetch(newDeliveryPickupRoute(), {
        method: "POST",
        headers: {
          //"Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Accept":"application/json",
          "Authorization": `Bearer ${token}`
          //"x-access-token": token,
        },
        body: JSON.stringify(body),
        //cors: 'no-cors'
      })
      let data = await res.json()
      console.log('resultados nuevo pickup: ',data)
      if(res.ok){
        alertSucess("Pickup "+data.correlative +" creado con éxito","Pickup created successfully")
        navigate("/Dashboard")          
      }else{
        if(res.status === 401||res.status === 403){
          mostrarErrorRequest("No tiene autorización o su sesión ha expirado")          
        }
        mostrarErrorRequest(data.detail)    
      }
      setLoadingRequest(false)
      return data;
    }catch(errorRequest){
      setLoadingRequest(false)
      setErrorRequest(errorRequest)
      mostrarErrorRequest(errorRequest)
    }
  }

  const modifyPickup = async(body,_id) =>{
    if(body.id_box_size==="-1"){
      body.id_box_size=null
    }    
    if(body.weight===""){
      body.weight=null
    }
    if(body.status==="1"){
      body.status="2"
      body.pickup_user=auth.globals.id.toString()
    }
    if(body.receives_id===null||body.receives_id===""){
      body.receives_id='-1'
    }
    try{
      //console.log('body modify pickup',body)
      let res = await fetch(modifyDeliveryPickupByCorrelativeRoute(body.id), {
        method: "PUT",
        headers: {
          //"Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Accept":"application/json",
          "Authorization": `Bearer ${token}`
          //"x-access-token": token,
        },
        body: JSON.stringify(body),
        //cors: 'no-cors'
      })
      let data = await res.json()
      //console.log('resultados modifica pickup: ',data)
      if(res.ok){
        alertSucess("Pickup "+body.correlative +" modificado con éxito",data.message)
        navigate("/Dashboard")        
      }else{
        if(res.status === 401||res.status === 403){
          mostrarErrorRequest("No tiene autorización o su sesión ha expirado")          
        }
        mostrarErrorRequest(data.detail)    
      }
      setLoadingRequest(false)
      return data;
    }catch(errorRequest){
      setLoadingRequest(false)
      setErrorRequest(errorRequest)
      mostrarErrorRequest(errorRequest)
    }
  }

  const balancePayment = async(body) =>{
    try{
      let res = await fetch(balancePaymentRoute(body.id), {
        method: "PUT",
        headers: {
          //"Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Accept":"application/json",
          "Authorization": `Bearer ${token}`
          //"x-access-token": token,
        },
        body: JSON.stringify(body),
        //cors: 'no-cors'
      })
      let data = await res.json()
      //console.log('resultados balancePayment: ',data)
      if(res.ok){
        alertSucess("Permisos de entrega "+body.correlative +" modificados con éxito",data.message)
        navigate("/Dashboard")        
      }else{
        if(res.status === 401||res.status === 403){
          mostrarErrorRequest("No tiene autorización o su sesión ha expirado")          
        }
        mostrarErrorRequest(data.detail)
      }
      setLoadingRequest(false)
      return data;
    }catch(errorRequest){
      setLoadingRequest(false)
      setErrorRequest(errorRequest)
    }
  }


  /*const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;*/
  const phoneRegExpSender = /^\d{8,10}$/;
  const phoneRegExpReceiver = /^\d{8,10}$/;

  const userSchema = yup.object().shape({
    senders_name: yup.string().required("* Valor Requerido"),
    senders_phone: yup.string().matches(phoneRegExpSender,"Número de telefono no valido").required("* Valor Requerido"),
    senders_address: yup.string().required("* Valor Requerido"),
    senders_zip: yup.string().required("* Valor Requerido"),
    senders_city: yup.string().required("* Valor Requerido"),
    senders_state: yup.string().required("* Valor Requerido"),
    receives_phone: yup.string().matches(phoneRegExpReceiver,"Número de telefono no valido").required("* Valor Requerido"),
    receives_name: yup.string().required("* Valor Requerido"),
    receives_cell: yup.string().matches(phoneRegExpReceiver,"Número de telefono no valido"),
    receives_address: yup.string().required("* Valor Requerido"),
    receives_country: yup.string().required("* Valor Requerido"),
    receives_state: yup.string().required("* Valor Requerido"),
    receives_municipality: yup.string().required("* Valor Requerido"),
    origin_route_pickup: yup.string().required("* Valor Requerido"),
    pickup_date: yup.date().required("* Valor Requerido"),
    weight: yup.number().min(0.01, 'El valor debe ser mayor a 0').typeError('Debe ser un número válido'),
    width: yup.number().required("* Valor Requerido").integer('Debe ser un número entero').min(1, 'El número debe ser mayor a 0').typeError('Debe ser un número válido'),
    height: yup.number().required("* Valor Requerido").integer('Debe ser un número entero').min(1, 'El número debe ser mayor a 0').typeError('Debe ser un número válido'),
    length: yup.number().required("* Valor Requerido").integer('Debe ser un número entero').min(1, 'El número debe ser mayor a 0').typeError('Debe ser un número válido'),
    approximate_cost: yup.number().required('* Valor Requerido').min(0.01, 'El valor debe ser mayor a 0').typeError('Debe ser un número válido'),
    insurance_cost: yup.number().required('* Valor Requerido').min(0.00, 'El valor debe ser mayor o igual a 0').typeError('Debe ser un número válido'),
    discount: yup.number()
      .required('* Valor Requerido')
      .min(0.00, 'El valor debe ser mayor o igual a 0')
      .max(maxDiscount, `El valor máximo permitido para esta caja es de ${maxDiscount}`) 
      .typeError('Debe ser un número válido'),
    extra_payment: yup.number().required('* Valor Requerido').min(0.00, 'El valor debe ser mayor o igual a 0').typeError('Debe ser un número válido'),
    extra_payment_description: yup.string().when('extra_payment', (extra_payment, schema) => {
      return extra_payment && parseFloat(extra_payment) > 0 
        ? schema.required("* Valor Requerido") 
        : schema;
    })
  })


  const handleFormSubmit = (values) => {
    setInitialValues(values)
    setLoadingRequest(true)
    if(_id){
      modifyPickup(values,_id);
    }
    else{
      newPickup(values);
    }
  }

  const handleChangeRol = (values,target,setFieldValue) =>{
    values[target.name]=target.value.toString();
    if(target.name==="senders_phone"&&target.value.toString().length===10){
      getClient(values,setFieldValue)
    }
    if(target.name==="receives_country"){
      values["receives_state"]=""
      values["receives_municipality"]=""
    }
    if(target.name==="receives_state"){
      values["receives_municipality"]=""
    }
  }

  const handleChangeValue = (values,name,value) =>{
    values[name]=value.toString();
  }

  //const pais = (id_pais) => countriesListData.find(p => p.id === id_pais);
  
  const box_size = (id_box_size) => {
    const numericId = Number(id_box_size); // Convierte el id a número
    return boxesListData.find(box => box.id === numericId);
  };

  const getReceiverClient = async(id_client) =>{
    try{
      
      let res = await fetch(receiverClientsByIdSenderClient(id_client), {
        method: "GET",
        headers: {
          //"Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Accept":"application/json",
          "Authorization": `Bearer ${token}`
          //"x-access-token": token,
        },
        //cors: 'no-cors'
      })
      let data = await res.json()
      //console.log('clientes receptores',data)
      if(res.ok){
        if (data.length>0){
          setReceivesClients(data);
          setOpenDialog(true); // Abre el diálogo
          //setSelectedClient(data[0]);
        }     
      }else{
        console.log('res',res)
        if(res.status === 401||res.status === 403){
          mostrarErrorRequest("No tiene autorización o su sesión ha expirado")          
        }
        mostrarErrorRequest(data.detail)
      }
      setLoadingRequest(false)
      return data;
    }catch(errorRequest){
      setLoadingRequest(false)
      setErrorRequest(errorRequest)
    }
  }

  const getClient = async(values,setFieldValue) =>{
    try{      
      let res = await fetch(clientByPhoneRoute(values.senders_phone), {
        method: "GET",
        headers: {
          //"Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Accept":"application/json",
          "Authorization": `Bearer ${token}`
          //"x-access-token": token,
        },
        //cors: 'no-cors'
      })
      let data = await res.json()
      console.log('cliente',data)
      if(res.ok){
        if (data.length>0){
          setFieldValue("senders_address",data[0].address)
          setFieldValue("sender_id",data[0].id.toString())
          setFieldValue("senders_zip",data[0].zip)
          getRoute(data[0].zip,setFieldValue)
          setFieldValue("senders_name",data[0].name)
          setFieldValue("senders_state",data[0].state)
          setFieldValue("senders_city",data[0].city)
          getReceiverClient(data[0].id)          
        }else{
          mostrarAlertRequest('Cliente no encontrado')
        }          
      }else{
        if(res.status === 401||res.status === 403){
          mostrarErrorRequest("No tiene autorización o su sesión ha expirado")          
        }else{
          mostrarErrorRequest(data.detail)
        }  
      }
      setLoadingRequest(false)
      return data;
    }catch(errorRequest){
      setLoadingRequest(false)
      setErrorRequest(errorRequest)
    }
  }

  const handleGetClient = (values,setFieldValue) => {
    if (values.senders_phone.length>0){
      getClient(values,setFieldValue)
    }
  }

  if(pickupLoading||companiesListLoading||storesListLoading||packageTypesListLoading||boxesListLoading||routesListLoading||loadingRequest||countriesListLoading)
  return(<Loading/>)

  if(pickupError||companiesListError||storesListError||packageTypesListError||boxesListError||routesListError||errorRequest||countriesListError)
  return(<FatalError/>)

  return (
    <Box m="20px" marginBottom="64px">
      {!_id?
        <Header title="Ingresar Caja - Pickup" subtitle="Crea nuevo pedido"/>:
        <Header title={_id?'Pickup '+_id:''} subtitle="Datos del pickup"/>}
        {initialValues.record_status && initialValues.record_status === 'INACTIVO' &&(
          <Paper style={{ 
            backgroundColor: 'lightcoral',
            padding: '15px',
            margin: '15px 0',
            textAlign: 'center'}}
          >
            <Typography variant="h3">
              Este registro se encuentra INACTIVO
            </Typography>
          </Paper>
        )}      
      <Formik
        enableReinitialize={true}
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema = {userSchema}
      >
        {({
          values, 
          errors, 
          touched, 
          handleBlur, 
          handleChange,
          handleSubmit,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit} >
              <CustomDialog
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                receivesClients={receivesClients}
                setFieldValue={setFieldValue}
                loadStates={loadStates}
                loadMunicipalities={loadMunicipalities}
                setIsStatesLoaded={setIsStatesLoaded}
                setIsMunicipalitiesLoaded={setIsMunicipalitiesLoaded}
                getBoxPrice={getBoxPrice}
                values={values}
              />
              <InactivateDialog
                openInactivateDialog={openInactivateDialog}
                setOpenInactivateDialog={setOpenInactivateDialog}
                id={values.id}
                correlative={values.correlative}
                record_status = {values.record_status}
                pageNavigate = {'/Dashboard'}
              />
              <Box
                display="grid" 
                gap="15px"
                marginBottom="15px" 
                gridTemplateColumns="repeat(12, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile? undefined : "span 12"}
                }}
              >
                <FormControl variant="filled"
                  disabled={false}
                  sx={{ gridColumn: "span 6"}}
                  required>
                  <InputLabel id="select-company-label" name="select-company-label">Empresa</InputLabel>
                  <Select
                    labelId="select-company-label"
                    id="select-company"
                    fullWidth={!isNonMobile}
                    onBlur = {handleBlur}
                    onChange = {(e) => {
                      handleChangeRol(values,e.target);
                      setFieldValue("company_id", e.target.value);
                      getBoxPrice(values.id_box_size,values.receives_country,values,setFieldValue)
                    }}
                    value = {values.company_id}
                    name = "company_id"
                    size="small"
                  >
                    {companiesListData.map((company) => (
                      <MenuItem key={company.id} value={company.id.toString()}>
                        {company.name}
                      </MenuItem>
                    ))}
                  </Select>  
                </FormControl>
                <FormControl variant="filled"
                  disabled={false}
                  sx={{ gridColumn: "span 6"}}
                  required>
                  <InputLabel id="select-store-label" name="select-store-label">Bodega</InputLabel>
                  <Select
                    labelId="select-store-label"
                    id="select-store"
                    fullWidth={!isNonMobile}
                    onBlur = {handleBlur}
                    onChange = {(e) => {
                      handleChangeRol(values,e.target);
                      setFieldValue("id_store", e.target.value);
                    }}
                    value = {values.id_store}
                    name = "id_store"
                    size="small"
                  >
                    {storesListData.map((store) => (
                      <MenuItem key={store.id} value={store.id.toString()}>
                        {store.name}
                      </MenuItem>
                    ))}
                  </Select>  
                </FormControl>               
                <FormControl variant="filled"
                  disabled={false}
                  sx={{ gridColumn: "span 6"}}
                  required>
                  <InputLabel id="select-package_type-label" name="select-package_type-label">Tipo de carga</InputLabel>
                  <Select
                    labelId="select-package_type-label"
                    id="select-package_type"
                    fullWidth={!isNonMobile}
                    onBlur = {handleBlur}
                    onChange = {(e) => {
                      handleChangeRol(values,e.target);
                      setFieldValue("id_package_type", e.target.value);
                    }}
                    value = {values.id_package_type}
                    name = "id_package_type"
                    size="small"
                  >
                    {packageTypesListData.map((type) => (
                      <MenuItem key={type.id} value={type.id.toString()}>
                        {type.name}
                      </MenuItem>
                    ))}
                  </Select>  
                </FormControl>
              </Box>
              <Box marginBottom="20px">
                <hr width="100%" color={colors.primary[400]}></hr>
              </Box>
              <Header title="" subtitle="Envía" />
              <Box
                display="grid" 
                gap="15px"
                marginBottom="15px" 
                gridTemplateColumns="repeat(12, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile? undefined : "span 12"}
                }}
              >
                <TextField
                  //disabled={_id!==null}
                  fullWidth={!isNonMobile}
                  variant="filled"
                  type="text"
                  label="Teléfono"
                  onBlur = {handleBlur}
                  onChange = {(e) => {
                    handleChangeRol(values,e.target,setFieldValue);
                    setFieldValue("senders_phone", e.target.value);
                  }}
                  value = { values.senders_phone}
                  name = "senders_phone"
                  error = {!!touched.senders_phone && !!errors.senders_phone}
                  helperText={touched.senders_phone && errors.senders_phone}
                  size="small"
                  sx={{ gridColumn: "span 6"}}
                />
                <Box display="flex" justifyContent="begin" mt="10px" sx={{ gridColumn: "span 6"}} maxHeight="35px">
                  {_id?undefined:
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={()=>{handleGetClient(values,setFieldValue)}
                    }
                  >
                    <SearchIcon/>
                      Buscar Cliente
                    </Button>
                  }
                </Box>
                <TextField
                  disabled={false}
                  fullWidth={!isNonMobile}
                  variant="filled"
                  type="text"
                  label="Nombre"
                  onBlur = {handleBlur}
                  onChange = {(e) => {
                    handleChangeRol(values,e.target);
                    setFieldValue("senders_name", e.target.value);
                  }}
                  value = { values.senders_name}
                  name = "senders_name"
                  error = {!!touched.senders_name && !!errors.senders_name}
                  helperText={touched.senders_name && errors.senders_name}
                  size="small"
                  sx={{ gridColumn: "span 12"}}
                />
                <Box
                  sx={{ gridColumn: "span 6"}}
                >
                  <PlacesAutocomplete
                    value={values.senders_address}
                    onChange={(value) => {
                      handleChangeValue(values,'senders_address',value)
                      setFieldValue('senders_address', value);
                    }}
                    onSelect={async (value) => {
                      try {
                        handleChangeValue(values,'senders_address',value)
                        setFieldValue("senders_address", value);
                        const results = await geocodeByAddress(value);
                        if (results && results.length > 0) {
                          const result = results[0];
                          const addressDetails = {
                            zip: result.address_components.find(component => component.types.includes('postal_code')).long_name,
                            city: result.address_components.find(component => component.types.includes('locality')).long_name,
                            state: result.address_components.find(component => component.types.includes('administrative_area_level_1')).long_name,
                          };
                          handleChangeValue(values,'senders_zip',addressDetails.zip)
                          setFieldValue("senders_zip", addressDetails.zip);
                          getRoute(addressDetails.zip,setFieldValue)
                          handleChangeValue(values,'senders_city',addressDetails.city)
                          setFieldValue("senders_city", addressDetails.city);
                          handleChangeValue(values,'senders_state',addressDetails.state)
                          setFieldValue("senders_state", addressDetails.state);
                        }
                      } catch (error) {
                        console.error('Error fetching coordinates', error);
                      }
                    }}
                  >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                      <Box>
                        <Box>
                          <TextField
                            fullWidth={!isNonMobile}
                            variant="filled"
                            type="text"
                            label="Dirección"
                            onBlur={handleBlur}
                            name="senders_address"
                            error={!!touched.senders_address && !!errors.senders_address}
                            helperText={touched.senders_address && errors.senders_address}
                            size="small"
                            sx={{ width: "100%" }}
                            {...getInputProps()}
                            disabled={false}
                          />
                        </Box>
                        <Box>
                          {loading ? <Box>Loading...</Box> : null}
                          {suggestions.map((suggestion) => (
                            <Box key={suggestion.placeId} 
                            {...getSuggestionItemProps(suggestion)}
                            sx={{
                              "&:hover": {
                                backgroundColor: colors.primary[400], // Cambia el color de fondo al pasar el mouse
                              },
                            }}
                            
                            >
                              {suggestion.description}
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    )}
                  </PlacesAutocomplete>
                </Box>
                <TextField
                  disabled={false}
                  fullWidth={!isNonMobile}
                  variant="filled"
                  type="text"
                  label="Zip"
                  onBlur = {handleBlur}
                  onChange = {(e) => {
                    handleChangeRol(values,e.target);
                    setFieldValue("senders_zip", e.target.value);
                    getRoute(e.target.value,setFieldValue)
                  }}
                  value = { values.senders_zip}
                  name = "senders_zip"
                  error = {!!touched.senders_zip && !!errors.senders_zip}
                  helperText={touched.senders_zip && errors.senders_zip}
                  size="small"
                  sx={{ gridColumn: "span 6"}}
                />
                <TextField
                  disabled={false}
                  fullWidth={!isNonMobile}
                  variant="filled"
                  type="text"
                  label="Ciudad"
                  onBlur = {handleBlur}
                  onChange = {(e) => {
                    handleChangeRol(values,e.target);
                    setFieldValue("senders_city", e.target.value);
                  }}
                  value = { values.senders_city}
                  name = "senders_city"
                  error = {!!touched.senders_city && !!errors.senders_city}
                  helperText={touched.senders_city && errors.senders_city}
                  size="small"
                  sx={{ gridColumn: "span 6"}}
                />
                <TextField
                  disabled={false}
                  fullWidth={!isNonMobile}
                  variant="filled"
                  type="text"
                  label="Estado"
                  onBlur = {handleBlur}
                  onChange = {(e) => {
                    handleChangeRol(values,e.target);
                    setFieldValue("senders_state", e.target.value);
                  }}
                  value = { values.senders_state}
                  name = "senders_state"
                  error = {!!touched.senders_state && !!errors.senders_state}
                  helperText={touched.senders_state && errors.senders_state}
                  size="small"
                  sx={{ gridColumn: "span 6"}}
                />
              </Box>
              <Box marginBottom="20px">
                <hr width="100%" color={colors.primary[400]}></hr>
              </Box>
              <Header title="" subtitle="Receive" />
              <Box
                display="grid" 
                gap="15px"
                marginBottom="15px" 
                gridTemplateColumns="repeat(12, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile? undefined : "span 12"}
                }}
              >
                <TextField
                  disabled={false}
                  fullWidth={!isNonMobile}
                  variant="filled"
                  type="text"
                  label="Name"
                  onBlur = {handleBlur}
                  onChange = {(e) => {
                    handleChangeRol(values,e.target);
                    setFieldValue("receives_name", e.target.value);
                  }}
                  value = { values.receives_name}
                  name = "receives_name"
                  error = {!!touched.receives_name && !!errors.receives_name}
                  helperText={touched.receives_name && errors.receives_name}
                  size="small"
                  sx={{ gridColumn: "span 12"}}
                />
                <TextField
                  disabled={false}
                  fullWidth={!isNonMobile}
                  variant="filled"
                  type="text"
                  label="Phone"
                  onBlur = {handleBlur}
                  onChange = {(e) => {
                    handleChangeRol(values,e.target);
                    setFieldValue("receives_phone", e.target.value);
                  }}
                  value = { values.receives_phone}
                  name = "receives_phone"
                  error = {!!touched.receives_phone && !!errors.receives_phone}
                  helperText={touched.receives_phone && errors.receives_phone}
                  size="small"
                  sx={{ gridColumn: "span 6"}}
                  inputProps={{
                    maxLength: 10
                  }}
                />
                <TextField
                  disabled={false}
                  fullWidth={!isNonMobile}
                  variant="filled"
                  type="text"
                  label="Cell"
                  onBlur = {handleBlur}
                  onChange = {(e) => {
                    handleChangeRol(values,e.target);
                    setFieldValue("receives_cell", e.target.value);
                  }}
                  value = { values.receives_cell}
                  name = "receives_cell"
                  error = {!!touched.receives_cell && !!errors.receives_cell}
                  helperText={touched.receives_cell && errors.receives_cell}
                  size="small"
                  sx={{ gridColumn: "span 6"}}
                  inputProps={{
                    maxLength: 10
                  }}
                />
                <TextField
                  disabled={false}
                  fullWidth={!isNonMobile}
                  variant="filled"
                  type="text"
                  label="Address"
                  onBlur = {handleBlur}
                  onChange = {(e) => {
                    handleChangeRol(values,e.target);
                    setFieldValue("receives_address", e.target.value);
                  }}
                  value = { values.receives_address}
                  name = "receives_address"
                  error = {!!touched.receives_address && !!errors.receives_address}
                  helperText={touched.receives_address && errors.receives_address}
                  size="small"
                  sx={{ gridColumn: "span 6"}}
                  inputProps={{
                    maxLength: 512
                  }}
                />
                <TextField
                  disabled={false}
                  fullWidth={!isNonMobile}
                  variant="filled"
                  type="text"
                  label="Zip"
                  onBlur = {handleBlur}
                  onChange = {(e) => {
                    handleChangeRol(values,e.target);
                    setFieldValue("receives_zip", e.target.value);
                  }}
                  value = { values.receives_zip}
                  name = "receives_zip"
                  error = {!!touched.receives_zip && !!errors.receives_zip}
                  helperText={touched.receives_zip && errors.receives_zip}
                  size="small"
                  sx={{ gridColumn: "span 6"}}
                />
                {/* Selector de País */}
                {isCountriesLoaded && (
                <FormControl variant="filled"
                sx={{ gridColumn: "span 6"}}
                size="small"
                //required
                >
                  <InputLabel>País</InputLabel>
                  <Select
                    name="receives_country"
                    value={values.receives_country}
                    onChange={(event) => {
                      setFieldValue("receives_country", event.target.value);
                      loadStates(event.target.value);
                      setFieldValue("receives_state", ""); // Resetear el estado seleccionado
                      setFieldValue("receives_municipality", ""); // Resetear el estado seleccionado
                      setMunicipalitiesList([]); // Vaciar la lista de municipios
                      getBoxPrice(values.id_box_size,event.target.value,values,setFieldValue)
                    }}
                  >
                    {countriesListData.map((country) => (
                      <MenuItem key={country.id} value={country.id.toString()}>
                        {country.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                )}
                {/* Selector de Estado */}
                {isStatesLoaded  && (
                <FormControl variant="filled"
                sx={{ gridColumn: "span 6"}}
                size="small"
                //required
                >
                  <InputLabel>Estado</InputLabel>
                  <Select
                    name="receives_state"
                    value={values.receives_state}
                    onChange={(event) => {
                      setFieldValue("receives_state", event.target.value);
                      loadMunicipalities(event.target.value);
                      setFieldValue("receives_municipality", "");
                    }}
                    disabled={!values.receives_country}
                  >
                    {isStateListArray && statesList.map((state) => (
                      <MenuItem key={state.id} value={state.id.toString()}>
                        {state.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                )}
                {/* Selector de Municipio */}
                {isMunicipalitiesLoaded  && (
                <FormControl variant="filled"
                sx={{ gridColumn: "span 6"}}
                size="small"
                //required
                >
                  <InputLabel>Municipio</InputLabel>
                  <Select
                    name="receives_municipality"
                    value={values.receives_municipality}
                    onChange={(event) => {
                      setFieldValue("receives_municipality", event.target.value);
                    }}
                    disabled={!values.receives_state}
                  >
                    {isMunicipalitiesListArray && municipalitiesList.map((micipality) => (
                      <MenuItem key={micipality.id} value={micipality.id.toString()}>
                        {micipality.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                )}
              </Box>
              <Box marginBottom="20px">
                <hr width="100%" color={colors.primary[400]}></hr>
              </Box>
              <Header title="" subtitle="Detalles" />
              <Box
                display="grid" 
                gap="15px"
                marginBottom="15px" 
                gridTemplateColumns="repeat(12, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile? undefined : "span 12"}
                }}
              >
                <FormControl variant="filled"
                  disabled={parseInt(values.status) > 2?!permissionsListData.includes(47):false}
                  sx={{ gridColumn: "span 6"}}
                  required>
                  <InputLabel id="select-route-label" name="select-driver-label">Ruta asignada</InputLabel>
                  <Select
                    labelId="select-route-label"
                    id="select-route"
                    fullWidth={!isNonMobile}
                    onBlur = {handleBlur}
                    onChange = {(e) => {
                      handleChangeRol(values,e.target);
                      setFieldValue("origin_route_pickup", e.target.value);
                    }}
                    value = {values.origin_route_pickup||''}
                    name = "origin_route_pickup"
                    size="small"
                  >
                    {routesListData && routesListData.map((route) => (
                      <MenuItem key={route.id} value={route.id.toString()}>
                        {route.route_name}
                      </MenuItem>
                    ))}
                  </Select>  
                </FormControl>
                <TextField
                  disabled={parseInt(values.status) > 2?!permissionsListData.includes(47):false}
                  fullWidth={!isNonMobile}
                  variant="filled"
                  type="date"
                  label="Fecha Pickup"
                  onBlur = {handleBlur}
                  onChange = {(e) => {
                    handleChangeRol(values,e.target);
                    setFieldValue("pickup_date", e.target.value);
                  }}
                  value = { values.pickup_date===undefined ||values.pickup_date===null?"":getFecha(values.pickup_date)}
                  asp-format="{0:yyyy-MM-dd}"
                  name = "pickup_date"
                  error = {!!touched.pickup_date && !!errors.pickup_date}
                  helperText={touched.pickup_date && errors.pickup_date}
                  size="small"
                  sx={{ gridColumn: "span 6"}}
                />
                <FormControl variant="filled"
                  disabled={parseInt(values.status) > 2?!permissionsListData.includes(47):false}
                  sx={{ gridColumn: "span 6"}}
                  //required
                  >
                  <InputLabel id="select-box-size-label" name="select-box-size-label">Tamaño</InputLabel>
                  <Select
                    labelId="select-box-size-label"
                    id="select-box-size"
                    fullWidth={!isNonMobile}
                    onBlur = {handleBlur}
                    //displayEmpty
                    onChange = {(e) => {
                      handleChangeRol(values,e.target);
                      setFieldValue("id_box_size",e.target.value!==""?e.target.value.toString():"");
                      const selectedBox = box_size(e.target.value);

                      handleChangeValue(values,'width',selectedBox ? selectedBox.width.toString() : "")
                      setFieldValue("width", selectedBox ? selectedBox.width.toString() : "");

                      handleChangeValue(values,'height',selectedBox ? selectedBox.height.toString() : "")
                      setFieldValue("height", selectedBox ? selectedBox.height.toString() : "");

                      handleChangeValue(values,'length',selectedBox ? selectedBox.length.toString() : "")
                      setFieldValue("length", selectedBox ? selectedBox.length.toString() : "");

                      getBoxPrice(e.target.value,values.receives_country,values,setFieldValue)

                      getMaxDiscount(e.target.value)
                    }}
                    value = {values.id_box_size}
                    name = "id_box_size"
                    size="small"
                  >
                    {<MenuItem key={-1} value={-1}>
                      Otro (Ingreso Manual)
                    </MenuItem>}
                    {boxesListData.map((box) => (
                      <MenuItem key={box.id} value={box.id.toString()}>
                        {box.dimension_concatenada}
                      </MenuItem>
                    ))}
                  </Select>  
                </FormControl>
                <TextField
                  disabled={values.id_box_size!=="-1"}
                  fullWidth={!isNonMobile}
                  variant="filled"
                  type="text"
                  label="Ancho"
                  onBlur = {handleBlur}
                  onChange = {(e) => {
                    handleChangeRol(values,e.target);
                    setFieldValue("width", e.target.value);
                  }}
                  value = { values.width}
                  name = "width"
                  error = {!!touched.width && !!errors.width}
                  helperText={touched.width && errors.width}
                  size="small"
                  sx={{ gridColumn: "span 2"}}
                />
                <TextField
                  disabled={values.id_box_size!=="-1"}
                  fullWidth={!isNonMobile}
                  variant="filled"
                  type="text"
                  label="Alto"
                  onBlur = {handleBlur}
                  onChange = {(e) => {
                    handleChangeRol(values,e.target);
                    setFieldValue("height", e.target.value);
                  }}
                  value = { values.height}
                  name = "height"
                  error = {!!touched.height && !!errors.height}
                  helperText={touched.height && errors.height}
                  size="small"
                  sx={{ gridColumn: "span 2"}}
                />
                <TextField
                  disabled={values.id_box_size!=="-1"}
                  fullWidth={!isNonMobile}
                  variant="filled"
                  type="text"
                  label="Largo"
                  onBlur = {handleBlur}
                  onChange = {(e) => {
                    handleChangeRol(values,e.target);
                    setFieldValue("length", e.target.value);
                  }}
                  value = { values.length}
                  name = "length"
                  error = {!!touched.length && !!errors.length}
                  helperText={touched.length && errors.length}
                  size="small"
                  sx={{ gridColumn: "span 2"}}
                />
                <TextField
                  disabled={false}
                  fullWidth={!isNonMobile}
                  variant="filled"
                  type="text"
                  label="Peso"
                  onBlur = {handleBlur}
                  onChange = {(e) => {
                    handleChangeRol(values,e.target);
                    setFieldValue("weight", e.target.value);
                  }}
                  value = { values.weight}
                  name = "weight"
                  error = {!!touched.weight && !!errors.weight}
                  helperText={touched.weight && errors.weight}
                  size="small"
                  sx={{ gridColumn: "span 6"}}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        lb
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  disabled={parseInt(values.status) > 2?!permissionsListData.includes(47):false}
                  fullWidth={!isNonMobile}
                  variant="filled"
                  type="text"
                  label="Precio"
                  onBlur = {handleBlur}
                  onChange = {(e) => {
                    handleChangeRol(values,e.target);
                    setFieldValue("approximate_cost", e.target.value);
                  }}
                  value = { values.approximate_cost}
                  name = "approximate_cost"
                  error = {!!touched.approximate_cost && !!errors.approximate_cost}
                  helperText={touched.approximate_cost && errors.approximate_cost}
                  size="small"
                  sx={{ gridColumn: "span 6"}}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        $
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  disabled={parseInt(values.status) > 2?!permissionsListData.includes(47):false}
                  fullWidth={!isNonMobile}
                  variant="filled"
                  type="text"
                  label="Seguro"
                  onBlur = {handleBlur}
                  onChange = {(e) => {
                    handleChangeRol(values,e.target);
                    setFieldValue("insurance_cost", e.target.value);
                  }}
                  value = { values.insurance_cost}
                  name = "insurance_cost"
                  error = {!!touched.insurance_cost && !!errors.insurance_cost}
                  helperText={touched.insurance_cost && errors.insurance_cost}
                  size="small"
                  sx={{ gridColumn: "span 6"}}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        $
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  disabled={parseInt(values.status) > 2?!permissionsListData.includes(47):false}
                  fullWidth={!isNonMobile}
                  variant="filled"
                  type="text"
                  label="Descuento"
                  onBlur = {handleBlur}
                  onChange = {(e) => {
                    handleChangeRol(values,e.target);
                    setFieldValue("discount", e.target.value);
                  }}
                  value = { values.discount}
                  name = "discount"
                  error = {!!touched.discount && !!errors.discount}
                  helperText={touched.discount && errors.discount}
                  size="small"
                  sx={{ gridColumn: "span 6"}}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        $
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  disabled={false}
                  fullWidth={!isNonMobile}
                  variant="filled"
                  type="text"
                  label="Cobro Extra"
                  onBlur = {handleBlur}
                  onChange = {(e) => {
                    handleChangeRol(values,e.target);
                    setFieldValue("extra_payment", e.target.value);
                  }}
                  value = { values.extra_payment}
                  name = "extra_payment"
                  error = {!!touched.extra_payment && !!errors.extra_payment}
                  helperText={touched.extra_payment && errors.extra_payment}
                  size="small"
                  sx={{ gridColumn: "span 6"}}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        $
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  disabled={false}
                  fullWidth={!isNonMobile}
                  variant="filled"
                  type="text"
                  label="Descripción cobro exta"
                  onBlur = {handleBlur}
                  onChange = {(e) => {
                    handleChangeRol(values,e.target);
                    setFieldValue("extra_payment_description", e.target.value);
                  }}
                  value = { values.extra_payment_description}
                  name = "extra_payment_description"
                  error = {!!touched.extra_payment_description && !!errors.extra_payment_description }
                  helperText={touched.extra_payment_description && errors.extra_payment_description }
                  size="small"
                  sx={{ gridColumn: "span 6"}}
                />
                <TextField
                  disabled={true}
                  fullWidth={!isNonMobile}
                  variant="filled"
                  type="text"
                  label="Total Delivery (Depósito)"
                  onBlur = {handleBlur}
                  onChange = {(e) => {
                    handleChangeRol(values,e.target);
                    setFieldValue("delivery_cost", e.target.value);
                  }}
                  value = { values.delivery_cost}
                  name = "delivery_cost"
                  //error = {!!touched.delivery_cost && !!errors.delivery_cost}
                  //helperText={touched.delivery_cost && errors.delivery_cost}
                  size="small"
                  sx={{ gridColumn: "span 12"}}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        $
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  disabled={true}
                  fullWidth={!isNonMobile}
                  variant="filled"
                  type="text"
                  label="Pagado hasta el momento"
                  onBlur = {handleBlur}
                  //onChange = {handleChange}
                  value = { 
                    parseFloat(values.payed?values.payed:0)
                  }
                  name = "payed"
                  size="small"
                  sx={{ gridColumn: "span 4"}}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        $
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  disabled={true}
                  fullWidth={!isNonMobile}
                  variant="filled"
                  type="text"
                  label="Por pagar (Faltante)"
                  onBlur = {handleBlur}
                  //onChange = {handleChange}
                  value = { 
                    parseFloat(values.approximate_cost?values.approximate_cost:0)
                    +parseFloat(values.insurance_cost?values.insurance_cost:0)
                    +parseFloat(values.extra_payment?values.extra_payment:0)
                    -parseFloat(values.discount?values.discount:0)
                    -parseFloat(values.payed?values.payed:0)
                  }
                  name = "payed"
                  size="small"
                  sx={{ gridColumn: "span 4"}}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        $
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  disabled={true}
                  fullWidth={!isNonMobile}
                  variant="filled"
                  type="text"
                  label="Total"
                  onBlur = {handleBlur}
                  //onChange = {handleChange}
                  value = { 
                    parseFloat(values.approximate_cost?values.approximate_cost:0)
                    +parseFloat(values.insurance_cost?values.insurance_cost:0)
                    +parseFloat(values.extra_payment?values.extra_payment:0)
                    -parseFloat(values.discount?values.discount:0)
                  }
                  name = "total_cost"
                  size="small"
                  sx={{ gridColumn: "span 4"}}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        $
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  disabled={false}
                  fullWidth={!isNonMobile}
                  variant="filled"
                  type="text"
                  label="Notas"
                  onBlur = {handleBlur}
                  onChange = {(e) => {
                    handleChangeRol(values,e.target);
                    setFieldValue("notes", e.target.value);
                  }}
                  value = { values.notes}
                  name = "notes"
                  error = {!!touched.notes && !!errors.notes}
                  helperText={touched.notes && errors.notes}
                  size="small"
                  sx={{ gridColumn: "span 12"}}
                  inputProps={{
                    maxLength: 512
                  }}
                />
                <TextField
                  disabled={false}
                  fullWidth={!isNonMobile}
                  variant="filled"
                  type="text"
                  label="Contenido"
                  onBlur = {handleBlur}
                  onChange = {(e) => {
                    handleChangeRol(values,e.target);
                    setFieldValue("content_description", e.target.value);
                  }}
                  value = { values.content_description}
                  name = "content_description"
                  error = {!!touched.content_description && !!errors.content_description}
                  helperText={touched.content_description && errors.content_description}
                  size="small"
                  sx={{ gridColumn: "span 12"}}
                  inputProps={{
                    maxLength: 512
                  }}
                />
              </Box>
              <Box marginBottom="20px">
                <hr width="100%" color={colors.primary[400]}></hr>
              </Box>
              <Box display="flex" justifyContent="end" mt="20px">
                  {!_id?
                    (permissionsListData.includes(11)?
                      <Button type="submit" color="secondary" variant="contained">
                        Crear Nuevo Pickup
                      </Button>:undefined
                    ):
                    <Box >
                      {initialValues.record_status==='ACTIVO'? 
                      (values.status==='1' && permissionsListData.includes(11)?
                        <Button sx={{marginRight: "10px"}} type="submit" color="secondary" variant="contained">
                          <SaveIcon/>
                          Crear Pickup
                        </Button>:
                        (permissionsListData.includes(29)?
                        <Button sx={{marginRight: "10px"}} type="submit" color="secondary" variant="contained">
                          <SaveIcon/>
                          Modificar Pickup
                        </Button>:undefined)                      
                      )
                      :undefined}                      
                      {permissionsListData.includes(30)?
                        (
                          initialValues.record_status&&initialValues.record_status==='ACTIVO'?
                          <Button sx={{marginRight: "10px"}} color="error" variant="contained" onClick={()=>{setOpenInactivateDialog(true)}}>
                            <DisabledByDefaultIcon/>
                            Inactivar Registro
                          </Button>:
                          <Button sx={{marginRight: "10px"}} color="warning" variant="contained" onClick={()=>{setOpenInactivateDialog(true)}}>
                            <CheckBoxIcon/>
                            Reactivar Registro
                          </Button>
                        )
                      :undefined}
                      {initialValues.status&&initialValues.status!=='1'?<Button sx={{marginRight: "10px"}} color="secondary" variant="contained" onClick={()=>{getStickerData(values.id)}}>
                        <SaveAltIcon/>
                        Descargar Etiqueta
                      </Button>:undefined}
                      {initialValues.status !== '8'?undefined:
                      initialValues.ends_with_balance&&initialValues.ends_with_balance==='N'?
                      <Button sx={{marginRight: "10px"}} color="warning" variant="contained" onClick={()=>{balancePayment(values)}}>
                        <PublishedWithChangesIcon/>
                        Permitir entrega con saldo
                      </Button>:
                      <Button sx={{marginRight: "10px"}} color="warning" variant="contained" onClick={()=>{balancePayment(values)}}>
                        <UnpublishedIcon/>
                        Restringir entrega con saldo
                      </Button>}                    
                    </Box>}                                
              </Box>                
          </form>
        )}
      </Formik>
    </Box>
  );
}

export default PickupForm;