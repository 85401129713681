import { Box,Button, useMediaQuery} from "@mui/material";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { getReceipDataRoute } from "../../../../config";
import FatalError from "../../../global/FatalError";
import Loading from "../../../global/Loading";
import swal from "sweetalert";
import { format } from 'date-fns';
import React from 'react';
import { useLocation } from "react-router-dom";


const Receipts = () => {
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const _id = query.get('id')
  const isNonMobile = useMediaQuery("(min-width:600px)")

  const [ loadingRequest, setLoadingRequest ] = useState(false)
  const [ errorRequest, setErrorRequest ] = useState(null)


  const getBoxTracking = async(payment_id) =>{
    if(payment_id && payment_id !== "" ){
      try{
        setLoadingRequest(true)
        //console.log('id_box',id_box)
        let res = await fetch(getReceipDataRoute(payment_id), {
          method: "GET",
          headers: {
            //"Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Accept":"application/json",
            //"Authorization": `Bearer ${token}`
            //"x-access-token": token,
          },
          //cors: 'no-cors'
        })
        let data = await res.json()
        console.log('Receipt',data)
        if(res.ok){
          setRecord(data)
        }else{
          //console.log('res',res)
          if(res.status === 404){
            mostrarAlertRequest("Recibo no encontrado")
            setRecord(null)
          }/*
          if(res.status === 401||res.status === 403){
            mostrarErrorRequest("No tiene autorización o su sesión ha expirado")          
          }*/
        }
        setLoadingRequest(false)
        return data;
      }catch(errorRequest){
        setLoadingRequest(false)
        setErrorRequest(errorRequest)
      }
    }
  }

  const [record, setRecord] = useState(null);

  const mostrarAlertRequest=(info)=>{
    if(info!=null){
      try{
        swal({
          title:"Alerta",
          text: info,
          icon: "warning",
          button:"Aceptar"
        })
        
      }catch(e){
        swal({
          title:"Error",
          text: "Error inesperado ",
          icon: "error",
          button:"Aceptar"
        })
      }
    }
  }

  const searchFormik = useFormik({
    initialValues: {
      recordId: '',
    },
    onSubmit: (values) => {
      //console.log(values)
      getBoxTracking(values.recordId)
    },
  });

  const handleInitialSearch = () => {
    if (_id) {
      searchFormik.setFieldValue('recordId', _id, false);
      searchFormik.submitForm();
    }
  };

  useEffect(() => {
    handleInitialSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_id]);

  const crear_detalle_pago = (detalle) => {
    let claves = "";
    let valores = "";
    let lista2 = [];

    detalle.forEach(item => {
      if (parseFloat(item.ammount) > 0) {
        lista2.push(item);
        if (item.payment_method === "Tarjeta") {
          claves += `<th>Zelle</th>`;
        } else {
          claves += `<th>${item.payment_method}</th>`;
        }
        valores += `<td>${parseFloat(item.ammount).toFixed(2)}</td>`;
      }
    });

    return `
      <tr>
      <th colspan="${lista2.length}">Detalle del pago</th>
      </tr>    
      <tr>
      ${claves}
      </tr>
      <tr>
      ${valores}
      </tr>
    `;
  };

  function formatDate(dateField) {
    if (!dateField) return '';  // Si el campo de fecha es null o undefined, devuelve una cadena vacía
    const date = new Date(dateField);
    return isNaN(date) ? '' : format(date, 'dd-M-yyyy');
  }

  function formatNumberString(numberString) {
    if (numberString.length !== 10) {
      return numberString;
    }  
    let formattedString = `(${numberString.substring(0, 3)})${numberString.substring(3, 6)}-${numberString.substring(6)}`;
    return formattedString;
  }

  const generateReceiptHtml = (record) => {

    if(record.box_status!==1){
      return `
        <!DOCTYPE html>
        <html lang="en">
        <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Receip</title>
          <style>
            .receipt-body {
              background-color: white;
              color: black;
              padding: 5px;
              font-family: Arial, sans-serif;
            }
            @page {
              padding: 0;
              size: A4;
              font-size: 10px;
              @bottom-center {
                content: "";
              }
            }
            .withborder tr td,.withborder tr th, .withborder{
              border: 1px solid black;
              border-collapse: collapse;
              padding: 5px;
              text-align: left;
            }
            #first-table tr td, #first-table{
              text-align: center;
            }
            table{
              width: 100%;
            }
            p{
              font-size: 9px;
            }
            img{
              width: 150px;
            }
            span{
              font-size: 10px;
              font-weight: bold;
            }
            .section {
              margin-top: 10px;
              display: flex;
            }
            .subsection {
              margin: 0 5px;
              text-align: justify;
            }
            .subsection:first-child {
              width:80%;
            }
            .subsection-table {
              margin-left: auto;
              width:30%;
              margin-top:10px;
              margin-bottom: 15px;
              font-size:12px;
            }
          </style>
        </head>
        <body class="receip-content">
        <div class="receipt-body">
          <section>
            <table id="first-table">
              <tr>
                <td><img width="150px" src="${record.company_logo}" alt="logo empresa"></td>
                <td>${record.company}<br>${record.company_address}<br>${record.company_phone}</td>
                <td><br><img width="150px" src="${record.barcode}" alt="codigo de barras"></td>
              </tr>
            </table>
          </section>
          <span>As agent of INTERPORT LINE LIC # 003172NF</span>
          <section>
            <table class="withborder">
              <tr>
                <th colspan="4">Remitente</th>
                <th colspan="4">Destinatario (Fecha de pickup: ${formatDate(record.pickup_date)})</th>
              </tr>
              <tr>
                <td colspan="4">${record.sender_name}<br>${record.sender_address},${record.sender_zip}<br> Tel. ${record.sender_phone}</td>
                <td colspan="4">${record.receiver_name}<br>${record.receiver_address}, ${record.receiver_municipality}, ${record.receiver_state}, ${record.receiver_country}<br>Tel. ${record.receiver_phone}</td>
              </tr>
              <tr>
                <th>Lbs</th>
                <th>Size</th>
                <th>Producto</th>
                <th>Guía</th>
                <th colspan="4">Notas</th>
              </tr>
              <tr>
                <td>${record.weight?record.weight:''}</td>
                <td>${record.size}</td>
                <td>${record.package_type}</td>
                <td>${record.correlative}</td>
                <td colspan="4">${record.notes}</td>
              </tr>
            </table>
          </section>
          <section>
            <table class="withborder">
              ${crear_detalle_pago(record.details)}
            </table>
          </section>
          <section class="section">
            <div class="subsection">
              <p>Restricciones y limitaciones de responsabilidad: El remitente declara que no está enviando: dinero, armas,
              drogas, explosivos, líquidos, químicos, inflamables, joyas, pornografía, no más de 5 perfumes, medicamento
              recetado, documentos legales, mercadería con propósito comercial y/o ningún otro artículo prohibido por el
              país de destino. Tres (3) electrónicos máximo por envío. LIMITACIONES DE RESPONSABILIDAD
              ${record.company.toUpperCase()} No es responsable por atrasos ocasionados por huelgas, cuarentenas,
              manifestaciones, fenómenos naturales, detenciones de aduana, desperfectos mecánicos, fallas eléctricas y/u
              otra situación fuera de nuestro alcance. ${record.company.toUpperCase()} Ofrece un seguro adicional para los
              envíos, el cual se puede obtener pagando el 5% sobre el valor declarado con Máximo de DOS MIL
              DOLARES demostrados con recibos de compra después de los USD $500.00, en ninguno de los casos el
              valor del flete será reembolsable en efectivo, sino que se dará en forma de crédito para otro envío. Si el
              envío no cuenta con seguro adicional, ${record.company.toUpperCase()} Responderá con un seguro automático de
              hasta $500.00 DOLARES por paquete de pérdida total. En caso de reexpedición de un paquete, habrá un
              costo extra de acuerdo con el peso de este, el servicio a Domicilio no se garantiza al 100% por favor
              llámenos al +1${formatNumberString(record.company_phone)}. Impuestos: Estos se aplicarán a los destinos que se requieran. Reclamos: El
              remitente cuenta con 5 días, después de haber recibido el paquete en el destino final para hacer cualquier
              notificación. *Declino a no aceptar el seguro ofrecido por mi mercadería enviada (__). El cliente se hace
              responsable de empacar adecuadamente sus pertenencias y de proporcionar información correcta,
              ${record.company.toUpperCase()} no se hace responsable por artículos mal empacados dentro del empaque.
              (English version are available, when the customer made the request)</p>
            </div>
            <div class="subsection">
              <table class="subsection-table withborder">
                <tr>
                  <th>Detalle</th>
                  <th>Valor</th>
                </tr>
                <tr>
                  <th>Flete</th>
                  <td>${record.approximate_cost}</td>
                </tr>
                <tr>
                  <th>Seguro</th>
                  <td>${record.insurance_cost}</td>
                </tr>
                <tr>
                  <th>Cargos extra</th>
                  <td>${record.extra_payment}</td>
                </tr>
                <tr>
                  <th>Depósito</th>
                  <td>${record.delivery_cost}</td>
                </tr>
                <tr>
                  <th>Descuento</th>
                  <td>${record.discount}</td>
                </tr>
                <tr>
                  <th>Total</th>
                  <td>${record.approximate_cost + record.insurance_cost + record.extra_payment - record.discount }</td>
                </tr>
                <tr>
                  <th>Saldo</th>
                  <td>${record.saldo}</td>
                </tr>
              </table>
            </div>
          </section>
          <section>
            <table style="width: 100%; border:none;">
              <tr>
                <td></td>
                <td></td>
                <td>
                  <img src="${record.payment_signature}" alt="">
                </td>
              </tr>
              <tr>
                <td class="signature-field">
                  <br>
                  Fecha
                </td>
                <td class="signature-field">
                  <br>
                  Driver: ${record.driver}
                </td>
                <td class="signature-field">
                  <br>
                  Firma del cliente
                </td>
              </tr>
            </table>
          </section>
        </div>
        </body>
        </html>
      `;
    }else{
      return `
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <style>
          .receipt-body {
            background-color: white;
            color: black;
            padding: 5px;
            font-family: Arial, sans-serif;
          }
          @page {
            padding: 0;
            size: A4;
            font-size: 10px;
            @bottom-center {
              content: "";
            }
          }
          .withborder tr td,.withborder tr th, .withborder{
            border: 1px solid black;
            border-collapse: collapse;
            padding: 5px;
            text-align: left;
          }
          #first-table tr td, #first-table{
            text-align: center;
          }
          table{
            width: 100%;
          }
          p{
            font-size: 9px;
          }
          img{
            width: 150px;
          }
          span{
            font-size: 10px;
            font-weight: bold;
          }
          .section {
            margin-top: 10px;
            display: flex;
          }
          .subsection {
            margin: 0 5px;
            text-align: justify;
          }
          .subsection:first-child {
            width:80%;
          }
          .subsection-table {
            margin-left: auto;
            width:30%;
            margin-top:10px;
            margin-bottom: 15px;
            font-size:12px;
          }
          .signatures{
            margin-top: 40px;
          }
          .signatures td{
            border-top: 1px solid #000;
          }
          .space{
            border-top: none!important;
          }
        </style>
      </head>
      <body>
      <div class="receipt-body">
        <section>
          <table id="first-table">
            <tr>
              <td rowspan="3">
                <img width="300px" src="${record.company_logo}" alt="">
              </td>
              <td>${record.company}</td>
              <td rowspan="3">Orden No. ${record.correlative}<br></td>
            </tr>
            <tr>
              <td>${record.company_address}</td>
              <td rowspan="2"></td>
            </tr>
            <tr>
              <td>${record.company_phone}</td>
            </tr>
          </table>
        </section>
        <section>
          <table class="withborder">
            <tr>
              <th colspan="4">Remitente</th>
              <th colspan="4">Destinatario</th>
            </tr>
            <tr>
              <td colspan="4">${record.sender_name}<br>${record.sender_address},${record.sender_zip}<br>Tel.${record.sender_phone}</td>
              <td colspan="4">${record.receiver_name?record.receiver_name:''}<br>${record.receiver_address?(record.receiver_address + ','):''} ${record.receiver_municipality?(record.receiver_municipality + ','):''} ${record.receiver_state?(record.receiver_state + ','):''} ${record.receiver_country}</td>
            </tr>
            <tr>
              <th colspan="2">Depósito</th>
              <th colspan="2">Producto</th>
              <th colspan="2">Piezas</th>
              <th colspan="2">Fecha de llevada</th>
            </tr>
            <tr>
              <td colspan="2">${record.delivery_cost}</td>
              <td colspan="2">${record.package_type}</td>
              <td colspan="2">1</td>
              <td colspan="2">${formatDate(record.delivery_date)}</td>
            </tr>
            <tr>
              <th>Lbs</th>
              <th colspan="2">Size</th>
              <th>Guía</th>
              <th colspan="4">Notas</th>
            </tr>
            <tr>
              <td>${record.weight?record.weight:''}</td>
              <td colspan="2">${record.size}</td>
              <td>${record.correlative}</td>
              <td colspan="4">${record.notes}</td>
            </tr>
            <tr>
              <td></td>
              <th colspan="2">Precio aproximado:</th>
              <td>${record.approximate_cost}</td>
            </tr>
          </table>
        </section>
        <section>
          <table class="withborder">
            ${crear_detalle_pago(record.details)}
          </table>
        </section>
        <section>
          <table class="signatures">
            <tr>
              <td width="33%">${record.driver}</td>
              <td width="33%"class="space"></td>
              <td width="33%">Firma del cliente</td>
            </tr>
          </table>
        </section>
      </div>
      </body>
      </html>
      `;
    }
  };


  /*const printReceipt = () => {
    const printWindow = window.open();
    printWindow.document.write(`<html><head><title>Recibo `+record.correlative+`</title>      <script>
        function printAndClose() {
          window.print();
        }
        window.onload = printAndClose;
      </script>`);
    printWindow.document.write('</head><body>');
    printWindow.document.write(document.getElementById('receipt-html').innerHTML);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
  };*/
  /*const printReceipt = () => {
    const receiptHtml = generateReceiptHtml(record);
    const blob = new Blob([receiptHtml], { type: 'text/html' });
    const url = URL.createObjectURL(blob);
    const printWindow = window.open(url, '_blank');
    
    printWindow.onload = () => {
      printWindow.print();
      URL.revokeObjectURL(url);
    };
  };*/


  const printReceipt = () => {
    const receiptHtml = generateReceiptHtml(record);
    const iframe = document.createElement('iframe');
  
    iframe.style.position = 'absolute';
    iframe.style.width = '0px';
    iframe.style.height = '0px';
    iframe.style.border = 'none';
  
    document.body.appendChild(iframe);
  
    const doc = iframe.contentWindow.document.open();
    doc.write(receiptHtml);
    doc.close();
  
    iframe.contentWindow.focus();
    iframe.contentWindow.print();
  
    document.body.removeChild(iframe);
  };


  if (loadingRequest) return (<Loading />);
  if (errorRequest) return (<FatalError />);

  return (
    <Box>
      {record && <div id="receipt-html" dangerouslySetInnerHTML={{ __html: generateReceiptHtml(record) }} />}
      <Box
        display="flex"
        flexDirection={isNonMobile ? 'row' : 'column'}
        justifyContent="center"
        mt="5px"
        mb="40px"
        gap={1}
      >
        {record && <Button onClick={printReceipt} variant="contained" color="secondary">Imprimir</Button>}
      </Box>
    </Box>
  );
}

export default Receipts;