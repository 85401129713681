import { Box,useTheme,TextField,Button} from "@mui/material";
//import { useNavigate } from "react-router-dom";
import { DataGrid, esES } from "@mui/x-data-grid";
import useAuth from "../../../../auth/useAuth";
import Header from "../../../../components/Header";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { tokens } from "../../../../theme";
import { deletePaymentRoute, getBoxPaymentsHistoryRoute } from "../../../../config";
import FatalError from "../../../global/FatalError";
import Loading from "../../../global/Loading";
import swal from "sweetalert";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import { format } from 'date-fns';
import React from 'react';
import { useLocation/*, useNavigate*/ } from "react-router-dom";

const VerPagos = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode)
  const auth = useAuth();
  const token = auth.globals.token
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const _id = query.get('id')
  //const navigate = useNavigate();

  const [ loadingRequest, setLoadingRequest ] = useState(false)
  const [ errorRequest, setErrorRequest ] = useState(null)

  const getBoxPaymentHistory = async(id_box) =>{
    if(id_box && id_box !== "" ){
      try{
        setLoadingRequest(true)
        //console.log('id_box',id_box)
        let res = await fetch(getBoxPaymentsHistoryRoute(id_box), {
          method: "GET",
          headers: {
            //"Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Accept":"application/json",
            "Authorization": `Bearer ${token}`
            //"x-access-token": token,
          },
          //cors: 'no-cors'
        })
        let data = await res.json()
        console.log('History',data)
        if(res.ok){
          setRecord(data)
        }else{
          //console.log('res',res)
          if(res.status === 404){
            mostrarAlertRequest("No existen pagos asociados al código ingresado")
            setRecord(null)
          }
          if(res.status === 401||res.status === 403){
            mostrarErrorRequest("No tiene autorización o su sesión ha expirado")          
          }  
        }
        setLoadingRequest(false)
        return data;
      }catch(errorRequest){
        setLoadingRequest(false)
        setErrorRequest(errorRequest)
      }
    }
  }

  const deletePayment = async(id_payment) =>{
    try{
      setLoadingRequest(true)
      //console.log('id_payment',id_payment)
      let res = await fetch(deletePaymentRoute(id_payment), {
        method: "DELETE",
        headers: {
          //"Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Accept":"application/json",
          "Authorization": `Bearer ${token}`
          //"x-access-token": token,
        },
        //cors: 'no-cors'
      })
      let data = await res.json()
      //console.log('History',data)
      if(res.ok){
        //console.log(record)
        getBoxPaymentHistory(searchFormik.values.recordId)
        alertSucess('Pago Eliminado','Pago eliminado con exito')
      }else{
        //console.log('res',res)
        if(res.status === 404){
          mostrarErrorRequest("Pago no encontrado")
        }
        if(res.status === 401||res.status === 403){
          mostrarErrorRequest("No tiene autorización o su sesión ha expirado")          
        }  
      }
      setLoadingRequest(false)
      return data;
    }catch(errorRequest){
      setLoadingRequest(false)
      setErrorRequest(errorRequest)
    }
  }

  const [record, setRecord] = useState(null);

  const searchFormik = useFormik({
      initialValues: {
        recordId: '',
      },
      onSubmit: (values) => {
        //console.log(values)
        getBoxPaymentHistory(values.recordId)
      },
  });

  // Función para manejar la búsqueda inicial
  const handleInitialSearch = () => {
    if (_id) {
      searchFormik.setFieldValue('recordId', _id, false);
      searchFormik.submitForm();
    }
  };

  useEffect(() => {
    handleInitialSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_id]);

  const mostrarErrorRequest=(error)=>{
    if(error!=null){
      try{
        swal({
          title:"Error",
          text: "Resultado: "+error,
          icon: "error",
          button:"Aceptar"
        })
        if(error==="No tiene autorización o su sesión ha expirado"){
          auth.logout();
        }
      }catch(e){
        swal({
          title:"Error",
          text: "Error inesperado ",
          icon: "error",
          button:"Aceptar"
        })
      }
    }
    setErrorRequest(null)
  }

  const mostrarAlertRequest=(info)=>{
    if(info!=null){
      try{
        swal({
          title:"Alerta",
          text: info,
          icon: "warning",
          button:"Aceptar"
        })
        
      }catch(e){
        swal({
          title:"Error",
          text: "Error inesperado ",
          icon: "error",
          button:"Aceptar"
        })
      }
    }
  }

  /*const mostrarAlertRequest=(info)=>{
    if(info!=null){
      try{
        swal({
          title:"Alerta",
          text: info,
          icon: "warning",
          button:"Aceptar"
        })
        
      }catch(e){
        swal({
          title:"Error",
          text: "Error inesperado ",
          icon: "error",
          button:"Aceptar"
        })
      }
    }
  }*/

  const alertSucess=(text_title,text_content='')=>{    
    try{
      swal({
        title:text_title,
        text: text_content,
        icon: "success",
        button:"Aceptar"
      })
    }catch(e){
      swal({
        title:"Error inesperado",
        text: "Error inesperado ",
        icon: "error",
        button:"Aceptar"
      })
    }
    //navigate("/Dashboard")
  }


  const customLocaleText = {
    ...esES.components.MuiDataGrid.defaultProps.localeText,
    noRowsLabel: 'No hay se encontraron resultados...',
  };

  const formatDateTime = (value) => {
    const date = new Date(value);
    return isNaN(date) ? "" : format(date, 'yyyy-MM-dd');
  };


  const columns=[
    {
      field:"id", 
      headerName: "ID",
      cellClassName: "id-column--cell",
      headerClassName: "id-header--cell",
      hideable:false
    },
    {
      flex:0.75,
      //hide: true,
      field:"payment", 
      headerName: "Valor pagado",
      cellClassName: "payment-column--cell",
      minWidth:85,
    },
    {
      flex:1.5,
      //hide: true,
      field:"comment", 
      headerName: "Comentario",
      cellClassName: "comment-column--cell",
      minWidth:100
    },
    {
      flex:1,
      //hide: true,
      field:"name", 
      headerName: "Estado caja",
      cellClassName: "name-column--cell",
      minWidth:100
    },
    {
      flex:1,
      //hide: true,
      field:"create_user", 
      headerName: "Usuario Registra pago",
      cellClassName: "create_user-column--cell",
      minWidth:100
    },
    {
      flex:0.75,
      //hide: true,
      field:"create_date", 
      headerName: "Fecha Pago",
      minWidth:100,
      renderCell: ({ row: { create_date} }) => formatDateTime(create_date)
    },
    {
      flex:1,
      minWidth:110,
      filterable:false,
      sortable:false,
      field:"url_receipt", 
      headerName: "Documentos",
      headerAlign:"center",
      cellClassName: "url_receipt-column--cell",
      renderCell:({ row: { id} })=>{
        return(
          (
          <Box
            width="95%"
            maxWidth="110px"
            height="75%"
            maxHeight="35px" 
            display="flex" 
            justifyContent="center" 
            m="0 auto">
            <Button
              color="secondary"
              variant="contained"
              onClick={()=>{
                window.open("/Receip?id=" + id, "_blank");
              }}
            >
            <ReceiptLongIcon/>
              <Box sx={{ml:"5px"}}>
                Ver recibo
              </Box>
            </Button>
          </Box>)
        )
      }
    },
    {
      flex:1,
      minWidth:100,
      field:"delete", 
      headerName: "Acciones",
      headerAlign:"center",
      cellClassName: "delete-column--cell",
      renderCell:({ row: {id} })=>{
        return(
          <Box
            width="95%"
            maxWidth="170px"
            height="75%"
            maxHeight="35px" 
            display="flex" 
            justifyContent="center" 
            m="0 auto">
            {<Button
              color="error"
              variant="contained"
              onClick={()=>{deletePayment(id)}}>
            <DeleteSweepIcon/>
              <Box sx={{ml:"5px"}}>
                Eliminar Pago
              </Box>
            </Button>}
          </Box>
        )
      }
    },
  ]

  if(loadingRequest)
  return(<Loading/>)

  if(errorRequest)
  return(<FatalError/>)


  return (
    <Box m="20px" marginBottom="64px">
      {/*<Box display="flex" justifyContent="space-between" alignItems="center">*/}
        <Header title="Ver pagos" subtitle="Ingrese la caja a buscar"/>
        <form onSubmit={searchFormik.handleSubmit}>
          <Box 
            sx={{ 
              display: 'flex', 
              justifyContent: 'left', 
              alignItems: 'center', 
              gap: 2,  // Espacio entre los elementos
              padding: 1 // Espacio alrededor del contenido
            }}
          >
            <TextField
              variant="filled"
              label="Código de caja"
              name="recordId"
              value={searchFormik.values.recordId}
              onChange={searchFormik.handleChange}
              size="small"
            />
            <Button type="submit" variant="contained" color="secondary">
              Buscar
            </Button>
          </Box>
        </form>

      {record && (
        <Box m="5px 0px 50px 0" /*height={isNonMobile?"100%":undefined}*/ sx={{
          "& .MuiDataGrid-root":{
            border: "none",
          },
          "& .MuiDataGrid-cell":{
            borderBottom: "none"
          },
          "& .MuiDataGrid-columnHeaders":{
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            color: "white"
          },
          "& .MuiDataGrid-virtualScroller":{
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer":{
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text":{
            color: `${colors.grey[100]} !important`,
          },
          "& .MuiToolbar-gutters":{
            color: "white"
          }

        }}>
          <DataGrid
            autoHeight
            initialState={{
              columns: {
                columnVisibilityModel: {
                  id: false,
                },
              },
            }}
            localeText={customLocaleText}
            rows={record}
            columns={columns}
            getRowId={(row) => row.id}
            density="compact"
            /*slots={{
              toolbar: GridToolbar,
            }}*/
          />
        </Box>
      )}
    </Box>
  );
}

export default VerPagos;