import { Box,/*,useTheme*/Button,TextField,useMediaQuery,FormControl,InputLabel,MenuItem} from "@mui/material";
import Select from '@mui/material/Select';
//import { useNavigate } from "react-router-dom";
import useAuth from "../../../../auth/useAuth";
import { useState } from "react";
import { useFormik } from "formik";
//import { tokens } from "../../../../theme";
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import CodeIcon from '@mui/icons-material/Code';
import ContainersDialogSelector from "./ContainersDialogSelector";
import swal from "sweetalert";
import { getAllContractsDataRoute, getContainerReportRoute } from "../../../../config";
import FatalError from "../../../global/FatalError";
import Loading from "../../../global/Loading";
import { generateMultipleContractsHTML } from "./HTMLGenerator/ContractGenerator";

const LoadingReport = () => {
  //const theme = useTheme();
  //const colors = tokens(theme.palette.mode)
  const auth = useAuth();
  const token = auth.globals.token
  const isNonMobile = useMediaQuery("(min-width:600px)")
  const [openDialog, setOpenDialog] = useState(false);

  const [ loadingRequest, setLoadingRequest ] = useState(false)
  const [ errorRequest, setErrorRequest ] = useState(null)

  const balanceOptions = [
    { id: 'todos', name: 'TODOS' },
    { id: 'con_saldo', name: 'Saldo Pendiente' },
    { id: 'sin_saldo', name: 'Sin Saldo Pendiente' }
  ];

  const [actionType, setActionType] = useState("");

  const getLocalDate = () => {
    return new Intl.DateTimeFormat('en-CA', { // 'en-CA' utiliza el formato YYYY-MM-DD
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }).format(new Date());
  };

  const today = getLocalDate()

  const reportFormik = useFormik({
    initialValues: {
        initialDate: today,
        finalDate: today,
        balance: balanceOptions[0].id,
        id_container: ''
    },
    onSubmit: (values) => {
      //console.log(values)
      if(!values.id_container){
        mostrarAlertRequest('Por favor seleccione un contenedor valido')
        return;
      }
      switch(actionType) {
        case "HTML":
          getContainerReport(values.balance,values.id_container)
          break;
        case "FACTURAS":
          getContractsReport(values.balance,values.id_container)
          break;
        default:
          console.log('Ninguna opcion seleccionada')
      }
    },
  });


  const getContractsReport = async (filter, container_id) => {
    if (filter && container_id && filter !== "" && container_id !== "") {
      try {
        setLoadingRequest(true)
        let res = await fetch(getAllContractsDataRoute(filter, container_id), {
          method: "GET",
          headers: {
            //"Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`
            //"x-access-token": token,
          }
          //cors: 'no-cors'
        })
        let data = await res.json()
        //console.log(data)
        if (res.ok) {
          if(data.length > 0){
            generateMultipleContractsHTML(data)
          }else{
            mostrarAlertRequest('No se encontraron resultados para esta búsqueda')
          }
        } else {
          if (res.status === 404) {
            mostrarErrorRequest("Not found")
          }
          if (res.status === 401 || res.status === 403) {
            mostrarErrorRequest("No tiene autorización o su sesión ha expirado")
          }
        }
        setLoadingRequest(false)
        return data;
      } catch (er) {
        setLoadingRequest(false)
        mostrarErrorRequest(er)
      }
    }
  }

  const getContainerReport = async (filter, container_id) => {
    if (filter && container_id && filter !== "" && container_id !== "") {
      try {
        setLoadingRequest(true)
        let res = await fetch(getContainerReportRoute(filter, container_id), {
          method: "GET",
          headers: {
            //"Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`
            //"x-access-token": token,
          }
          //cors: 'no-cors'
        })
        let data = await res.json()
        //console.log(data)
        if (res.ok) {
          openPopupWithData(data)
          //console.log('data',data)
        } else {
          if (res.status === 404) {
            mostrarErrorRequest("Not found")
          }
          if (res.status === 401 || res.status === 403) {
            mostrarErrorRequest("No tiene autorización o su sesión ha expirado")
          }
        }
        setLoadingRequest(false)
        return data;
      } catch (errorRequest) {
        setLoadingRequest(false)
        setErrorRequest(errorRequest)
      }
    }
  }
  
  const now = new Date();
  const dateTimeString = now.toLocaleString();

  const openPopupWithData = (data) => {
    const popupWindow = window.open('', '_blank', 'width=1200,height=800');
    const style = `
    <style>
      body {
        font-family: sans-serif;
      }
      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
      }
      .report-title {
        font-size: 20px;
        font-weight: bold;
      }
      .report-info {
        text-align: right;
        font-size: 20px;
        font-weight: bold;
      }
      .user-info {
        text-align: right;
        font-size: 14px;
      }
      table {
        width: 100%;
        border-collapse: collapse;
        margin: 10px 0;
      }
      th, td {
        border: 1px solid #ddd;
        padding: 5px;
        text-align: left;
      }
      th {
        background-color: #000000;
        color: #FFFFFF;
        text-align: center;
      }
      tr:nth-child(even) {
        background-color: #e0e5eb;
      }
      tr:hover {
        background-color: #CED3D8;
      }
    </style>
    `;
    popupWindow.document.write('<html><head><title>Detalles</title>');
    popupWindow.document.write(style); // Agregar los estilos
    popupWindow.document.write('</head><body>');
  
    // Agregar el encabezado
    popupWindow.document.write(`
    <div class="header">
      <div class="report-title">
        REPORTE DE CARGA <br>
        Container: ${data.container}<br>
        Booking: ${data.booking} <br>
        Sello: ${data.sello} <br>
      </div>
      <div class="report-info">
        Destino: ${data.destino}<br>
        Origen: ${data.origen}<br>
        Total de cajas: ${data.total}<br>
        Fecha: ${data.fecha}
      </div>
    </div>
    <div class="user-info">
      Generado por: ${auth.globals.user} <br>
      ${dateTimeString}
    </div>
    `);
  
    // Crear una sola tabla
    popupWindow.document.write(convertToTable(data.lista));
    popupWindow.document.write('</body></html>');
    popupWindow.document.close();
  }

  const convertToTable = (lista) => {
    let table = "<table border='1'><tr>";
    if (lista.length > 0 ) {
      // Asumiendo que el vector tiene las mismas claves
      let keys = Object.keys(lista[0]);
      
      keys.forEach(key => {
        table += `<th>${getHeaderTitle(key)}</th>`;
      });
      table += "</tr>";
  
    lista.forEach(item => {
      table += "<tr>";
      keys.forEach(key => {
        table += `<td>${item[key]}</td>`;
      });
      table += "</tr>";
    });  
    }
    table += "</table>";
    return table;
  }

  const getHeaderTitle = (fieldName) => {
    switch (fieldName) {
      case 'codigo':
        return 'Id';
      case 'status':
        return 'Estado';
      case 'nombre':
        return 'Nombre';
      case 'direccion':
        return 'Dirección';
      case 'estado':
        return 'Departamento';
      case 'municipio':
        return 'Municipio';
      case 'celular':
        return 'Celular';
      case 'empresa':
        return 'Empresa';
      case 'pagado':
        return 'Pagado (USD)';
      case 'saldo':
        return 'Saldo (USD)';
      case 'tamano':
        return 'Tamaño';
      case 'telefono':
        return 'Teléfono';
      case 'zip':
        return 'Zip';
      default:
        return fieldName;
    }
  }


  const mostrarErrorRequest=(error)=>{
    if(error!=null){
      try{
        swal({
          title:"Error",
          text: "Resultado: "+error,
          icon: "error",
          button:"Aceptar"
        })
        if(error==="No tiene autorización o su sesión ha expirado"){
          auth.logout();
        }
      }catch(e){
        swal({
          title:"Error",
          text: "Error inesperado ",
          icon: "error",
          button:"Aceptar"
        })
      }
    }
    setErrorRequest(null)
  }

  const mostrarAlertRequest=(info)=>{
    if(info!=null){
      try{
        swal({
          title:"Alerta",
          text: info,
          icon: "warning",
          button:"Aceptar"
        })
        
      }catch(e){
        swal({
          title:"Error",
          text: "Error inesperado ",
          icon: "error",
          button:"Aceptar"
        })
      }
    }
  }

  /*const alertSucess=(text_title,text_content='')=>{    
    try{
      swal({
        title:text_title,
        text: text_content,
        icon: "success",
        button:"Aceptar"
      })
    }catch(e){
      swal({
        title:"Error inesperado",
        text: "Error inesperado ",
        icon: "error",
        button:"Aceptar"
      })
    }
    //navigate("/Dashboard")
  }*/




  
  if (loadingRequest)
  return (<Loading/>)
  if (errorRequest)
  return (<FatalError/>)
  

  return (
    <Box>
      <form >
        <ContainersDialogSelector
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
            initialDate={reportFormik.values.initialDate}
            finalDate={reportFormik.values.finalDate}
            token={token}
            reportFormik={reportFormik}
            reloadDataValue={true}
        />
      <Box
          display="grid" 
          gap="15px"
          marginBottom="15px" 
          gridTemplateColumns="repeat(12, minmax(0, 1fr))"
          sx={{
            "& > div": { gridColumn: isNonMobile? undefined : "span 12"}
          }}
        >
          <FormControl variant="filled"
            disabled={false}
            sx={{ gridColumn: "span 6"}}
            required>
            <InputLabel id="select-balance-label" name="select-balance-label">Tipo de saldo</InputLabel>
            <Select
              labelId="select-balance-label"
              id="select-balance"
              fullWidth={!isNonMobile}
              //onBlur = {rutasFormik.handleBlur}
              value = {reportFormik.values.balance||''}
              onChange={reportFormik.handleChange}
              name = "balance"
              size="small"
            >
              {balanceOptions.map((balanceType) => (
                <MenuItem key={balanceType.id} value={balanceType.id.toString()}>
                  {balanceType.name}
                </MenuItem>
              ))}
            </Select>  
          </FormControl>
        </Box>
        <Box
          display="grid" 
          gap="15px"
          marginBottom="15px" 
          gridTemplateColumns="repeat(12, minmax(0, 1fr))"
          sx={{
            "& > div": { gridColumn: isNonMobile? undefined : "span 12"}
          }}
        >
          <TextField
            label="Fecha inicial"
            name="initialDate"
            type="date"
            value={reportFormik.values.initialDate}
            onChange={reportFormik.handleChange}
            fullWidth
            variant="filled"
            InputLabelProps={{ shrink: true }}
            size="small"
            sx={{ gridColumn: "span 6"}}
          />
          <TextField
            label="Fecha Final"
            name="finalDate"
            type="date"
            value={reportFormik.values.finalDate}
            onChange={reportFormik.handleChange}
            fullWidth
            variant="filled"
            InputLabelProps={{ shrink: true }}
            size="small"
            sx={{ gridColumn: "span 6"}}
          />
        </Box>
        <Box
          display="flex"
          flexDirection={isNonMobile ? 'row' : 'column'}
          justifyContent="flex-start"
          mt="10px"
          gap={1} // Añade un espacio entre los botones
        >
          <Button variant="contained" color="secondary" onClick={() => {setActionType("HTML"); setOpenDialog(true)}} >
            <CodeIcon/> Reporte HTML
          </Button>
          <Button variant="contained" color="secondary" onClick={() => {setActionType("FACTURAS"); setOpenDialog(true)}} >
            <StickyNote2Icon/> FACTURAS
          </Button>
        </Box>  
      </form>
    </Box>
  );
}

export default LoadingReport;