import { Box,Button,useMediaQuery,useTheme,TextField } from "@mui/material";
import { DataGrid, esES, GridToolbar } from "@mui/x-data-grid";
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import { /*useLocation,*/ useNavigate } from "react-router-dom";
import useAuth from "../../../../auth/useAuth";
import Header from "../../../../components/Header";
import { useFetchGET } from "../../../../state/FetchHelpers";
import { newRoleRoute, rolesListRoute } from "../../../../config";
import Loading from "../../../global/Loading";
import FatalError from "../../../global/FatalError";
import AddIcon from '@mui/icons-material/Add';
import { tokens } from "../../../../theme";
import { useEffect, useState } from "react";
import swal from "sweetalert";
import * as yup from "yup";
import { Formik } from "formik"

const Roles = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode)
  const auth = useAuth();
  const token = auth.globals.token
  const isNonMobile = useMediaQuery("(min-width:600px)")
  //const location = useLocation()


  const [reloadData, setReloadData] = useState(false);
  const handleReloadData = () => {
    setReloadData(true);
  };

  useEffect(() => {
    // Llama a la función para recargar datos cuando reloadData cambie a true
    if (reloadData) {
      handleReloadData();
      setReloadData(false);
      setInicitalValues({
        name: "",
        create_user: auth.globals.id.toString()
      })
    }
  }, [reloadData,auth.globals.id]);

  const { data: rolesListData, loading: rolesListLoading, error: rolesListError } = useFetchGET(rolesListRoute(),token,[],reloadData);
  //const {data,loading,error} = useFetch2(_user!=null?`${url}/api/pools/user/${_user}`:`${url}/api/pools`,token)

  //const isNonMobile = useMediaQuery("(min-width:600px)")
  const navigate = useNavigate();

  const [ errorRequest, setErrorRequest ] = useState(null)
  const [ loadingRequest, setLoadingRequest ] = useState(false)
  
  const mostrarErrorRequest=(error)=>{
    if(error!=null){
      try{
        swal({
          title:"Error al crear Rol",
          text: "Resultado: "+error,
          icon: "error",
          button:"Aceptar"
        })
        if(error==="No tiene autorización o su sesión ha expirado"){
          auth.logout();
        }
      }catch(e){
        swal({
          title:"Error al crear Rol",
          text: "Error inesperado ",
          icon: "error",
          button:"Aceptar"
        })
      }
    }
    setErrorRequest(null)
  }

  const alertSucess=(text_title,text_content='')=>{    
    try{
      swal({
        title:text_title,
        text: text_content,
        icon: "success",
        button:"Aceptar"
      })
    }catch(e){
      swal({
        title:"Error inesperado",
        text: "Error inesperado ",
        icon: "error",
        button:"Aceptar"
      })
    }
    navigate("/Users")
  }

  const newRole = async(body) =>{
    //console.log('bpdy',body)
    try{
      
      let res = await fetch(newRoleRoute(), {
        method: "POST",
        headers: {
          //"Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Accept":"application/json",
          "Authorization": `Bearer ${token}`
          //"x-access-token": token,
        },
        body: JSON.stringify(body),
        //cors: 'no-cors'
      })
      let data = await res.json()
      if(res.ok){
        setLoadingRequest(false)
        alertSucess('Rol Ingresado',data.message)
        //console.log('data',data)
        //console.log('res',res)
        navigate("/Roles")
        setReloadData(true);       
      }else{
        setLoadingRequest(false)
        //console.log('data else',data)
        //console.log('res else',res)
        if(res.status === 401||res.status ===403){
          setErrorRequest("No tiene autorización o su sesión ha expirado")
        }else{
          setErrorRequest(data.detail)
        }  
        mostrarErrorRequest(data.detail)     
      }return data;
    }catch(errorRequest){
      //console.log('errorRequest',errorRequest)
      setLoadingRequest(false)
      setErrorRequest(errorRequest)
    }
  }

  const [initialValues,setInicitalValues] = useState({
    name: "",
    create_user: auth.globals.id.toString()
  })


  const userSchema = yup.object().shape({
    name: yup.string().required("* Valor Requerido"),
  })

  const handleFormSubmit = (values) => {
    //console.log('values',values)
    setInicitalValues(values)
    setLoadingRequest(true)
    newRole(values)
    //console.log('Nueva bodega')
  }



  const columns=[
    {
      field:"id", 
      headerName: "ID",
      cellClassName: "id-column--cell",
      headerClassName: "id-header--cell",
      hideable:false
    },
    {
      //hide: true,
      field:"name", 
      headerName: "Nombre",
      flex: 0.5, 
      cellClassName: "name-column--cell",
      minWidth:120
    },
    {
      minWidth:200,
      filterable:false,
      sortable:false,
      field:"actions", 
      headerName: "Acciones",
      headerAlign:"center",
      cellClassName: "actions-column--cell",
      renderCell:({ row: { id,name} })=>{
        return(
          <Box
            width="80%"
            maxWidth="175px"
            height="75%"
            maxHeight="35px" 
            display="flex" 
            justifyContent="center" 
            m="0 auto">
            <Button
              color="secondary"
              variant="contained"
              onClick={()=>{navigate("/Roles/Acciones?id="+id+"&name="+name)}}>
            <ModeEditOutlineIcon/>
              <Box sx={{ml:"5px"}}>
                Ver / Editar
              </Box>
            </Button>
          </Box>
        )
      }
    }
  ]

  if(rolesListLoading)
  return (<Loading/>)

  if(loadingRequest)
  return (<Loading/>)

  if(rolesListError)
  return(<FatalError/>)

  if(errorRequest)
  return(<FatalError/>)

  return (
    <Box p={1} >
      {/*<Box display="flex" justifyContent="space-between" alignItems="center">*/}
      <Header title="Roles" subtitle="Listado de Roles"/>
      <Formik
        enableReinitialize={true}
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema = {userSchema}
      >
      {({
        values, 
        errors, 
        touched, 
        handleBlur, 
        handleChange,
        handleSubmit,
        setFieldValue
      }) => (
        <form onSubmit={handleSubmit} >
          <Box
            display="grid" 
            gap="15px"
            marginBottom="15px" 
            gridTemplateColumns="repeat(12, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile? undefined : "span 12"}
            }}
          >        
            <TextField
                  fullWidth={!isNonMobile}
                  size="small"
                  variant="filled"
                  type="text"
                  label="Nombre"
                  onBlur = {handleBlur}
                  onChange = {handleChange}
                  value = { values.name}
                  name = "name"
                  error = {!!touched.name && !!errors.name}
                  helperText={touched.name && errors.name}
                  sx={{ gridColumn: "span 12"}}
            />
          </Box>
              <Box display="flex" justifyContent="end" mt="20px">               
                <Button type="submit" color="secondary" variant="contained">
                  <AddIcon/>
                    Agregar nuevo Rol
                </Button>                           
              </Box>
        </form>
        )}
      </Formik>
          {/*<Box
              width="100px"
              p= "5px"
              display="flex"
              justifyContent="center"
              backgroundColor= {colors.greenAccent[600]}
              borderRadius = "4px"
              onClick={()=>{navigate("/Clientes/Form")}}
              >
              <PersonAddIcon/>
              <Typography color={colors.grey[100]} sx={{ml:"5px"}}>
                Nuevo
              </Typography>
          </Box>*/}
      <Box m="5px 0px 50px 0" /*height={isNonMobile?"100%":undefined}*/ sx={{
        "& .MuiDataGrid-root":{
          border: "none",
        },
        "& .MuiDataGrid-cell":{
          borderBottom: "none"
        },
        "& .MuiDataGrid-columnHeaders":{
          backgroundColor: colors.blueAccent[700],
          borderBottom: "none",
          color: "white"
        },
        "& .MuiDataGrid-virtualScroller":{
          backgroundColor: colors.primary[400],
        },
        "& .MuiDataGrid-footerContainer":{
          borderTop: "none",
          backgroundColor: colors.blueAccent[700],
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text":{
          color: `${colors.grey[100]} !important`,
        },
        "& .MuiToolbar-gutters":{
          color: "white"
        }

      }}>
        <DataGrid
          autoHeight
          initialState={{
            columns: {
              columnVisibilityModel: {
                // Hide columns status and traderName, the other columns will remain visible
                id: false,
                actions: true
              },
            },
          }}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          rows={rolesListData}
          columns={columns}
          getRowId={(row) => row.id}
          density="compact"
          slots={{
            toolbar: GridToolbar,
          }}
          /*columnVisibilityModel={{
            id: false,
          }}*/
          
        />
      </Box>
    </Box>
  );


}

export default Roles;