import { useState, useEffect } from 'react';
import swal from 'sweetalert';
import useAuth from '../auth/useAuth'

export const useFetchPOST = (url, myVar, token) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchResource = async () => {
      try {
        let res = await fetch(url, {
          method: "POST",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          },
          body: JSON.stringify(myVar),
          //cors: 'no-cors'
        });

        let responseData = await res.json();

        setData(responseData);
        setLoading(false);

      } catch (error) {
        setLoading(false);
        setError(error);
      }
    };
    fetchResource();
  }, [url, myVar, token]);

  return { data, loading, error };
};

export const useFetchGET = (url, token, initial, reloadData) => {
  const [data, setData] = useState(initial);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const auth = useAuth();

  const mostrarAlerta = () => {
    swal({
      title: "No autorizado",
      text: "No tiene autorización para permanecer en esta página o su sesión ha expirado",
      icon: "error",
      button: "Aceptar"
    });
  };

  useEffect(() => {
    const fetchResource = async () => {
      if (url != null) {
        try {
          //setLoading(true)
          let res = await fetch(url, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Accept": "application/json",
              "Authorization": `Bearer ${token}`
            },
            //cors: 'no-cors'
          });

          let responseData = await res.json();

          if (res.ok) {
            setData(responseData);
          } else {
            if (res.status === 401||res.status ===403) {
              mostrarAlerta();
              auth.logout();
            }
            setError(responseData.message);
          }
          setLoading(false);

        } catch (error) {
          setLoading(false);
          setError(error);
        }
      } else {
        setLoading(false);
      }
    };
    fetchResource();
  }, [url, token, auth, reloadData]);

  return { data, loading, error };
};

export const useFetchPUT = (url, myVar, token) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchResource = async () => {
      try {
        let res = await fetch(url, {
          method: "PUT",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          },
          body: JSON.stringify(myVar),
          //cors: 'no-cors'
        });

        let responseData = await res.json();

        setData(responseData);
        setLoading(false);

      } catch (error) {
        setLoading(false);
        setError(error);
      }
    };
    fetchResource();
  }, [url, myVar, token]);

  return { data, loading, error };
};