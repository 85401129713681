import React from 'react';
import {useNavigate } from 'react-router-dom';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { Box, Button, Typography, IconButton} from '@mui/material';


const NoDataFound = () => {
  const navigate = useNavigate();

  return (
    <Box sx={{ height: '90vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <IconButton >
        <SentimentVeryDissatisfiedIcon sx={{ fontSize: '4rem', mb: 2 }}/>
      </IconButton>
      <Typography variant="h4" mb={2}>
        ERROR 404
      </Typography>
      <Typography variant="subtitle1" mb={4}>
        Lo siento, no hay datos para mostrar.
      </Typography>
      <Button variant="contained" color="primary" size="large" onClick={()=>{navigate('/')}}>
        Volver al inicio
      </Button>
    </Box>
  );
};
  
  export default NoDataFound;