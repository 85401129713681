import { Box,useTheme } from "@mui/material";
import { DataGrid, esES, GridToolbar } from "@mui/x-data-grid";
import useAuth from "../../../../auth/useAuth";
import { useFetchGET } from "../../../../state/FetchHelpers";
import { getHistoryCashRoute} from "../../../../config";
import Loading from "../../../global/Loading";
import FatalError from "../../../global/FatalError";
import { tokens } from "../../../../theme";
import { useState } from "react";
import { format, parseISO } from 'date-fns';

const HistoryCash = ({reloadData}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode)
  const auth = useAuth();
  const token = auth.globals.token

  const customLocaleText = {
    ...esES.components.MuiDataGrid.defaultProps.localeText,
    noRowsLabel: 'No hay se encontraron resultados...',
  };

  const { data: historyCashListData, loading: historyCashListLoading, error: historyCashListError } = useFetchGET(getHistoryCashRoute(), token, [],reloadData);
  //console.log('historyCashListData',historyCashListData)

  /*const getFormatDate = (date_param) => {
    return format(new Date(date_param),'MM/dd/yyyy');
  };*/

  const columns=[
    {
      flex:1,
      field: 'driver_cash_date', 
      headerName: 'Fecha', 
      type: 'date',
      valueGetter: (params) => {
        if (params.row.driver_cash_date) {
          return parseISO(params.row.driver_cash_date);
        }
        return null;
      },
      renderCell: (params) => {
        if(params.value) {
          return format(params.value, 'MM/dd/yyyy');
        }
        return '';
      },
    },
    {
      flex:1,
      field:"amount", 
      headerName: "Cantidad recibida (USD)",
    },
    {
      flex:1,
      field:"driver_name", 
      headerName: "Usuario",
    },
    {
      flex:1,
      field:"receiving_user", 
      headerName: "Usuario que recibió",
    }
  ]

  const rows = historyCashListData.map((element, index) => ({
    id: index,
    driver_cash_date: element.driver_cash_date,
    amount: element.amount,
    driver_name: element.driver_name,
    receiving_user: element.receiving_user,
  }));

  const [sortModel] = useState([
    {
      field: 'driver_cash_date',
      sort: 'desc',
    },
  ]);

  if(historyCashListLoading)
  return (<Loading/>)

  if(historyCashListError)
  return(<FatalError/>)

  return(
    <Box p={1} >
      { historyCashListData && (
      <Box m="5px 0px 50px 0" /*height={isNonMobile?"100%":undefined}*/ sx={{
        "& .MuiDataGrid-root":{
          border: "none",
        },
        "& .MuiDataGrid-cell":{
          borderBottom: "none"
        },
        "& .MuiDataGrid-columnHeaders":{
          backgroundColor: colors.blueAccent[700],
          borderBottom: "none",
          color: "white"
        },
        "& .MuiDataGrid-virtualScroller":{
          backgroundColor: colors.primary[400],
        },
        "& .MuiDataGrid-footerContainer":{
          borderTop: "none",
          backgroundColor: colors.blueAccent[700],
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text":{
          color: `${colors.grey[100]} !important`,
        },
        "& .MuiToolbar-gutters":{
          color: "white"
        }

      }}>
        <DataGrid
          autoHeight
          rows={rows}
          columns={columns}
          localeText={customLocaleText}
          density="compact"
          slots={{
            toolbar: GridToolbar,
          }}
          sortModel={sortModel}
        />
      </Box>
      )}
    </Box>
  )

}

export default HistoryCash
