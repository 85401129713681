import { Box,Button,TextField,useMediaQuery,FormControl,InputLabel,MenuItem} from "@mui/material";
import Select from '@mui/material/Select';
//import { useNavigate } from "react-router-dom";
import useAuth from "../../../../auth/useAuth";
import { useState } from "react";
import { useFormik } from "formik";
import { getCashIncomeReportRoute, usersByRolRoute } from "../../../../config";
import { useFetchGET } from "../../../../state/FetchHelpers";
import Loading from "../../../global/Loading";
import FatalError from "../../../global/FatalError";
import CodeIcon from '@mui/icons-material/Code';
import swal from "sweetalert";

const CashIncome = () => {
  //const theme = useTheme();
  //const colors = tokens(theme.palette.mode)
  const auth = useAuth();
  const token = auth.globals.token
  const isNonMobile = useMediaQuery("(min-width:600px)")
  //const navigate = useNavigate();

  const [ loadingRequest, setLoadingRequest ] = useState(false)
  const [ errorRequest, setErrorRequest ] = useState(null)

  const { data: driversListData, loading: driversListLoading, error: driversListError } = useFetchGET(usersByRolRoute(2),token,[]);
  //console.log(driversListData)

  const getLocalDate = () => {
    return new Intl.DateTimeFormat('en-CA', { // 'en-CA' utiliza el formato YYYY-MM-DD
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }).format(new Date());
  };

  const today = getLocalDate()

  const cashIncomeFormik = useFormik({
    initialValues: {
        initialDate: today,
        endDate: today,
        driver: '',
    },
    onSubmit: (values) => {
      getCashIncomeReport(values.driver,values.initialDate,values.endDate)
      console.log(values)
    },
  });

  const getCashIncomeReport = async (user,initialDate,endDate) => {
    if (initialDate && endDate && user && initialDate !== "" && endDate !== "" && user !=="") {
      try {
        setLoadingRequest(true)
        let res = await fetch(getCashIncomeReportRoute(user===-1?'null':user,initialDate, endDate), {
          method: "GET",
          headers: {
            //"Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`
            //"x-access-token": token,
          }
          //cors: 'no-cors'
        })
        let data = await res.json()
        //console.log(data)
        if (res.ok) {
          openPopupWithData(data,initialDate,endDate)
          //console.log('data',data)
        } else {
          if (res.status === 404) {
            mostrarErrorRequest("Not found")
          }
          if (res.status === 401 || res.status === 403) {
            mostrarErrorRequest("No tiene autorización o su sesión ha expirado")
          }
        }
        setLoadingRequest(false)
        return data;
      } catch (errorRequest) {
        setLoadingRequest(false)
        setErrorRequest(errorRequest)
      }
    }
  }

  const now = new Date();
  const dateTimeString = now.toLocaleString();

  const openPopupWithData = (data,init_date,end_date) => {
    const popupWindow = window.open('', '_blank', 'width=1200,height=800');
    const style = `
    <style>
      body {
        font-family: sans-serif;
      }
      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
      }
      .report-title {
        font-size: 20px;
        font-weight: bold;
      }
      .report-info {
        text-align: right;
        font-size: 20px;
        font-weight: bold;
      }
      .user-info {
        text-align: right;
        font-size: 14px;
      }
      table {
        width: 100%;
        border-collapse: collapse;
        margin: 10px 0;
      }
      th, td {
        border: 1px solid #ddd;
        padding: 5px;
        text-align: left;
      }
      th {
        background-color: #000000;
        color: #FFFFFF;
        text-align: center;
      }
      tr:nth-child(even) {
        background-color: #e0e5eb;
      }
      tr:hover {
        background-color: #CED3D8;
      }
    </style>
    `;
    popupWindow.document.write('<html><head><title>Detalles</title>');
    popupWindow.document.write(style); // Agregar los estilos
    popupWindow.document.write('</head><body>');
  
    // Agregar el encabezado
    popupWindow.document.write(`
    <div class="header">
      <div class="report-title">
        REPORTE INGRESOS DE EFECTIVO <br>
        Del: ${init_date} al: ${end_date}<br>
        Valores en USD
      </div>
      <div class="user-info">
      Generado por: ${auth.globals.user} <br>
      ${dateTimeString}
    </div>
    </div>
    `);
  
    // Crear una sola tabla
    popupWindow.document.write(convertToTable(data));
    popupWindow.document.write('</body></html>');
    popupWindow.document.close();
  }

  const convertToTable = (data) => {
    let table = "<table border='1'><tr>";
    if (Object.keys(data).length > 0 ) {
      // obtenemos al primer agente
      let primerNombre = Object.keys(data)[0];
      //asumiendo que todos los agentes tienen las mismas keys
      let keysLista = Object.keys(data[primerNombre].lista[0]);

      keysLista.forEach(key => {
        table += `<th>${getHeaderTitle(key)}</th>`;
      });
      table += "</tr>";
      //traemos a todos los agentes
      let keysNames = Object.keys(data)
      //iteramos a los agentes
      keysNames.forEach(keyName => {
        //console.log('nombre',keyName)
        //console.log('cantidad de llaves de la lista',Object.keys(data[keyName]["lista"][0]).length)
        //console.log('tamaño de la lista',data[keyName]["lista"].length)
        table += `<th colspan="${Object.keys(data[keyName]["lista"][0]).length}"> ${keyName} (Cantidad: ${data[keyName]["lista"].length})</th>`;
        //console.log('lista de nombre',data[keyName]["lista"])
        data[keyName]["lista"].forEach(item => {
          //console.log('item',item)
          table += "<tr>";
          keysLista.forEach(key => {
            //console.log('llave',key)
            //console.log('valor de la llave',data[keyName]["lista"][key])
            table += `<td>${item[key]}</td>`;
          });
        })
        table += "</tr>";
        table += "<tr>";
        table += `<td > Total </td>`;
        table += `<td > ${data[keyName]["total_income"]}</td>`;
        table += "</tr>";
      });

    }
    table += "</table>";
    return table;
  }

  const getHeaderTitle = (fieldName) => {
    switch (fieldName) {
      case 'correlative':
        return 'Id';
      case 'income':
        return 'Efectivo';
      default:
        return fieldName;
    }
  }

  const mostrarErrorRequest=(error)=>{
    if(error!=null){
      try{
        swal({
          title:"Error",
          text: "Resultado: "+error,
          icon: "error",
          button:"Aceptar"
        })
        if(error==="No tiene autorización o su sesión ha expirado"){
          auth.logout();
        }
      }catch(e){
        swal({
          title:"Error",
          text: "Error inesperado ",
          icon: "error",
          button:"Aceptar"
        })
      }
    }
    setErrorRequest(null)
  }

  /*const mostrarAlertRequest=(info)=>{
    if(info!=null){
      try{
        swal({
          title:"Alerta",
          text: info,
          icon: "warning",
          button:"Aceptar"
        })
        
      }catch(e){
        swal({
          title:"Error",
          text: "Error inesperado ",
          icon: "error",
          button:"Aceptar"
        })
      }
    }
  }*/

  /*const alertSucess=(text_title,text_content='')=>{    
    try{
      swal({
        title:text_title,
        text: text_content,
        icon: "success",
        button:"Aceptar"
      })
    }catch(e){
      swal({
        title:"Error inesperado",
        text: "Error inesperado ",
        icon: "error",
        button:"Aceptar"
      })
    }
    //navigate("/Dashboard")
  }*/


  if (driversListLoading||loadingRequest)
  return (<Loading/>)
  if (driversListError||errorRequest)
  return (<FatalError/>)

  return (
    <Box>
      <form onSubmit={cashIncomeFormik.handleSubmit}>
        <Box
          display="grid" 
          gap="15px"
          marginBottom="15px" 
          gridTemplateColumns="repeat(12, minmax(0, 1fr))"
          sx={{
            "& > div": { gridColumn: isNonMobile? undefined : "span 12"}
          }}
        >
          <FormControl variant="filled"
            disabled={false}
            sx={{ gridColumn: "span 6"}}
            required>
            <InputLabel id="select-driver-label" name="select-driver-label">Drivers</InputLabel>
            <Select
              labelId="select-driver-label"
              id="select-driver"
              fullWidth={!isNonMobile}
              //onBlur = {rutasFormik.handleBlur}
              value = {cashIncomeFormik.values.driver||''}
              onChange={cashIncomeFormik.handleChange}
              name = "driver"
              size="small"
            >
              {<MenuItem key={-1} value={-1}>
                Todos
              </MenuItem>}
              {driversListData.map((driver) => (
                <MenuItem key={driver.id} value={driver.id.toString()}>
                  {driver.name + ' ' + driver.lastname}
                </MenuItem>
              ))}
            </Select>  
          </FormControl>            
        </Box>
        <Box
          display="grid" 
          gap="15px"
          marginBottom="15px" 
          gridTemplateColumns="repeat(12, minmax(0, 1fr))"
          sx={{
            "& > div": { gridColumn: isNonMobile? undefined : "span 12"}
          }}
        >
          <TextField
            label="Fecha inicial"
            name="initialDate"
            type="date"
            value={cashIncomeFormik.values.initialDate}
            onChange={cashIncomeFormik.handleChange}
            fullWidth
            variant="filled"
            InputLabelProps={{ shrink: true }}
            size="small"
            sx={{ gridColumn: "span 6"}}
          />
          <TextField
            label="Fecha Final"
            name="endDate"
            type="date"
            value={cashIncomeFormik.values.endDate}
            onChange={cashIncomeFormik.handleChange}
            fullWidth
            variant="filled"
            InputLabelProps={{ shrink: true }}
            size="small"
            sx={{ gridColumn: "span 6"}}
          />
        </Box>
        <Box
          display="flex"
          flexDirection={isNonMobile ? 'row' : 'column'}
          justifyContent="flex-start"
          mt="10px"
          gap={1} // Añade un espacio entre los botones
        >
          <Button type="submit" variant="contained" color="secondary" >
            <CodeIcon/> Reporte HTML
          </Button>
        </Box>  
      </form>
    </Box>
  );
}

export default CashIncome;