import { Box, Button, FormControl, InputLabel, MenuItem, TextField } from "@mui/material";
import Select from '@mui/material/Select';
import useMediaQuery from "@mui/material/useMediaQuery";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import swal from 'sweetalert';
import * as yup from "yup";
import useAuth from "../../../../auth/useAuth";
import Header from "../../../../components/Header";
import { newUserRoute, rolesListRoute, storesListRoute, updateUserRoute, userRoute } from "../../../../config";
//import { useFetchGET as fetchUser } from "../../../../state/useFetch";
import { useFetchGET } from "../../../../state/FetchHelpers";
import Loading from "../../../global/Loading";
import UserRoles from "../Catalogos/UserRoles";



const FormUser = ({permissionsListData}) => {
  const navigate = useNavigate();
  const auth = useAuth();
  const token = auth.globals.token
  const isNonMobile = useMediaQuery("(min-width:600px)")
  const [ loadingRequest, setLoadingRequest ] = useState(false)
  const [ errorRequest, setErrorRequest ] = useState(null)
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const _id = query.get('id')
  
  const { data: userData,loading: userLoading,error: userError } = useFetchGET(_id!=null?userRoute(_id):null,token)
  //console.log('datauser',userData)

  const { data: rolesData, loading: rolesLoading, error: rolesError } = useFetchGET(rolesListRoute(), token, []);
  //console.log('Listado roles: ',rolesData)

  const { data: storesListData, loading: storesListLoading, error: storesListError } = useFetchGET(storesListRoute(), token, []);
  //console.log('Listado stores: ',storesListData)

  const filterRolesList = () => {
    // Revisar si el permissionsListData incluye el ID 48
    const includeRole1 = permissionsListData.includes(48);
  
    // Filtrar rolesData para excluir el rol con id = 1 si includeRole1 es falso
    return rolesData.filter(role => !(role.id === 1 && !includeRole1));
  }
  
  const filteredRolesList = filterRolesList();

  const mostrarErrorRequest=(error)=>{
    if(error!=null){
      try{
        swal({
          title:"Error al crear/modificar usuario",
          text: "Resultado: "+error,
          icon: "error",
          button:"Aceptar"
        })
        if(error==="No tiene autorización o su sesión ha expirado"){
          auth.logout();
        }
      }catch(e){
        swal({
          title:"Error al crear/modificar usuario",
          text: "Error inesperado ",
          icon: "error",
          button:"Aceptar"
        })
      }
    }
    setErrorRequest(null)
  }

  const mostrarErrorUsuario=(error)=>{
    if(error!=null){
      try{
        swal({
          title:"Usuario no encontrado",
          text: "Resultado: "+error,
          icon: "error",
          button:"Aceptar"
        })
      }catch(e){
        swal({
          title:"Usuario no encontrado",
          text: "Error inesperado ",
          icon: "error",
          button:"Aceptar"
        })
      }
    }
    navigate("/Users")
  }


  const alertSucess=(text_title,text_content='')=>{    
    try{
      swal({
        title:text_title,
        text: text_content,
        icon: "success",
        button:"Aceptar"
      })
    }catch(e){
      swal({
        title:"Error inesperado",
        text: "Error inesperado ",
        icon: "error",
        button:"Aceptar"
      })
    }
    navigate("/Users")
  }

  const newUser = async(body) =>{
    //console.log('bpdy',body)
    try{
      
      let res = await fetch(newUserRoute(), {
        method: "POST",
        headers: {
          //"Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Accept":"application/json",
          "Authorization": `Bearer ${token}`
          //"x-access-token": token,
        },
        body: JSON.stringify(body),
        //cors: 'no-cors'
      })
      let data = await res.json()
      if(res.ok){
        setLoadingRequest(false)
        alertSucess('Usuario Creado',data.message)
        //console.log('data',data)
        //console.log('res',res)
        navigate("/Users")       
      }else{
        //console.log('res else',res)
        if(res.status === 401||res.status === 403){
          mostrarErrorRequest("No tiene autorización o su sesión ha expirado")          
        }
        mostrarErrorRequest(data.detail)
        setErrorRequest(data.detail)    
      }
      setLoadingRequest(false) 
      return data;
    }catch(errorRequest){
      //console.log('errorRequest',errorRequest)
      setLoadingRequest(false)
      setErrorRequest(errorRequest)
    }
  }

  const modifyUser = async(body,id) =>{
    setLoadingRequest(false);
    body.id_store = String(body.id_store);
    //console.log('Modificar')
    try{
      
      let res = await fetch(updateUserRoute(id), {
        method: "PUT",
        headers: {
          //"Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Accept":"application/json",
          "Authorization": `Bearer ${token}`
          //"x-access-token": token,
        },
        body: JSON.stringify(body),
        //cors: 'no-cors'
      })
      let data = await res.json()
      if(res.ok){
        setLoadingRequest(false)
        //alertSucess(data.message)
        //console.log(data)
        //console.log(res)
        navigate("/Users")       
      }else{
        //console.log('res else',res)
        if(res.status === 401||res.status === 403){
          mostrarErrorRequest("No tiene autorización o su sesión ha expirado")          
        }
        mostrarErrorRequest(data.message)
        setErrorRequest(data.message)    
      }
      setLoadingRequest(false) 
      return data;
    }catch(errorRequest){
      setLoadingRequest(false)
      setErrorRequest(errorRequest)
    }
  }


  const [initialValues,setInicitalValues] = useState({
    email: "",
    password: "",
    name: "",
    lastname: "",
    user: "",
    status: "1",
    create_user: auth.globals.id.toString(),
    id_rol: "",
    id_store:"",
    phone:""
  })

  useEffect(()=>{
      if(_id&&userData){
        setInicitalValues(userData[0])
        //console.log('efect data',userData)
      }      
  },[userData,_id])

  const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

  const userSchema = yup.object().shape({
    name: yup.string().required("* Valor Requerido"),
    lastname: yup.string().required("* Valor Requerido"),
    user: yup.string().required("* Valor Requerido"),
    email: yup.string().email("Correo no valido").required("* Valor Requerido"),
    password: _id?undefined:yup.string().required("* Valor Requerido"),
    id_rol: _id?undefined:yup.string().required("* Valor Requerido"),
    phone: yup.string().matches(phoneRegExp,"Número de telefono no valido").required("* Valor Requerido"),

  })


  const handleFormSubmit = (values) => {
    //console.log('values',values)
    setInicitalValues(values)
    setLoadingRequest(true)
    if(_id){
      //console.log('modificar')
      modifyUser(values,_id);
    }
    else{
      newUser(values);
    }
  }

  const handleChangeRol = (values,target) =>{
    //console.log('values inicial',values)
    //console.log('target inicial',target)
    values[target.name]=target.value.toString();
    //console.log(values[target.name])
    //console.log('values final',values)
    //console.log('target final',target)
    //setInicitalValues(values)
    //console.log('initialValues',initialValues)
  }


  if(userError)
  return(mostrarErrorUsuario("Usuario no Encontrado"))
  else
  if(errorRequest)
  return(mostrarErrorRequest(errorRequest))
  else
  if(rolesError)
  return(mostrarErrorUsuario("Error al cargar listado de Roles"))
  else
  if(storesListError)
  return(mostrarErrorUsuario("Error al cargar listado de Bodegas"))
  else
  if(loadingRequest)
  return (<Loading/>)
  else
  if(userLoading)
  return (<Loading/>)
  else
  if(rolesLoading)
  return (<Loading/>)
  else
  if(storesListLoading)
  return (<Loading/>)
  else
  return (
    <Box m="20px" marginBottom="64px">
      {!_id?
        <Header title="Nuevo Usuario" subtitle="Crear usuario nuevo"/>:
        <Header title={_id?userData[0].email:''} subtitle="Datos del usuario"/>}    


      <Formik
        enableReinitialize={true}
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema = {userSchema}
      >
        {({
          values, 
          errors, 
          touched, 
          handleBlur, 
          handleChange,
          handleSubmit,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit} >
              <Box
                display="grid" 
                gap="15px"
                marginBottom="15px" 
                gridTemplateColumns="repeat(12, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile? undefined : "span 12"}
                }}
              >               
                <TextField
                  fullWidth={!isNonMobile}
                  variant="filled"
                  type="text"
                  label="Nombre"
                  onBlur = {handleBlur}
                  onChange = {handleChange}
                  value = { values.name}
                  name = "name"
                  error = {!!touched.name && !!errors.name}
                  helperText={touched.name && errors.name}
                  sx={{ gridColumn: "span 6"}}
                />
                <TextField
                  fullWidth={!isNonMobile}
                  variant="filled"
                  type="text"
                  label="Apellido"
                  onBlur = {handleBlur}
                  onChange = {handleChange}
                  value = { values.lastname}
                  name = "lastname"
                  error = {!!touched.lastname && !!errors.lastname}
                  helperText={touched.lastname && errors.lastname}
                  sx={{ gridColumn: "span 6"}}
                />
                <TextField
                  fullWidth={!isNonMobile}
                  variant="filled"
                  type="text"
                  label="Nombre Usuario"
                  onBlur = {handleBlur}
                  onChange = {handleChange}
                  value = { values.user}
                  name = "user"
                  error = {!!touched.user && !!errors.user}
                  helperText={touched.user && errors.user}
                  sx={{ gridColumn: "span 6"}}
                />
                <TextField
                  fullWidth={!isNonMobile}
                  variant="filled"
                  type="text"
                  label="Email"
                  disabled={_id!=null}
                  onBlur = {handleBlur}
                  onChange = {handleChange}
                  value = { values.email}
                  name = "email"
                  error = {!!touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                  sx={{ gridColumn: "span 6"}}
                />
                <TextField
                  fullWidth={!isNonMobile}
                  variant="filled"
                  type="text"
                  label="Phone"
                  onBlur = {handleBlur}
                  onChange = {handleChange}
                  value = { values.phone}
                  name = "phone"
                  error = {!!touched.phone && !!errors.phone}
                  helperText={touched.phone && errors.phone}
                  sx={{ gridColumn: "span 6"}}
                />
                {_id?undefined:<TextField
                  fullWidth={!isNonMobile}
                  variant="filled"
                  type="text"
                  label="Pasword Inicial"
                  onBlur = {handleBlur}
                  onChange = {handleChange}
                  value = { values.password}
                  name = "password"
                  error = {!!touched.password && !!errors.password}
                  helperText={touched.password && errors.password}
                  sx={{ gridColumn: "span 6"}}
                />}
                {_id?undefined:<FormControl variant="filled"
                  sx={{ gridColumn: "span 6"}}
                  required>
                  <InputLabel id="select-role-label" name="select-role-label">Rol</InputLabel>
                  <Select
                    labelId="select-role-label"
                    id="select-role"
                    fullWidth={!isNonMobile}
                    onBlur = {handleBlur}
                    onChange = {(e) => {
                      handleChangeRol(values,e.target);
                      setFieldValue("id_rol", e.target.value);
                    }}
                    value = {values.id_rol}
                    name = "id_rol"
                  >
                    {filteredRolesList.map((rol) => (
                      <MenuItem key={rol.id} value={rol.id.toString()}>
                        {rol.name}
                      </MenuItem>
                    ))}
                  </Select>                  
                </FormControl>}
                {<FormControl variant="filled"
                  sx={{ gridColumn: "span 6"}}
                  required>
                  <InputLabel id="select-store-label" name="select-store-label">Bodega</InputLabel>
                  <Select
                    labelId="select-store-label"
                    id="select-store"
                    fullWidth={!isNonMobile}
                    onBlur = {handleBlur}
                    onChange = {(e) => {
                      handleChangeRol(values,e.target);
                      setFieldValue("id_store", e.target.value);
                    }}
                    value = {values.id_store}
                    name = "id_store"
                  >
                    {storesListData.map((store) => (
                      <MenuItem key={store.id} value={store.id.toString()}>
                        {store.name}
                      </MenuItem>
                    ))}
                  </Select>                  
                </FormControl>}
              </Box>
              <Box display="flex" justifyContent="end" mt="20px">
                {!_id?
                  <Button type="submit" color="secondary" variant="contained">
                    Crear Nuevo Usuario
                  </Button>:
                  <Box >
                    <Button sx={{marginRight: "10px"}} type="submit" color="secondary" variant="contained">
                      Modificar
                    </Button>                   
                  </Box>}                                
              </Box>                
          </form>
        )}
      </Formik>
      {_id!=null?<UserRoles permissionsListData={permissionsListData}/>:undefined}    
    </Box>
  )
}

export default FormUser;