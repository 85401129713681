import { Box,/*useTheme,*/TextField,Button, Grid,Paper, Typography, InputAdornment} from "@mui/material";
import Header from "../../../../components/Header";
import useAuth from "../../../../auth/useAuth";
import { boxClosureRoute, getBoxPaymentsSummaryRoute, getContractDataRoute, getStickerDataRoute, newPaymentRoute } from "../../../../config";
import { useState } from "react";
import { useFormik} from "formik";
import swal from "sweetalert";
import * as yup from "yup";
import FatalError from "../../../global/FatalError";
import Loading from "../../../global/Loading";
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { generateHTML } from "../Reportes/HTMLGenerator/StikersGenerator";
import { generateContractHTML } from "../Reportes/HTMLGenerator/ContractGenerator";

const DropOff = () => {
  const auth = useAuth();
  const token = auth.globals.token

  const [ loadingRequest, setLoadingRequest ] = useState(false);
  const [ errorRequest, setErrorRequest ] = useState(null);
  const [record, setRecord] = useState(null);
  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);

  const statusOptions = [
    { id: 1, name: 'Delivery' },
    { id: 2, name: 'Pickup' },
    { id: 3, name: 'Tránsito a bodega USA' },
    { id: 5, name: 'Tránsito a Aduana' },
    { id: 6, name: 'Trámite en Aduana' },
    { id: 7, name: 'Bodega país destino' },
    { id: 8, name: 'Trancito para entrega' },
    { id: 9, name: 'Entregada' },
  ];

  const handleFileChange = async (event, setImage) => {
    const file = event.currentTarget.files[0];
    if (file) {
      const base64 = await convertToBase64(file);
      setImage(base64);
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        // Divide la cadena en "data:image/jpeg;base64," y el código base64
        const base64String = fileReader.result.split(',')[1];
        resolve(base64String);
      };
      fileReader.onerror = (error) => reject(error);
    });
  };


  const getBoxSummary = async(id_box) =>{
    if(id_box && id_box !== "" ){
      try{
        setLoadingRequest(true)
        //console.log('id_box',id_box)
        let res = await fetch(getBoxPaymentsSummaryRoute(id_box), {
          method: "GET",
          headers: {
            //"Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Accept":"application/json",
            "Authorization": `Bearer ${token}`
            //"x-access-token": token,
          },
          //cors: 'no-cors'
        })
        let data = await res.json()
        //console.log('sumary',data)
        if(res.ok){
          setRecord(data[0])
        }else{
          //console.log('res',res)
          if(res.status === 404){
            mostrarErrorRequest("Registro no encontrado")
            setRecord(null)
          }
          if(res.status === 401||res.status === 403){
            mostrarErrorRequest("No tiene autorización o su sesión ha expirado")          
          }  
        }
        setLoadingRequest(false)
        return data;
      }catch(errorRequest){
        setLoadingRequest(false)
        setErrorRequest(errorRequest)
      }
    }
  }

  const getStickerData = async(box_id) =>{
    if(box_id && box_id !== "" ){
      try{
        //console.log('box_id',box_id)
        let res = await fetch(getStickerDataRoute(box_id), {
          method: "GET",
          headers: {
            //"Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Accept":"application/json",
            "Authorization": `Bearer ${token}`
            //"x-access-token": token,
          },
          //cors: 'no-cors'
        })
        let data = await res.json()
        //console.log('respuesta sticker data',data)
        if(res.ok){
          //console.log('respuesta correcta')
          generateHTML(data,box_id)
        }else{
          if(res.status === 404){
            mostrarErrorRequest('No se encuentra la informacion para generar la etiqueta')
          }
          if(res.status === 401||res.status === 403){
            mostrarErrorRequest("No tiene autorización o su sesión ha expirado")          
          }  
        }
        setLoadingRequest(false)
        return data;
      }catch(errorRequest){
        setLoadingRequest(false)
      }
    }
  }

  const getContractData = async(box_id) =>{
    if(box_id && box_id !== "" ){
      try{
        //console.log('box_id',box_id)
        let res = await fetch(getContractDataRoute(box_id), {
          method: "GET",
          headers: {
            //"Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Accept":"application/json",
            "Authorization": `Bearer ${token}`
            //"x-access-token": token,
          },
          //cors: 'no-cors'
        })
        let data = await res.json()
        //console.log('respuesta contract data',data)
        if(res.ok){
          //console.log('respuesta correcta',data)
          generateContractHTML(data,box_id)
        }else{
          if(res.status === 404){
            mostrarErrorRequest('No se encuentra la informacion para generar la etiqueta')
          }
          if(res.status === 401||res.status === 403){
            mostrarErrorRequest("No tiene autorización o su sesión ha expirado")          
          }  
        }
        setLoadingRequest(false)
        return data;
      }catch(errorRequest){
        setLoadingRequest(false)
      }
    }
  }


  const newPayment = async(body) =>{
    try{
      console.log('json pago',{
        "id_box":record.id.toString(),
        "box_status" : record.status.toString(),
        "payment_signature" : '',
        "comment" : body.comment,
        "destination_user" : null,
        "detail" : [
          {
            "payment_method": "Efectivo",
            "payment": body.cash
          },
          {
            "payment_method": "Transferencia",
            "payment": body.transfer
          },    
        ]
      })
      setLoadingRequest(true)
      let res = await fetch(newPaymentRoute(), {
        method: "POST",
        headers: {
          //"Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Accept":"application/json",
          "Authorization": `Bearer ${token}`
          //"x-access-token": token,
        },
        body: JSON.stringify({
          "id_box":record.id.toString(),
          "box_status" : record.status.toString(),
          "payment_signature" : '',
          "comment" : body.comment,
          "destination_user" : null,
          "detail" : [
            {
              "payment_method": "Efectivo",
              "payment": body.cash
            },
            {
              "payment_method": "Transferencia",
              "payment": body.transfer
            },    
          ]
        }),
        //cors: 'no-cors'
      })
      let data = await res.json()
      //console.log('resultados nuevo pago: ',data)
      if(res.ok){
        //alertSucess("Pago registrado con éxito",data.message)
        //navigate("/IngresoEfectivo")
        boxClosure(body)          
      }else{
        if(res.status === 401||res.status === 403){
          mostrarErrorRequest("No tiene autorización o su sesión ha expirado")          
        }
        mostrarErrorRequest(data.detail)
        setErrorRequest(data.detail)
        //console.log('pago error')     
      }
      setLoadingRequest(false)
      return data;
    }catch(errorRequest){
      //console.log('errorRequest',errorRequest)
      setLoadingRequest(false)
      setErrorRequest(errorRequest)
      //console.log('pago catch') 
    }
  }

  
  const boxClosure = async(body) =>{
    try{
      setLoadingRequest(true) 
      let res = await fetch(boxClosureRoute(record.id), {
        method: "POST",
        headers: {
          //"Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Accept":"application/json",
          "Authorization": `Bearer ${token}`
          //"x-access-token": token,
        },
        body: JSON.stringify({
          "image_1":body.image_1,
          "image_2" : body.image_2,
          "update_user" : auth.globals.id.toString(),
        }),
        //cors: 'no-cors'
      })
      let data = await res.json()
      //console.log('resultados closure: ',data)
      if(res.ok){
        alertSucess("Entregado con éxito",data.message)
        //navigate("/IngresoEfectivo")
        getBoxSummary(record.id)     
      }else{
        if(res.status === 401||res.status === 403){
          mostrarErrorRequest("No tiene autorización o su sesión ha expirado")          
        }
        mostrarErrorRequest(data.detail)
        setErrorRequest(data.detail)
        //console.log('entrega error')     
      }
      setLoadingRequest(false)
      return data;
    }catch(errorRequest){
      //console.log('errorRequest',errorRequest)
      setLoadingRequest(false)
      setErrorRequest(errorRequest)
      //console.log('entrega catch') 
    }
  }

  const searchFormik = useFormik({
    initialValues: {
        recordId: '',
    },
    onSubmit: (values) => {
      //console.log(values)
      getBoxSummary(values.recordId)
    },
  });

  const paymentSchema = yup.object().shape({
    cash: yup.number().required('* Valor Requerido').min(0.00, 'El valor debe ser mayor o igual a 0').typeError('Debe ser un número válido'),
    transfer: yup.number().required('* Valor Requerido').min(0.00, 'El valor debe ser mayor o igual a 0').typeError('Debe ser un número válido'),
  })

  const paymentFormik = useFormik({
    initialValues: {
        cash: '0',
        transfer: '0',
        comment: 'Drop Off',
    },
    validationSchema: paymentSchema, 
    onSubmit: (values, { resetForm }) => {

      if (!image1) {
        mostrarErrorRequest('La imagen 1 es obligatoria')
        return;
      }

      const dataToSubmit = {
        ...values,
        image_1: image1,
        image_2: image2
      };
      //console.log(dataToSubmit)


      if(record.ends_with_balance ==='N' && amountToCharge > 0){
        if (Number(values.cash)+Number(values.transfer)!==amountToCharge){
          mostrarAlertRequest('Debe registra un el pago por $.' + amountToCharge + ' para entregar la caja')
        }else{
          //console.log('llamar al pago y caja_entregada')
          newPayment(dataToSubmit)
          resetForm();
          setImage1(null)
          setImage2(null)
        }
      }else if(record.ends_with_balance ==='S' && (Number(values.cash)+Number(values.transfer)>0)){
        //console.log('puede enviar sin pago pero decide pagar')
        newPayment(dataToSubmit)
        resetForm();
        setImage1(null)
        setImage2(null)
      }
      else{
        //console.log('solo cerrar caja')
        boxClosure(dataToSubmit)
        resetForm();
        setImage1(null)
        setImage2(null)
      }

    },
});


  const getStatusName = (statusId) => {
    const status = statusOptions.find(option => option.id === statusId);
    return status ? status.name : 'Estado desconocido';
  };

  const calculateAmountToCharge = () => {
    if (!record) return 0;
    if (record.status === 1) {
      return record.delivery_cost - record.payed;
    } else if (record.status > 1) {
      return record.approximate_cost + record.insurance_cost + record.extra_payment - record.discount - record.payed;
    }
    return 0;
  };

  const amountToCharge = calculateAmountToCharge();

  const paperStyle = {
    backgroundColor: amountToCharge === 0 ? 'lightgreen' : 'lightcoral',
    padding: '15px',
    margin: '15px 0',
    textAlign: 'center'
  };

  const mostrarErrorRequest=(error)=>{
    if(error!=null){
      try{
        swal({
          title:"Error",
          text: "Resultado: "+error,
          icon: "error",
          button:"Aceptar"
        })
        if(error==="No tiene autorización o su sesión ha expirado"){
          auth.logout();
        }
      }catch(e){
        swal({
          title:"Error",
          text: "Error inesperado ",
          icon: "error",
          button:"Aceptar"
        })
      }
    }
    setErrorRequest(null)
  }

  const mostrarAlertRequest=(info)=>{
    if(info!=null){
      try{
        swal({
          title:"Alerta",
          text: info,
          icon: "warning",
          button:"Aceptar"
        })
        
      }catch(e){
        swal({
          title:"Error",
          text: "Error inesperado ",
          icon: "error",
          button:"Aceptar"
        })
      }
    }
  }

  const alertSucess=(text_title,text_content='')=>{    
    try{
      swal({
        title:text_title,
        text: text_content,
        icon: "success",
        button:"Aceptar"
      })
    }catch(e){
      swal({
        title:"Error inesperado",
        text: "Error inesperado ",
        icon: "error",
        button:"Aceptar"
      })
    }
    //navigate("/Dashboard")
  }

  if(loadingRequest)
  return(<Loading/>)

  if(errorRequest)
  return(<FatalError/>)

  return (
    
    <Box m="20px" marginBottom="64px">
      <Header title="Drop Off" subtitle="Drop Off"/>
        <form onSubmit={searchFormik.handleSubmit}>
          <Box 
            sx={{ 
              display: 'flex', 
              justifyContent: 'left', 
              alignItems: 'center', 
              gap: 2,  // Espacio entre los elementos
              padding: 1 // Espacio alrededor del contenido
            }}
          >
            <TextField
              variant="filled"
              label="Código de caja"
              name="recordId"
              value={searchFormik.values.recordId}
              onChange={searchFormik.handleChange}
              size="small"
            />
            <Button type="submit" variant="contained" color="secondary">
              Buscar
            </Button>
          </Box>
        </form>

      { record && (<Paper style={paperStyle}>
        <Typography variant="h3">
          Saldo pendiente: ${amountToCharge.toFixed(2)}
        </Typography>
      </Paper>
      )}

      { record && (
      <Paper style={{ margin: '15px 0', padding: '15px' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle1">Empresa: 
              <Box display="inline">
                <Typography variant="subtitle1" display="inline" color="secondary">
                {' ' + record.company}
                </Typography>
              </Box>
            </Typography>
            <Typography variant="subtitle1">Cliente que recibe: 
              <Box display="inline">
                <Typography variant="subtitle1" display="inline" color="secondary">
                {' ' + record.receives_name}
                </Typography>
              </Box>
            </Typography>
            <Typography variant="subtitle1">Teléfono: 
              <Box display="inline">
                <Typography variant="subtitle1" display="inline" color="secondary">
                {' ' + record.receives_phone}
                </Typography>
              </Box>
            </Typography>
            <Typography variant="subtitle1">Celular: 
              <Box display="inline">
                <Typography variant="subtitle1" display="inline" color="secondary">
                {' ' + record.receives_cell}
                </Typography>
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle1">Estado Caja: 
              <Box display="inline">
                <Typography variant="subtitle1" display="inline" color="secondary">
                {' ' + getStatusName(record.status)}
                </Typography>
              </Box>
            </Typography>
            <Typography variant="subtitle1">Pais: 
              <Box display="inline">
                <Typography variant="subtitle1" display="inline" color="secondary">
                {' ' + record.country}
                </Typography>
              </Box>
            </Typography>
            <Typography variant="subtitle1">Dirección: 
              <Box display="inline">
                <Typography variant="subtitle1" display="inline" color="secondary">
                {' ' + record.receives_address}
                </Typography>
              </Box>
            </Typography>
            <Typography variant="subtitle1">Estado: 
              <Box display="inline">
                <Typography variant="subtitle1" display="inline" color="secondary">
                {' ' + record.state}
                </Typography>
              </Box>
            </Typography>
            <Typography variant="subtitle1">Municipio: 
              <Box display="inline">
                <Typography variant="subtitle1" display="inline" color="secondary">
                {' ' + record.municipality}
                </Typography>
              </Box>
            </Typography>
            <Typography variant="subtitle1">Zip: 
              <Box display="inline">
                <Typography variant="subtitle1" display="inline" color="secondary">
                {' ' + record.receives_zip}
                </Typography>
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle1">Tamaño: 
              <Box display="inline">
                <Typography variant="subtitle1" display="inline" color="secondary">
                {' ' + record.size}
                </Typography>
              </Box>
            </Typography>
            <Typography variant="subtitle1">Contenedor: 
              <Box display="inline">
                <Typography variant="subtitle1" display="inline" color="secondary">
                {' ' + record.container_code}
                </Typography>
              </Box>
            </Typography>
            <Typography variant="subtitle1">Fecha contenedor: 
              <Box display="inline">
                <Typography variant="subtitle1" display="inline" color="secondary">
                {' ' + record.container_date}
                </Typography>
              </Box>
            </Typography>
            <Typography variant="subtitle1">Nota: 
              <Box display="inline">
                <Typography variant="subtitle1" display="inline" color="secondary">
                {' ' + record.notes}
                </Typography>
              </Box>
            </Typography>
          </Grid>
        </Grid>
      </Paper>
      )}
      {record && (
      <Box display="flex" justifyContent="end" mt="20px">
        {record.status&&record.status!==1?
        <Button sx={{marginRight: "10px"}} color="secondary" variant="contained" onClick={()=>{getStickerData(record.id)}}>
          <SaveAltIcon/>
          Descargar Etiqueta
        </Button>:undefined}
        {record.status&&record.status!==1&&record.status!==2?
        <Button sx={{marginRight: "10px"}} color="secondary" variant="contained" onClick={()=>{getContractData(record.id)}}>
          <SaveAltIcon/>
          Descargar Factura
        </Button>:undefined}                               
      </Box> 
      )}

      { record &&(
      <Box sx={{ width: '100%', marginRight: 'auto', paddingBottom:'15px'}}>
        <form>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={4}>
                <Typography variant="subtitle1">Pagado (USD)</Typography>
                <TextField
                    label="Valor pagado"
                    name="payed"
                    value={record.payed}
                    disabled={true}
                    //onChange={paymentFormik.handleChange}
                    fullWidth
                    variant="filled"
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          $
                        </InputAdornment>
                      ),
                    }}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <Typography variant="subtitle2">Total (USD)</Typography>
                <TextField
                    label="Valor total"
                    name="cost"
                    value={record.approximate_cost + record.insurance_cost + record.extra_payment - record.discount}
                    disabled={true}
                    //onChange={paymentFormik.handleChange}
                    fullWidth
                    variant="filled"
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          $
                        </InputAdornment>
                      ),
                    }}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <Typography variant="subtitle3">Pendiente (USD)</Typography>
                <TextField
                    label="Valor Pendiente"
                    name="debt"
                    value={record.approximate_cost + record.insurance_cost + record.extra_payment - record.discount - record.payed}
                    disabled={true}
                    //onChange={paymentFormik.handleChange}
                    fullWidth
                    variant="filled"
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          $
                        </InputAdornment>
                      ),
                    }}
                />
            </Grid>
          </Grid>
        </form>
      </Box> 
      )}

      { record && record.status === 8 && (
      <form onSubmit={paymentFormik.handleSubmit}>
      <Box sx={{ width: { xs: '100%', sm: '50%', md: '35%' }, marginRight: 'auto'}}>
        <Grid container direction="column" spacing={2}>
          <Grid item container alignItems="center" spacing={2}>
            <Grid item style={{ width: '100px' }}>
              <Typography variant="subtitle1">Efectivo</Typography>
            </Grid>
            <Grid item xs>
              <TextField
                label="Ingrese el valor"
                name="cash"
                value={paymentFormik.values.cash}
                onChange={paymentFormik.handleChange}
                fullWidth
                variant="filled"
                size="small"
                onBlur={paymentFormik.handleBlur}
                error={paymentFormik.touched.cash && Boolean(paymentFormik.errors.cash)} 
                helperText={paymentFormik.touched.cash && paymentFormik.errors.cash}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      $
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Grid item container alignItems="center" spacing={2}>
            <Grid item style={{ width: '100px' }}>
              <Typography variant="subtitle2">Transferencia</Typography>
            </Grid>
            <Grid item xs>
              <TextField
                label="Ingrese el valor"
                name="transfer"
                value={paymentFormik.values.transfer}
                onChange={paymentFormik.handleChange}
                fullWidth
                variant="filled"
                size="small"
                onBlur={paymentFormik.handleBlur}
                error={paymentFormik.touched.transfer && Boolean(paymentFormik.errors.transfer)} 
                helperText={paymentFormik.touched.transfer && paymentFormik.errors.transfer}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      $
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid> 
          <Grid item container alignItems="center" spacing={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">Imagen 1</Typography>
              <input
                type="file"
                onChange={(event) => handleFileChange(event, setImage1)}
              />
              {!image1 && <div>Se requiere la imagen 1</div>}
            </Grid>
          </Grid>
          <Grid item container alignItems="center" spacing={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">Imagen 2 (Opcional)</Typography>
              <input
                type="file"
                onChange={(event) => handleFileChange(event, setImage2)}
              />
            </Grid>
          </Grid>
        </Grid>
        </Box>
        {record.status === 8 &&
        <Button type="submit" variant="contained" color="secondary" style={{ marginTop: '20px' }}>
          Enviar imagen y marcar como entregado
        </Button>}
      </form>
      )}
      {record && (
        <Box>
          {record.url_delivered_1 && (
            <Box mt={2}>
              <Typography variant="subtitle1">Imagen Entregada 1:</Typography>
              <img src={record.url_delivered_1} alt="Imagen Entregada 1" style={{ maxWidth: '350px', height: 'auto' }} />
            </Box>
          )}
          {record.url_delivered_2 && (
            <Box mt={2}>
              <Typography variant="subtitle1">Imagen Entregada 2:</Typography>
              <img src={record.url_delivered_2} alt="Imagen Entregada 2" style={{ maxWidth: '350px', height: 'auto' }} />
            </Box>
          )}
        </Box>
      )}
    </Box>

  );
}

export default DropOff;