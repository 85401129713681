import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';
//import { useFormikContext } from 'formik';
import { activateInactivateRoute } from '../../../../config';
import { useNavigate } from "react-router-dom";
import useAuth from '../../../../auth/useAuth';
import swal from 'sweetalert';
import Loading from '../../../global/Loading';

const InactivateDialog = ({ openInactivateDialog, setOpenInactivateDialog,id,correlative,record_status,pageNavigate,handleSearch,lastSearchParams,setReloadData}) => {
  const auth = useAuth();
  const token = auth.globals.token
  const [inactivationReason, setIinactivationReason] = useState('');
  const [ loadingRequest, setLoadingRequest ] = useState(false)
  //const { setFieldValue } = useFormikContext();
  const navigate = useNavigate();

  const activateInactivateBox = async() =>{
    try{
      setLoadingRequest(true)
      let res = await fetch(activateInactivateRoute(id), {
        method: "PUT",
        headers: {
          //"Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Accept":"application/json",
          "Authorization": `Bearer ${token}`
          //"x-access-token": token,
        },
        body: JSON.stringify(
          {
            "motivo": inactivationReason
          }
        ),
        //cors: 'no-cors'
      })
      let data = await res.json()
      //console.log('Anula/Reactiva: ',data)
      if(res.ok){
        alertSucess(record_status==='ACTIVO'? "Registro " + correlative + " Anulado con exito":"Registro " + correlative +" Reactivado con exito",data)
        navigate(pageNavigate)
        if(pageNavigate==='/CajasConSaldo'){
          //console.log('Entra al set')
          setReloadData(true)
        }
        if(handleSearch && lastSearchParams){
          handleSearch(lastSearchParams)
        }
        setIinactivationReason('')
      }else{
        if(res.status === 401||res.status === 403){
          mostrarErrorRequest("No tiene autorización o su sesión ha expirado")          
        }
        mostrarErrorRequest(data.detail)    
      }
      setLoadingRequest(false)
      return data;
    }catch(errorRequest){
      mostrarErrorRequest(errorRequest)
      setLoadingRequest(false)
    }
  }

  const mostrarErrorRequest=(error)=>{
    if(error!=null){
      try{
        swal({
          title:"Error",
          text: "Resultado: "+error,
          icon: "error",
          button:"Aceptar"
        })
        if(error==="No tiene autorización o su sesión ha expirado"){
          auth.logout();
        }
      }catch(e){
        swal({
          title:"Error",
          text: "Error inesperado ",
          icon: "error",
          button:"Aceptar"
        })
      }
    }
  }

  const alertSucess=(text_title,text_content='')=>{    
    try{
      swal({
        title:text_title,
        text: text_content,
        icon: "success",
        button:"Aceptar"
      })
    }catch(e){
      swal({
        title:"Error inesperado",
        text: "Error inesperado ",
        icon: "error",
        button:"Aceptar"
      })
    }
    navigate("/Dashboard")
  }

  const handleSelectClient = () => {
    if (record_status === 'ACTIVO' && !inactivationReason) {
      swal({
        title: "Error",
        text: "Debe ingresar un motivo de anulación.",
        icon: "error",
        button: "Aceptar"
      });
      return; // Detiene la ejecución de la función aquí
    }
    //setFieldValue("inactivationReason", inactivationReason);
    setOpenInactivateDialog(false);
    //console.log(inactivationReason)
    activateInactivateBox()
  };

  if(loadingRequest)
  return(<Loading/>)

  return (
    <Dialog open={openInactivateDialog} onClose={() => setOpenInactivateDialog(false)}>
      {record_status==='ACTIVO'?
      <DialogTitle>¿Está seguro que desea ANULAR el registro {correlative}? Ingrese una razón</DialogTitle>:
      <DialogTitle>¿Está seguro que desea ACTIVAR el registro {correlative}?</DialogTitle>}
      {record_status==='ACTIVO'?<DialogContent>
        <TextField
          label="Motivo de Anulación"
          value={inactivationReason}
          onChange={e => setIinactivationReason(e.target.value)}
          fullWidth
          margin="normal"
        />
      </DialogContent>:undefined}
      <DialogActions>
        <Button onClick={() => setOpenInactivateDialog(false)}>Regresar</Button>
        {record_status==='ACTIVO'?
        <Button color="error" onClick={handleSelectClient}>Anular</Button>:
        <Button color="success" onClick={handleSelectClient}>Activar</Button>}
      </DialogActions>
    </Dialog>
  );
};

export default InactivateDialog;