// CustomDialog.js
import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Select, MenuItem } from '@mui/material';

const CustomDialog = ({ openDialog, setOpenDialog, receivesClients, setFieldValue, loadStates, loadMunicipalities, setIsStatesLoaded, setIsMunicipalitiesLoaded,getBoxPrice,values}) => {
  //console.log(receivesClients)
  const [selectedClient, setSelectedClient] = useState({receives_client_id: ''});
  const handleSelectClient = () => {
    if (selectedClient.country) {
      setIsStatesLoaded(false);
      setIsMunicipalitiesLoaded(false);

      loadStates(selectedClient.country).then(() => {
        setIsStatesLoaded(true);
        setFieldValue("receives_country", selectedClient.country ? selectedClient.country.toString() : "");
        getBoxPrice(values.id_box_size,selectedClient.country,values,setFieldValue)
        setFieldValue("receives_state", "");
        setFieldValue("receives_municipality", "");

        if (selectedClient.state) {
          loadMunicipalities(selectedClient.state).then(() => {
            setIsMunicipalitiesLoaded(true);
            setFieldValue("receives_state", selectedClient.state ? selectedClient.state.toString() : "");
            setFieldValue("receives_municipality", selectedClient.municipality ? selectedClient.municipality.toString() : "");
          });
        } else {
          setIsMunicipalitiesLoaded(true);
        }
      });
    } else {
      setIsStatesLoaded(true);
      setIsMunicipalitiesLoaded(true);
      setFieldValue("receives_country", "");
      setFieldValue("receives_state", "");
      setFieldValue("receives_municipality", "");
    }

    // Actualizar los demás campos con setFieldValue
    setFieldValue("receives_name", selectedClient.name ? selectedClient.name : "");
    setFieldValue("receives_phone", selectedClient.phone ? selectedClient.phone : "");
    setFieldValue("receives_cell", selectedClient.cell ? selectedClient.cell : "");
    setFieldValue("receives_address", selectedClient.address ? selectedClient.address : "");
    setFieldValue("receives_zip", selectedClient.zip ? selectedClient.zip : "");
    setFieldValue("receives_id", selectedClient.receives_client_id ? selectedClient.receives_client_id.toString() :null);

    setOpenDialog(false);
  };

  return (
    <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
      <DialogTitle>Personas a las que ha enviado con anterioridad:</DialogTitle>
      <DialogContent>
        <Select
          value={selectedClient.receives_client_id}
          onChange={e => {
            const client = receivesClients.find(c => c.receives_client_id === e.target.value);
            setSelectedClient(client || {receives_client_id: ''});
          }}
          fullWidth
        >
          {receivesClients.map(client => (
            <MenuItem key={client.receives_client_id} value={client.receives_client_id}>
              {client.descriptor}
            </MenuItem>
          ))}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenDialog(false)}>Ingreso Manual</Button>
        <Button onClick={handleSelectClient}>Seleccionar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomDialog;